import React from "react";

function Checkbox(props) {
  return (
    <input
      id="isRequireQuotation"
      //id={`isRadio_${props.financeCreditNoteId}`} //"isRequireQuotation"
      name="isRequireQuotation"
      type="checkbox"
      className="cursor-pointer h-6 w-6 rounded check-box-custom text-[#ffffff]"
      onChange={props.handleCheckbox}
      checked={props.checkedHeader}
    />
  );
}

export default Checkbox;
