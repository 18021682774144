import React from 'react'
import ButtonLoader from '../../common/buttonLoader/ButtonLoader'
import DropdownSelect from '../../dropdown/Dropdown'
import DatePicker from "../../datePicker/DatePicker";
import moment from "moment";
function MembershipSubscription(props) {
  return (
    <>
      <div>
        <div className="grid grid-cols-12 gap-6 w-full p-[25px]">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Membership Type
                </h2>
                <DropdownSelect
                  // disabled={true}
                  drpIdentity={"Membership_Type"}
                  optionArray={props.membershipTypes}
                  setFilterParameters={props.setFilterParameters}
                  value={props.membershipSubscription.membershipTypeId}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Billing Category
                </h2>
                <DropdownSelect
                  disabled={true}
                  drpIdentity={"Billing_Category"}
                  optionArray={props.billingCategory}
                  setFilterParameters={props.setFilterParameters}
                  value={props.membershipSubscription.billingCategoryId}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Subscription Start Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input dropdwn-input-email flex items-center">
                  <DatePicker
                    isDisable={true}
                    name="subscriptionStartDate"
                    handleChange={props.handleChange}
                    identity="MembershipSubscription"
                    value={moment(props.membershipSubscription.subscriptionStartDate).format("yyyy-MM-DD")}
                  // onBlur={(e) => props.validate("corporateJoinedDate", props.membershipSubscription)}
                  />

                  {/* <Validation error={props.validState.error.subscriptionStartDate} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Subscription End Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input dropdwn-input-email flex items-center">
                  <DatePicker
                    isDisable={true}
                    name="subcriptionEndDate"
                    handleChange={props.handleChange}
                    identity="MembershipSubscription"
                    value={moment(props.membershipSubscription.subscriptionEndDate).format("yyyy-MM-DD")}
                  // onBlur={(e) => props.validate("corporateJoinedDate", props.membershipSubscription)}
                  />

                  {/* <Validation error={props.validState.error.subcriptionEndDate} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="flex items-center justify-end">
              {props && props.isLoading === true ? (
                <ButtonLoader />
              ) : (
                <button
                  className="btn btn-pink text-xl text-white font-semibold"
                  onClick={() => props.onSave()}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default MembershipSubscription;