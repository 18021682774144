import React from "react";
import EditDetailsColumn1 from "./EditdetailsColumn1";
import EditDetailsColumn2 from "./EditDetailsColumn2";



const EditDetail = (props) => {
    return (
        <>
            <div className="border border-[#e0e0e0] border-solid">
                <div className="px-4">
                    <div className="grid 2xl:grid-cols-12 lg:grid-cols-12 gap-8 w-full 2xl:pl-4 lg:px-3 mt-8  mb-20 ">
                        <div className="2xl:col-span-9 lg:col-span-8 col-span-12 post-details ck-content space-y-8">
                            <EditDetailsColumn1
                                selectionDisplay={props.selectionDisplay}
                                categoryCampaignItem={props.categoryCampaignItem}
                                carouselData={props.carouselData}
                                handleChange={props.handleChange}
                                handleAboutEditorChange={props.handleAboutEditorChange}
                                handleOutlineEditorChange={props.handleOutlineEditorChange}
                                handleSpeakerEditorChange={props.handleSpeakerEditorChange}
                                handleReadMoreEditorChange={props.handleReadMoreEditorChange}
                                validState={props.validState}
                                validateField={props.validateField}
                                validateFieldDescription={props.validateFieldDescription}
                                validStateDescription={props.validStateDescription}
                                entryPricingDetails={props.entryPricingDetails}
                                setEditorData={props.setEditorData}
                            />
                        </div>
                        <div className="2xl:col-span-3 lg:col-span-4 col-span-12 mt-0 space-y-4">
                            <EditDetailsColumn2
                                categoryEntry={props.categoryEntry}
                                campaignList={props.campaignList}
                                categoryCampaignItem={props.categoryCampaignItem}
                                handleChange={props.handleChange}
                                handleChangeInDropdown={props.handleChangeInDropdown}
                                handleSpeakerEditorChange={props.handleSpeakerEditorChange}
                                handleRadioButtons={props.handleRadioButtons}
                                onToggleChange={props.onToggleChange}
                                filterByToggle={props.filterByToggle}
                                filterByFeatured={props.filterByFeatured}
                                validState={props.validState}
                                validateField={props.validateField}
                                financeClassCodes={props.financeClassCodes}
                                financeAccountCodes={props.financeAccountCodes}
                                handleChangeInCodesDropdown={props.handleChangeInCodesDropdown}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default EditDetail;