export const FormFieldTypes = {
    Input: 1,
    Select: 2,
    CheckBox: 3,
    Numeric: 4,
    TextArea: 5,
    DatePicker: 6,
    Password: 7,
}

export const ButtonTypes = {
    Primary: 1,
    Secondary: 2,
    Cancel: 3
}
export const StatusType ={
    StatusTrue:true,
    StateFalse:false,
}