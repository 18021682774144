import React from "react";
import AvailabilityAndSchedule from "../availabilityschedule/AvailabilityAndSchedule";
import BasicInformation from "../basicInformation/BasicInformation";
import Pricing from "../Pricing";
import Programme from "../Programme";
import Others from "./Others";
import Questions from "./Questions";

function CreateEntryTab(props) {
  // const [openTab, setOpenTab] = React.useState(1);
console.log("Basic Info Props :", props.additionalGuest)
  return (
    <div>
      <div className="custom-card shadow-lg flex flex-wrap" name="tabbing-part" id="tabbing-part">
        <div className="globle-connection-tabs w-full">
          <div
            className="flex items-center mb-0 list-none pt-3 pb-4 flex-row progress-steps"
            role="tablist"
          >
            {/* Basic Information Start*/}
            <div className="flex-auto text-center tab-sec-p">
              <div className="">
                <div className="entry-steps-section">
                  <a
                    className={
                      props.openTab === 1
                        ? "activetab cursor-pointer"
                        : "cursor-pointer"
                    }
                    // onClick={(e) => {
                    //   props.nextToBasicInformation();
                    //   e.preventDefault();
                    //   setOpenTab(1);
                    // }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    <span
                      className={`
                     round-count ${props.openTab === 1
                          ? "bg-[#AA3361] text-white tab-background2 inner-title-font-small-button font-bold uppercase  inline-block "
                          : "bg-[#a1a1a1] inner-title-font-small-button font-bold uppercase text-white  inline-block"
                        }`}
                    >
                      1
                    </span>
                    <span className="tab-title">Basic Information</span>
                  </a>
                </div>
              </div>
            </div>
            {/* Basic Information End*/}

            {/* Availability & Scheduale Start*/}
            {(props.basicInformationDetails.eventTypeId !== 3) ?
              <div className="flex-auto text-center tab-sec-p">
                <div className="">
                  <div className="entry-steps-section">
                    <a
                      className={
                        props.openTab === 2
                          ? "activetab cursor-pointer"
                          : "cursor-pointer"
                      }
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   setOpenTab(2);
                      // }}
                      data-toggle="tab"
                      href="#link2"
                      role="tablist"
                    >
                      <span
                        className={`round-count ${props.openTab === 2
                          ? "bg-[#AA3361] text-white tab-background2 inner-title-font-small-button font-bold uppercase  inline-block "
                          : "bg-[#a1a1a1] inner-title-font-small-button font-bold uppercase text-white  inline-block"
                          }`}
                      >
                        2
                      </span>
                      <span className="tab-title">
                        Availability &amp; Schedule
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              : null}
            {/* Availability & Scheduale End*/}

            {/* Programe Start*/}
            {(props.basicInformationDetails.eventTypeId === 2) ?
              <div className="flex-auto text-center tab-sec-p">
                <div className="">
                  <div className="entry-steps-section">
                    <a
                      className={
                        props.openTab === 3
                          ? "activetab cursor-pointer"
                          : "cursor-pointer"
                      }
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   setOpenTab(3);
                      // }}
                      data-toggle="tab"
                      href="#link3"
                      role="tablist"
                    >
                      <span
                        className={`round-count ${props.openTab === 3
                          ? "bg-[#AA3361] text-white tab-background2 inner-title-font-small-button font-bold uppercase  inline-block "
                          : "bg-[#a1a1a1] inner-title-font-small-button font-bold uppercase text-white  inline-block"
                          }`}
                      >
                        3
                      </span>
                      <span className="tab-title">Programme</span>
                    </a>
                  </div>
                </div>
              </div>
              : null}
            {/* Programe End*/}

            {/* Pricing Start*/}
            {(props.basicInformationDetails.eventTypeId !== 3) ?
              <div className="flex-auto text-center tab-sec-p">
                <div className="">
                  <div className="entry-steps-section ">
                    <a
                      className={
                        props.openTab === 4
                          ? "activetab cursor-pointer"
                          : "cursor-pointer"
                      }
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   setOpenTab(4);
                      // }}
                      data-toggle="tab"
                      href="#link4"
                      role="tablist"
                    >
                      <span
                        className={`
                   round-count ${props.openTab === 4
                            ? "bg-[#AA3361] text-white tab-background2 inner-title-font-small-button font-bold uppercase  inline-block "
                            : "bg-[#a1a1a1] inner-title-font-small-button font-bold uppercase text-white  inline-block"
                          }`}
                      >
                        {props.basicInformationDetails.eventTypeId === 1 || props.basicInformationDetails.eventTypeId === 4 ? "3" : "4"}
                      </span>
                      <span className="tab-title">Pricing</span>
                    </a>
                  </div>
                </div>
              </div>
              :
              null}
            {/* Pricing End*/}

            {/* Others Start */}
            {(props.basicInformationDetails.eventTypeId !== 3) ?
              <div className="flex-auto text-center tab-sec-p">
                <div className="">
                  <div className="entry-steps-section">
                    <a
                      className={props.openTab === 5 ? "activetab" : "cursor-pointer"}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   setOpenTab(5);
                      // }}
                      data-toggle="tab"
                      href="#link5"
                      role="tablist"
                    >
                      <span
                        className={`
                   round-count ${props.openTab === 5
                            ? "bg-[#AA3361] text-white tab-background2 inner-title-font-small-button font-bold uppercase  inline-block "
                            : "bg-[#a1a1a1] inner-title-font-small-button font-bold uppercase text-white  inline-block"
                          }`}
                      >
                        {props.basicInformationDetails.eventTypeId === 1 || props.basicInformationDetails.eventTypeId === 4 ? "4" : "5"}
                      </span>
                      <span className="tab-title">Others</span>
                    </a>
                  </div>
                </div>
              </div>
              : null}
            {/* Others End */}

            {/* Questions Start */}
            {(props.basicInformationDetails.eventTypeId === 3) ?
              <div className="flex-auto text-center tab-sec-p">
                <div className="">
                  <div className="entry-steps-section">
                    <a
                      className={props.openTab === 6 ? "activetab" : "cursor-pointer"}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   setOpenTab(6);
                      // }}
                      data-toggle="tab"
                      href="#link5"
                      role="tablist"
                    >
                      <span
                        className={`round-count ${props.openTab === 6
                          ? "bg-[#AA3361] text-white tab-background2 inner-title-font-small-button font-bold uppercase  inline-block "
                          : "bg-[#a1a1a1] inner-title-font-small-button font-bold uppercase text-white  inline-block"
                          }`}
                      >
                        2
                      </span>
                      <span className="tab-title">Questions</span>
                    </a>
                  </div>
                </div>
              </div>
              : null}
            {/* Questions End */}
          </div>
        </div>
      </div>
      <div>
        <div className="upcomming-events-globle-tab w-full pb-0 pt-12">
          <div
            className={
              props.openTab === 1
                ? "block"
                : props.openTab === 2
                  ? "block"
                  : props.openTab === 3
                    ? "block"
                    : props.openTab === 4
                      ? "block"
                      : props.openTab === 5
                        ? "block"
                        : props.openTab === 6
                          ? "block"
                          : "hidden"
            }
            id="link1"
          >
            <div className="grid grid-cols-12 gap-6">
              <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                {props.openTab === 1 ? (
                  <div className="">
                    <BasicInformation
                      pageDescription={props.pageDescription}
                      entryTopics={props.entryTopics}
                      entryType={props.entryType}
                      creatEntry={props.creatEntry}
                      handleChangeInDropdown={props.handleChangeInDropdown}
                      basicInformationDetails={props.basicInformationDetails}
                      sectionArr={props.sectionArr}
                      handleChange={props.handleChangeForBasicInfo}
                      validState={props.validState}
                      validStateAdditionalDescription={
                        props.validStateAdditionalDescription
                      }
                      validStateSpeaker={props.validStateSpeaker}
                      validStateModerator={props.validStateModerator}
                      handleRadioButtons={props.handleRadioButtons}
                      saveMainImage={props.saveMainImage}
                      setEditorData={props.setEditorData}
                      addDescription={props.addDescription}
                      additionalDescription={props.additionalDescription}
                      addSpeaker={props.addSpeaker}
                      additionalSpeaker={props.additionalSpeaker}
                      addEditEntryDetails={(packageList, redirectToNext, isCreditAllow) => props.addEditEntryDetails(packageList, redirectToNext, isCreditAllow)}
                      additionalModerator={props.additionalModerator}
                      addModerator={props.addModerator}
                      additionalGuest={props.additionalGuest}
                      pricingDetail={props.pricingDetail}
                      openTab={props.openTab}
                      nextToBasicInformation={props.nextToBasicInformation}
                      executeScroll={props.executeScroll}
                      myRef={props.myRef}
                      startupMember={props.startupMember}
                      salesEndDateDays={props.salesEndDateDays}
                      packageList={props.packageList}
                      eventId={props.eventId}
                      validStatePrice={props.validStatePrice}
                      navigateTo={(routeTo) => props.navigateTo(routeTo)}
                      removeAdditionalDescription={(index) => props.removeAdditionalDescription(index)}
                      removeAdditionalSpeaker={(index) => props.removeAdditionalSpeaker(index)}
                      removeAdditionalModerator={(index) => props.removeAdditionalModerator(index)}
                      goToViolation={(id) => props.goToViolation(id)}
                      deleteSpeakerById={(index) => props.deleteSpeakerById(index)}
                      deleteModeratorById={(index) => props.deleteModeratorById(index)}
                      deleteAdditionalDescriptionById={(index) => props.deleteAdditionalDescriptionById(index)}
                      setTime={props.setTime}
                      eventTimeSlotId={props.eventTimeSlotId}
                      backFromOthers={props.backFromOthers}
                      setSectionArray={(arr) => props.setSectionArray(arr)}
                      addGuest={props.addGuest}
                      removeAdditionalGuest={(index) => props.removeAdditionalGuest(index)}
                      deleteGuestById={(index) => props.deleteGuestById(index)}
                      validStateGuest={props.validStateGuest}
                    />
                  </div>
                ) : props.openTab === 2 ? (
                  <div className="AvailabilityAndScheduleSec">
                    <AvailabilityAndSchedule
                      nextToAvailabilitySchedule={props.nextToAvailabilitySchedule}
                      backFromAvailabilitySchedule={props.backFromAvailabilitySchedule}
                      eventId={props.eventId}
                      eventTypeId={props.basicInformationDetails.eventTypeId}
                      setTime={props.setTime}
                      tempData={props.tempData}
                      executeScroll={props.executeScroll}
                      sgTechAddress={props.sgTechAddress}
                      sgTechLocationAreas={props.sgTechLocationAreas}
                      navigateTo={(routeTo) => props.navigateTo(routeTo)}
                      goToViolation={(id) => props.goToViolation(id)}
                      applyAllZoomId={props.applyAllZoomId}
                    />
                  </div>
                ) : props.openTab === 3 ? (
                  <div className="">
                    <Programme
                      trackNo={props.trackNo}
                      selectSpeaker={props.selectSpeaker}
                      handleChangeInDropdown={(selected, drpIdentity) =>
                        props.handleChangeInDropdown(selected, drpIdentity)
                      }
                      eventId={props.eventId}
                      nextToProgramme={props.nextToProgramme}
                      backFromProgramme={props.backFromProgramme}
                      executeScroll={props.executeScroll}
                      navigateTo={(routeTo) => props.navigateTo(routeTo)}
                      goToViolation={(id) => props.goToViolation(id)}
                    />
                  </div>
                ) : props.openTab === 4 ? (
                  <div className="">
                    <Pricing
                      eventId={props.eventId}
                      creatEntry={props.creatEntry}
                      startupMember={props.startupMember}
                      pricingDetail={props.pricingDetail}
                      salesEndDateDays={props.salesEndDateDays}
                      maxAttendees={props.maxAttendees}
                      maxGuest={props.maxGuest}
                      handleChangeInDropdown={props.handleChangeInDropdown}
                      nextToPricing={props.nextToPricing}
                      backFromPricing={props.backFromPricing}
                      executeScroll={props.executeScroll}
                      navigateTo={(routeTo) => props.navigateTo(routeTo)}
                      goToViolation={(id) => props.goToViolation(id)}
                    />
                  </div>
                ) : props.openTab === 5 ? (
                  <div className="">
                    <Others
                      backFromOthers={props.backFromOthers}
                      eventId={props.eventId}
                      executeScroll={props.executeScroll}
                      navigateTo={(routeTo) => props.navigateTo(routeTo)}
                      goToViolation={(id) => props.goToViolation(id)}
                      handleChangeForPartnership={props.handleChangeForPartnership}
                    />
                  </div>
                ) : (
                  <div className="">
                    <Questions
                      executeScroll={props.executeScroll}
                      eventId={props.eventId}
                      backFromQuestions={props.backFromQuestions}
                      navigateTo={(routeTo) => props.navigateTo(routeTo)}
                      goToViolation={(id) => props.goToViolation(id)}
                    />
                  </div>
                )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreateEntryTab;