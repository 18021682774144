// Import react libraries and components
import React, { Component } from 'react'
import DropdownSelect from "../../components/dropdown/Dropdown";
import Table from '../../components/table/Table';
import ActionDeleteButton from '../../components/UIComponent/ActionTableComponent/ActionDeleteButton';
import ActionEditButton from '../../components/UIComponent/ActionTableComponent/ActionEditButton';
import { getAuthProps } from '../../utils/AuthenticationLibrary';
import { CommonSuccessMessages, CommonValidationMessages } from '../../utils/Messages';
import { isValidForm, validate } from '../../utils/validation/CommonValidator';
import ValidationText from '../../utils/validation/ValidationText';
import ButtonLoader from '../../components/common/buttonLoader/ButtonLoader';

// Import api services
import FieldManagementServices from '../../services/axiosServices/apiServices/FieldManagementServices';
import SwalServices from '../../services/swalServices/SwalServices';

export default class ManageTags extends Component {
    constructor(props) {
        super(props);
        this.fieldManagementServices = new FieldManagementServices();
        this.swalServices = new SwalServices();
        this.state = {
            totalResultes: 0,
            isLoading: false,
            redirect: null,
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
            },
            tagList: [],
            addTagdetails: {
                tagId: 0,
                tagName: "",
                tagTypeId: 0,
                colourIndex: ""
            },

            actionDropdown: ["View/Edit", "Delete"],
            tagTypeDropdown: [],
            validState: {
                isValid: true,
                error: {}
            },
            validationRule: {
                tagName: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'tag name')
                    }
                ],
                tagTypeId: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.SelectRequired.replace('{0}', 'tag type')
                    }
                ],
            },
            createdBy: 0,
            colourIndex: [
                {
                    type: 'require',
                    message: CommonValidationMessages.FieldRequired.replace('{0}', 'colour index')
                }
            ],
        };
    }

    isValidateAllFields = () => {
        let validrule = this.state.validationRule;
        if (this.state.addTagdetails.tagTypeId === 2) {
            validrule.colourIndex = this.state.colourIndex;
        }
        else {
            if (validrule.colourIndex) {
                delete validrule.colourIndex
            }
        }
        const newValidState = isValidForm(this.state.addTagdetails, validrule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;

    }
    validateField = (key) => {
        let rules = this.state.validationRule;
        if (this.state.addTagdetails.tagTypeId === 2) {
            rules.colourIndex = this.state.colourIndex;
        }
        const newValidState = validate(key, this.state.addTagdetails, rules, this.state.validState);
        this.setState({ validState: newValidState });
    }

    componentDidMount() {
        // For Set Created by based on admin login
        let cookie = getAuthProps();
        const adminUserId = (cookie?.adminUserId) ? Number(cookie.adminUserId) : 0;
        if (adminUserId > 0) {
            this.setState({ createdBy: adminUserId });
        }
        // END
        this.getAllTagTypeDropdown();
    }

    reset = () => {
        let details = this.state.addTagdetails;
        details.tagId = 0;
        details.tagName = "";
        details.tagTypeId = 0;
        details.colourIndex = "";
        this.setState({ addTagdetails: details });
    }

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.addTagdetails;
        detail[name] = value;
        this.setState({ addTagdetails: { ...detail } });
    }

    searchByTagName = (value, identity) => {
        let detail = this.state.pagination;
        if (identity === "searchByTagName") {
            detail.pageNo = 1;
            detail.pageSize = 10;
            detail.searchText = value;
            this.setState({ pagination: { ...detail } });
        }
        this.getAllTagList();
    }

    onDropdown = (id, drpIdentity) => {
        let details = this.state.addTagdetails
        if (drpIdentity === 'TagType') {
            //details.tagTypeId = id;
            details["tagTypeId"] = id;
            this.validateField("tagTypeId");
        }
        this.setState({ addTagdetails: details });
    }

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getAllTagList();
    };

    getAllTagTypeDropdown = () => {
        this.setState({ isLoading: true });
        this.fieldManagementServices.getAllTagTypeDropdown().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.state.tagTypeDropdown = response.responseItem.responseContent
                let tagTypeDropdown = this.state.tagTypeDropdown.filter((item) => item.id !== 1 && item.id !== 3 && item.id !== 4);
                this.setState({ tagTypeDropdown: tagTypeDropdown }, () => this.getAllTagList());
            } else {
                this.getAllTagList();
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };

    getAllTagList = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });
        this.fieldManagementServices.getAllTagList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let details = response.responseItem.responseContent.itemList;
                details.forEach((x) => {
                    if (!x.colourIndex)
                        x.colourIndex = "-";
                });
                this.setState({ tagList: details });
                this.setState({ totalResultes: response.responseItem.responseContent.totalCount })
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };

    addEditTag = () => {
        if (this.isValidateAllFields()) {
            this.setState({ isLoading: true });
            let request = this.state.addTagdetails;
            request.createdBy = this.state.createdBy;
            request.createdAppId = 114;
            this.fieldManagementServices.addEditTag(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    if (response.responseItem.responseContent.errorType === 'Exists') {
                        this.swalServices.Error("Tag name is already exists.");
                    }
                    else {
                        this.swalServices.Success("Tag details add successfully.");
                        this.getAllTagList();
                    }
                } else {
                    this.swalServices.Error(response.message);
                }
                this.reset();
            });
            this.setState({ isLoading: false });
        }
    };

    onEdit = (value) => {
        this.fieldManagementServices.getTagDetailById(value).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ addTagdetails: response.responseItem.responseContent })
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }

    onDelete = (value) => {
        let request = {
            id: value,
            deletedBy: this.state.createdBy,
            appId: 114
        }
        this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "tag"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "tag"),
            CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
                if (response) {
                    this.fieldManagementServices.deleteTags(request).then((response) => {
                        if (response.statusCode === 200) {
                            this.swalServices.Success("Tag deleted successfully.");
                            this.getAllTagList();
                        } else {
                            this.swalServices.Error(response.message);
                        }
                        this.setState({ isLoading: false });
                    });
                }
            });
    }

    actions = (element, index, value) => {
        return element !== 'tagId' ? null : (
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap pointer">
                <div className="flex items-center relative">
                    <ActionEditButton
                        value={value}
                        id={index}
                        index={index}
                        onActionClick={this.onEdit.bind(this)}
                        text="Edit"
                    />
                    <ActionDeleteButton
                        value={value}
                        id={index}
                        index={index}
                        onActionClick={this.onDelete.bind(this)}
                        text="Delete"
                    />
                </div>
            </td>
        );
    };

    render() {
        return (
            <div className="main-body-section">
                <div className="bg-white pb-10">
                    <div className="heading-part py-3 border-b">
                        <div className="flex items-center justify-between pl-8">
                            <h4 className="small-title font-bold theme-color ">
                                Add Tag
                            </h4>
                        </div>
                    </div>
                    {/* Search by Partner and Events section */}
                    <div className="filter-info grid grid-cols-12 gap-6 px-8 pt-8">
                        <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
                            <label
                                className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                htmlFor="default"
                            >
                                Tag Name
                            </label>
                            <input
                                id="tagName"
                                name="tagName"
                                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                type="text"
                                value={this.state.addTagdetails.tagName}
                                onChange={(event) => this.handleChange(event)}
                                onBlur={() => this.validateField("tagName")}
                            />
                            <ValidationText
                                error={this.state.validState.error.tagName}
                            />
                        </div>
                        <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
                            <label
                                className=" filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                htmlFor="default"
                            >
                                Tag Type
                            </label>
                            <DropdownSelect
                                drpIdentity="TagType"
                                optionArray={this.state.tagTypeDropdown}
                                setFilterParameters={this.onDropdown.bind(this)}
                                value={this.state.addTagdetails.tagTypeId}
                                onBlur={() => this.validateField("tagTypeId")}

                            />
                            <ValidationText
                                error={this.state.validState.error.tagTypeId}
                            />
                        </div>
                        {this.state.addTagdetails.tagTypeId === 2 ?

                            <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
                                <label
                                    className=" filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                    htmlFor="default"
                                >
                                    Colour Index
                                </label>
                                <input
                                    id="colourIndex"
                                    name="colourIndex"
                                    className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="text"
                                    value={this.state.addTagdetails.colourIndex}
                                    onChange={(event) => this.handleChange(event)}
                                    onBlur={() => this.validateField("colourIndex")}
                                // onChange={(e) => this.searchByName(e.target.value, "searchByEvent")}
                                />
                                <ValidationText
                                    error={this.state.validState.error.colourIndex}
                                />
                            </div>
                            :
                            // <>
                            //     {this.state.addTagdetails.tagTypeId != 4 ?
                            //         <>
                            //             {this.state.addTagdetails.colourIndex = ''}
                            //         </>
                            //         :
                            //         <>
                            //             {this.state.addTagdetails.colourIndex = ''}
                            //         </>
                            //     }
                            // </>
                            null
                        }
                    </div>
                    <div className="mx-8 mt-10">
                        <div className="flex items-center justify-between">
                            <button
                                className="btn btn-pink  text-xl text-white font-bold mr-5"
                                onClick={() => {
                                    this.reset();
                                }}
                            >
                                Reset
                            </button>
                            <div className="h-full pb-1">
                            {this.state.isLoading ? (
                            <ButtonLoader />
                            ) : (
                            <button
                                className="btn btn-pink  text-xl text-white font-bold"
                                onClick={() => {
                                    this.addEditTag();
                                }}
                            >
                                Save
                            </button>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='table-section'>
                    <div className="bottom-list-display">
                        <div className="flex items-center justify-end mt-6">
                            <div className="flex items-center w-[30%]">
                                <div className="text-[#181818] text-xl mr-5 w-[20%]">Search : </div>
                                {/* <input className="col-span-2 text-lg font-bold  h-[40px] w-full text-[#181818]"/> */}
                                <input
                                    id="default"
                                    className="col-span-2 text-lg font-bold  h-[55px] text-[#181818] border-white w-[80%]"
                                    type="text"
                                    name="search"
                                    // onChange={(e) => this.searchByName(e.target.value)}
                                    onChange={(e) => this.searchByTagName(e.target.value, "searchByTagName")}
                                />
                            </div>
                        </div>
                        <main className="pb-10">
                            <div className="w-full mx-auto">
                                <div className="relative">
                                    <div className="tags-manage-sec">
                                        <div className=" mt-6">
                                            <Table
                                                columns={[
                                                    { name: 'tagName', title: "Tags" },
                                                    { name: 'tagTypeName', title: "Tags Type" },
                                                    { name: 'colourIndex', title: "Colour Index" },
                                                    { name: 'tagId', title: "Action" },
                                                ]}
                                                pagination={this.state.pagination}
                                                rows={this.state.tagList}
                                                sortingColumns={[
                                                    "tags",
                                                    "tagsType",
                                                    "colourIndex",
                                                ]}
                                                isLoading={this.state.isLoading}
                                                totalResultes={this.state.totalResultes}
                                                totalCount={this.state.totalResultes}
                                                setPagination={this.setPagination.bind(this)}
                                                customScope={[
                                                    {
                                                        column: 'tagId',
                                                        renderTableData: this.actions.bind(this),
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        )
    }
}