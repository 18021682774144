import React from "react";
import ButtonLoader from "../../common/buttonLoader/ButtonLoader";
import HistoryTable from "../../table/HistoryTable";
import moment from "moment";
import DropdownSelect from "../../dropdown/Dropdown";
import DatePicker from "../../datePicker/DatePicker";
import ValidationText from "../../../utils/validation/ValidationText";
function EngagementTrackingNotes(props) {
    return (
        <>
            <div className="p-[25px]">
                <div className="grid grid-cols-12 gap-6 w-full ">
                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                        <div className="history-table pb-4 ">
                            <HistoryTable
                                engagementTrackingList={props.engagementTrackingList}
                                onEdit={props.onEdit}
                                isCorporate={props.isCorporate}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-6 w-full">
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                                    Engagement Type
                                </h2>
                                <DropdownSelect
                                    // disabled={true}
                                    drpIdentity={"Engagement_Type"}
                                    optionArray={props.engagementType}
                                    setFilterParameters={props.setFilterParameters}
                                    value={props.engagementTrackingAndNotes.corporateEngagementTypeId}
                                />
                                {/* <Validation error={props.validState.error.isAccountActive} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                                    Engagement Date
                                </h2>
                                <div className="">
                                    <DatePicker
                                        name="engagementDate"
                                        handleChange={props.handleChange}
                                        identity="Engagement_Tracking"
                                        value={moment(props.engagementTrackingAndNotes.engagementDate).format("yyyy-MM-DD")}
                                    // onBlur={(e) => props.validate("corporateJoinedDate", props.corporateSIISAccount)}
                                    />
                                </div>
                                {/* <Validation error={props.validState.error.corporateJoinedDate} /> */}
                            </div>
                        </div>
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                                    Description
                                </h2>
                                <textarea
                                    id="default"
                                    className="signup-text-area form-input rounded-none w-full shadow-red  text-lg"
                                    name="engagementDescription"
                                    rows="4"
                                    value={props.engagementTrackingAndNotes.engagementDescription}
                                    onChange={(event) =>
                                        props.handleChange(event, "Engagement_Tracking")
                                    }
                                // onBlur={(e) =>
                                //   props.validate(
                                //     "engagementDescription",
                                //     props.engagementTrackingAndNotes
                                //   )
                                // }
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                                    Recorded by
                                    <span className="text-[#AA3361]"> *</span>
                                </h2>
                                <DropdownSelect
                                    // disabled={true}
                                    drpIdentity={"RecordedBy_Engagement"}
                                    optionArray={props.recorded_by}
                                    setFilterParameters={props.setFilterParameters}
                                    value={props.engagementTrackingAndNotes.corporateEngagementRecordedById}
                                />
                                <ValidationText error={props.validState.error.corporateEngagementRecordedById} />
                            </div>
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                                    Follow Up by
                                </h2>
                                <DropdownSelect
                                    // disabled={true}
                                    drpIdentity={"Follow_Up_By"}
                                    optionArray={props.followUp_By}
                                    setFilterParameters={props.setFilterParameters}
                                    value={props.engagementTrackingAndNotes.corporateEngagementFollowUpById}
                                />
                                {/* <Validation error={props.validState.error.isAccountActive} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-12 lg:col-span-12 col-span-12 mt-5">
                                <div className="flex items-center justify-end">
                                    {props && props.isLoading === true ? (
                                        <ButtonLoader />
                                    ) : (
                                        <button
                                            className="btn btn-pink text-white py-3"
                                            onClick={() => props.addEditEngagementTracking()}
                                        >
                                            <span className="text-2xl text-white font-semibold mr-2 rounded-full border-2 border-white h-7 w-7 flex items-center justify-center">
                                                +
                                            </span>
                                            <span className="text-xl text-white font-semibold tracking-normal">
                                                Add/Save
                                            </span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default EngagementTrackingNotes;