import React, { useState } from "react";
import moment from "moment/moment";
import { isNullString } from "../../../utils/Utils";
import GenerateCreditNoteForIndividual from "./GenerateCreditNoteForIndividual";
import { formatPrice } from "../../common/FormatePrice";

function IndividualFinancialsRecord(props) {
  const [generateCreditNote, setGenerateCreditNote] = useState(false);
  const [isInvoiceCancel, setIsInvoiceCancel] = useState(false);
  return (
    <>
      {props.individualFinanceInfo && props.individualFinanceInfo.length > 0 && props.individualFinanceInfo.map((value, key) => {
        return (
          <React.Fragment key={key}>
            <div className=" bg-white border-b border-[#f3f3f3] pb-7 p-[25px]">
              <div className="grid grid-cols-12 gap-6 w-full ">
                <div className="2xl:col-span-9 lg:col-span-9 col-span-12 2xl:pt-7 lg:mt-4 items-center">
                  <div className="grid grid-cols-12 gap-2">
                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                      <div className="flex items-start justify-between">
                        <div className="left-side">
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Invoice Number : <span>{value.membershipPaymentInvoiceNo}</span>
                          </h2>
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Date : <span>{moment(value.paymentDate).format("DD MMM YYYY")}</span>
                          </h2>
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Payment Method : <span>{value.paymentVia}</span>
                          </h2>
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Payment Status : <span>{value.paymentStatus}</span>
                          </h2>
                        </div>
                        <div className="right-side">
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Promotion Code : <span className="">{isNullString(value.voucherCode) === "" ? "N.A" : value.voucherCode}</span>
                          </h2>
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Amount (Billable) : <span>
                              {/* {`$${parseFloat(value.totalAmount).toFixed(2)}`} */}
                              {formatPrice(value.totalAmount)}
                            </span>
                          </h2>
                          <h2 className="text-xl theme-color ">
                            Amount (Paid) : <span>{value.paymentStatus === "Success" ? <>
                              {/* {`$${parseFloat(value.totalAmount).toFixed(2)}`} */}
                              {formatPrice(value.totalAmount)}
                            </> : <>
                              {value.paidPriceByReconciliation ? <>
                                {/* {`$${parseFloat(value.paidPriceByReconciliation).toFixed(2)}`} */}
                                {formatPrice(value.paidPriceByReconciliation)}
                              </> : "$0.00"}</>} </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    </div>
                  </div>
                </div>
                <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                  <div className="relative flex">
                    <div className="right-status-label absolute 2xl:top-[8px] 2xl:right-[-25px] xl:top-[8px] xl:right-[-25px]  lg:top-[10px] lg:right-[-20px]">
                      <span
                        className={`text-xl text-white font-semibold  2xl:px-6 xl:px-6 lg:px-6 md:px-6 sm:px-4 py-3 ${value.paymentStatus === "Success" || value.paymentStatus === "Paid" ? "bg-[#7a9763]" : "bg-[#ffae00]"}`}>
                        {value.paymentStatus === "Success" ? "Paid" : value.paymentStatus}
                      </span>
                    </div>
                    <div className="right-btn mt-20 w-full">
                      <div className="flex items-center justify-end mb-5">
                        <button className="2xl:px-7 lg:px-5 py-2 btn btn-pink-border text-xl border" onClick={() => props.getIndividualFinanceReceiptInvoice(value.membersReceiptId, false, value.membershipPaymentInvoiceNo)}>
                          <span className="text-xl font-semibold tracking-normal underline">
                            View Invoice
                          </span>
                        </button>
                      </div>

                      {value.isReceiptGenerated === true && (value.paymentStatus === "Success" || value.paymentStatus === "Pending Cancellation") ?
                        <>
                          <div className="flex items-center justify-end mb-5">
                            <button className="2xl:px-7 lg:px-5 py-2 btn btn-pink-border text-xl border " onClick={() => props.getIndividualFinanceReceiptInvoice(value.membershipPaymentId, true, value.receiptNo)}>
                              <span className="text-xl font-semibold tracking-normal underline">
                                View Receipt
                              </span>
                            </button>
                          </div>
                        </>
                        : null
                      }
                      {value.isReceiptGenerated === true && value.paymentStatus === "Success" ?
                        <>
                          {(value.membershipPaymentTypeId === 1 || value.membershipPaymentTypeId === 3 || value.membershipPaymentTypeId === 2) && value.isAvailableForCreditNote === true ?
                            <div className="flex items-center justify-end">
                              <button className="2xl:px-[15px] lg:px-[10px] py-2 btn btn-pink text-white" onClick={(e) => { e.stopPropagation(); setGenerateCreditNote(true); setIsInvoiceCancel(false); props.getCreditNoteInfoForIndividualMember(value.membershipPaymentId, value.paymentStatus, value.isReceiptGenerated); props.getCancellationReasonForCreditNote(); props.addEditCreditNote.membershipPaymentId = value.membershipPaymentId; }}>
                                <span className="text-xl text-white font-semibold tracking-normal">

                                  Generate Credit Note
                                </span>
                              </button>
                            </div>
                            : null}

                        </>
                        : value.paymentStatus === "Pending" && (value.membershipPaymentTypeId === 1 || value.membershipPaymentTypeId === 3 || value.membershipPaymentTypeId === 2)
                          // && value.membershipPaymentTypeId !== 1
                          ?
                          <>
                            <div className="flex items-center justify-end">
                              <button className="2xl:px-[15px] lg:px-[10px] py-2 btn btn-pink text-white" onClick={(e) => { e.stopPropagation(); setGenerateCreditNote(true); setIsInvoiceCancel(false); props.getCreditNoteInfoForIndividualMember(value.membershipPaymentId, value.paymentStatus, value.isReceiptGenerated); props.getCancellationReasonForCreditNote(); props.addEditCreditNote.membershipPaymentId = value.membershipPaymentId; }}>
                                <span className="text-xl text-white font-semibold tracking-normal">
                                  Cancel Invoice
                                </span>
                              </button>
                            </div>
                          </>
                          : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      })}
      <div className="add-edite-profile-popup">
        <GenerateCreditNoteForIndividual
          generateCreditNote={generateCreditNote}
          setOpenModal={() => setGenerateCreditNote(false)}
          individualCreditNotePaymentInfo={props.individualCreditNotePaymentInfo}
          cancellationReason={props.cancellationReason}
          setFilterParameters={props.setFilterParameters}
          isOtherCancellationReason={props.isOtherCancellationReason}
          addEditCreditNote={props.addEditCreditNote}
          handleChange={props.handleChange}
          addFinanceCreditNoteForIndividual={props.addFinanceCreditNoteForIndividual}
          validate={props.validate}
          validCreditNote={props.validCreditNote}
          validate2={props.validate2}
          validCreditNote2={props.validCreditNote2}
          isInvoiceCancel={isInvoiceCancel}
          isLoading={props.isLoading}
        />
      </div>
    </>
  );
}

export default IndividualFinancialsRecord;
