import React, { Component } from 'react'
import ActionContainer from '../../components/UIComponent/ActionTableComponent/actionContainer';
import DropdownAction from '../../components/dropdown/DropdownAction';
import Table from '../../components/table/Table';
import { Navigate } from 'react-router-dom';
import BookingServices from '../../services/axiosServices/apiServices/BookingServices';
import moment from "moment";
import { encryptAES } from '../../utils/Encryption';

export default class ViewBookingCancellation extends Component {
  constructor(props) {
    super(props);
    this.BookingServices = new BookingServices();
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        dateFrom: '',
        dateTo: '',
      },
      actionDropdown: ["Detail"],
      route: "",
      totalResultes: "",
      bookingCancellationList: [],
      isLoading: false,
    }

  }
  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getbookingsCancellationList(newPagination);
  };

  getbookingsCancellationList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.BookingServices.getbookingsCancellationList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let details = response.responseItem.responseContent.itemList;
        details.map((key, index) => {
          key.RequestDate = moment(key.RequestDate).format(
            "DD MMM YYYY"
          );
        });
        this.setState({ bookingCancellationList: details });
        this.setState({
          totalResultes: response.responseItem.responseContent.totalCount,
        });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  actionClick = (index, value, option, event) => {
    if (option === "Detail") {
      if (value && value > 0) {
        this.setState({
          route: "/booking/NewBookingCancellationDetail/cancellationId?=" + encryptAES(value),
        });
      }
    }
  };

  actions = (element, index, value) => {
    return element !== "CancellationId" ? null : (
      <td>
      <div className="relative w-full credit-note-action-dropdown">
        <ActionContainer>
          <div className="mr-8">
            <DropdownAction
              key={index}
              value={value}
              id={index}
              index={index}
              options={this.state.actionDropdown}
              align="right"
              onOptionClick={this.actionClick.bind(this)}
            />
          </div>
        </ActionContainer>
      </div>
      </td>
    );
  };
  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.searchText = "";
    this.setState({ pagination: { ...detail } });
    if (value.length > 2) {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getbookingsCancellationList();
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.pagination;
    let fulldate = (detail[name] = value === "" ? null : value);
    let date = new Date(fulldate); //1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM
    let startLimit = new Date("1/1/1753 12:00:00 AM");
    let endLimit = new Date("12/31/9999 11:59:59 PM");
    detail[name] = value;
    this.setState({ pagination: { ...detail } }, () => {
      if (startLimit < date && date < endLimit) {
        this.getbookingsCancellationList();
      } else if (fulldate === null) {
        this.getbookingsCancellationList();
      }
    });
  }

  componentDidMount() {
    this.getbookingsCancellationList();
  }

  render() {
    if (this.state.route != "") {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="main-body-section View-booking-sec">
        <div className="top-filter-sec">
          <div className="flex justify-between pb-7">
            <div className="left-date">
              <div className="grid grid-cols-12 gap-6 items-center w-full">
                <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                  <input
                    id="default"
                    name="dateFrom"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    value={this.state.pagination.dateFrom}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
                <div className=" 2xl:col-span-2 lg:col-span-2 col-span-12 w-full text-center">
                  <h2 className="text-xl theme-color">To</h2>
                </div>
                <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                  <input
                    id="default"
                    name="dateTo"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    value={this.state.pagination.dateTo}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="right-search flex items-center">
              <div className="text-[#757575] text-xl pr-5">Search</div>

              <input
                id="default"
                className="col-span-2 text-lg font-bold  h-[55px] w-full text-[#757575] border-white"
                type="text"
                onChange={(e) => this.searchByName(e.target.value)}
              />
            </div>
          </div>
          <Table
            columns={[
              { name: "BookingReferenceNo", title: "Ref. No." },
              { name: "ClassName", title: "Class" },
              { name: "BookingStatus", title: "Status" },
              { name: "EventTypeName", title: "	Type" },
              { name: "AttendeeName", title: "Username" },
              { name: "AttendeeEmail", title: "Email" },
              { name: "RequestDate", title: "	Request Date" },
              { name: "CancellationId", title: "Actions" },
            ]}
            rows={this.state.bookingCancellationList}
            isLoading={this.state.isLoading}
            sortingColumns={["BookingReferenceNo"]}
            pagination={this.state.pagination}
            totalResultes={this.state.totalResultes}
            totalCount={this.state.totalResultes}
            setPagination={this.setPagination.bind(this)}
            customScope={[
              {
                column: "CancellationId",
                renderTableData: this.actions.bind(this),
              },
              // {
              //     column: "selectRow",
              //     renderTableData: this.select.bind(this),
              //     valueColumnName: "chartsOfAccountsId",
              // },
            ]}
          />
        </div>
      </div>
    )
  }
}
