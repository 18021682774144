import React, { useState } from 'react';

function SidebarLinkGroup({
  children,
  activecondition,
}) {

  const [open, setOpen] = useState(activecondition);

  const handleClick = () => {
    setOpen(!open);
  }

  return (
    <li className={`rounded-sm 2xl:mb-12 lg:mb-[40px] mb-[20px] last:mb-0 ${activecondition && 'bg-red hover-active-color'}`}>
      {children(handleClick, open)}
    </li>
  );
}

export default SidebarLinkGroup;