import React from 'react'
import ModalBasic from '../../../pages/component/ModalBasic'
import ValidationText from '../../../utils/validation/ValidationText'
import DropdownSelect from '../../dropdown/Dropdown'
import TextInput from '../../UIComponent/TextInput'
import ButtonLoader from '../../common/buttonLoader/ButtonLoader'
import Selector from '../../dropdown/commonDropdown/Selector'

const AddEditOrganisationKeyRole = (props) => {
    return (
        <ModalBasic
            id="show-individual-profile-modal"
            modalOpen={props.organisationKeyRoleModal}
            setModalOpen={props.setModalOpen}
        >
            <div className="bg-white mb-10">
                <div className="">
                    <div className="grid grid-cols-12 gap-6 w-full 2xl:px-10 lg:px-8 pr-0">
                        <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                            <h1 className="text-4xl text-[#AA3361] font-bold leading-loose py-4">
                                Individual Profile
                            </h1>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 w-full 2xl:px-10 lg:px-8 pr-0 mb-8">
                        {
                            props.isCreateNewOrgRole === true ?
                                <div className="col-span-12">
                                    <div className='grid grid-cols-12 gap-6'>
                                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts'>
                                            <h2 className="text-xl mb-1">Email Address<span className='text-[#AA3361]'>*</span></h2>
                                            <TextInput
                                                placeholder=""
                                                type="text"
                                                name="searchByEmail"
                                                value={props.organisationKeyRoleDetail.searchByEmail}
                                                handleChange={props.handleChange}
                                                onBlur={(e) => props.validateField("searchByEmail", props.organisationKeyRoleDetail)}
                                                identity="Organisation"
                                            />
                                            {/* <ValidationText error={props.validState.error.searchByEmail} /> */}
                                        </div>
                                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                            <div className='flex justify-end'>
                                                <div>
                                                    <h2 className="text-xl mb-1">&nbsp;</h2>
                                                    <button className="w-32 px-7 py-2 btn-sm btn-pink text-lg text-white font-bold"
                                                        onClick={() => props.searchIndividualMemberByEmailForOrgRole("Organisarion Key Roles")}
                                                    >Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className="grid grid-cols-12 gap-6 w-full 2xl:px-10 lg:px-8 pr-0">
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <h2 className="2xl:text-xl lg:text-md text-[#181818] font-bold 2xl:mb-1">
                                First Name <span className="text-[#AA3361]">*</span>
                            </h2>
                            <div className="flex items-center">
                                <div className="w-[130px] individual-f-name">
                                    <DropdownSelect
                                        drpIdentity={"KeyRole_Salutation"}
                                        optionArray={props.salutation}
                                        setFilterParameters={props.setFilterParameters}
                                        value={props.organisationKeyRoleDetail.mrMrsId}
                                        disabled={props.isEmployeeModalEditable !== true}

                                    />
                                </div>
                                <div className="w-full">
                                    <TextInput
                                        placeholder=""
                                        name="firstName"
                                        identity="Organisation_Key_Role"
                                        handleChange={props.handleChange}
                                        value={props.organisationKeyRoleDetail.firstName}
                                        onBlur={(e) => props.validateField("firstName", "Organisation_Key_Role")}
                                        disabled={props.isEmployeeModalEditable !== true}
                                        isCurserAllowed={props.isEmployeeModalEditable === true}
                                    />
                                </div>
                            </div>
                            <ValidationText error={props.validStateOrganisationKeyRole.error.mrMrs} />
                            <ValidationText error={props.validStateOrganisationKeyRole.error.firstName} />
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <h2 className="2xl:text-xl lg:text-md text-[#181818] font-bold 2xl:mb-1">
                                Last Name <span className="text-[#AA3361]">*</span>
                            </h2>
                            <TextInput
                                placeholder=""
                                name="lastName"
                                identity="Organisation_Key_Role"
                                handleChange={props.handleChange}
                                value={props.organisationKeyRoleDetail.lastName}
                                onBlur={(e) => props.validateField("lastName", "Organisation_Key_Role")}
                            disabled={props.isEmployeeModalEditable !== true}
                            isCurserAllowed={props.isEmployeeModalEditable === true}
                            />
                            <ValidationText error={props.validStateOrganisationKeyRole.error.lastName} />
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <h2 className="2xl:text-xl lg:text-md text-[#181818] font-bold 2xl:mb-1">
                                Business Email{" "}
                                <span className="text-[#AA3361]">*</span>
                            </h2>
                            <div className="flex items-center">
                                <div className="w-full right-border-none">
                                    <TextInput
                                        placeholder=""
                                        name="email"
                                        identity="Organisation_Key_Role"
                                        handleChange={props.handleChange}
                                        value={props.organisationKeyRoleDetail.email}
                                        onBlur={(e) => props.validateField("email", "Organisation_Key_Role")}
                                    disabled={props.isEmployeeModalEditable !== true}
                                    isCurserAllowed={props.isEmployeeModalEditable === true}
                                    />
                                </div>
                            </div>
                            <ValidationText error={props.validStateOrganisationKeyRole.error.email} />
                            {/* <ValidationText error={props.validStateOrganisationKeyRole.error.emailString} /> */}
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <h2 className="2xl:text-xl lg:text-md text-[#181818] font-bold 2xl:mb-1">
                                Contact Number
                            </h2>
                            <TextInput
                                placeholder=""
                                name="contactNo"
                                identity="Organisation_Key_Role"
                                handleChange={props.handleChange}
                                value={props.organisationKeyRoleDetail.contactNo}
                            // onBlur={(e) => props.validateField("contactNo", "Organisation_Key_Role")}
                            disabled={props.isEmployeeModalEditable !== true}
                            isCurserAllowed={props.isEmployeeModalEditable === true}
                            />
                            <ValidationText error={props.validStateOrganisationKeyRole.error.contactNo} />
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <h2 className="2xl:text-xl lg:text-md text-[#181818] font-bold 2xl:mb-1">
                                Job Function
                                <span className="text-[#AA3361]"> *</span>
                            </h2>
                            <div className="dropdwn-input-email flex items-center">
                            </div>
                            <DropdownSelect
                                drpIdentity={"Designation_KeyRoles"}
                                optionArray={props.jobFunctionList}
                                setFilterParameters={props.setFilterParameters}
                                value={props.organisationKeyRoleDetail.jobPositionId}
                                disabled={props.isEmployeeModalEditable !== true}
                            />
                            {/* <Selector
                                drpIdentity="Designation_KeyRoles"
                                options={props.jobFunctionList}
                                isMulti={false}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                // components={{ Option }}
                                onChange={props.handleChangeMultiDropdown}
                                allowSelectAll={true}
                                value={props.organisationKeyRoleDetail.selectedDesignation}
                            // disabled={props.isEmployeeModalEditable !== true}
                            /> */}
                            <ValidationText error={props.validStateOrganisationKeyRole.error.jobPositionId} />
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <h2 className="2xl:text-xl lg:text-md text-[#181818] font-bold 2xl:mb-1">
                                Organisation Role {"(You can select more than one option) "}
                                <span className="text-[#AA3361]"> *</span>
                            </h2>
                            <div className="dropdwn-input-email flex items-center">
                            </div>
                            <Selector
                                drpIdentity="Org_OrganisationRoles"
                                options={props.organisationRolesList}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                // components={{ Option }}
                                onChange={props.handleChangeMultiDropdown}
                                allowSelectAll={true}
                                value={props.organisationKeyRoleDetail.selectedOrganisationRole}
                                disabled={props.isEmployeeModalEditable !== true}
                            />
                            <ValidationText error={props.validStateOrganisationKeyRole.error.selectedOrganisationRole} />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-6 w-full pt-16 px-10">
                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                        <div className="flex items-center justify-between">
                            <div className="">
                                {/* {
                                    props.organisationKeyRoleDetail && props.organisationKeyRoleDetail.individualMemberId > 0 ? */}
                                <button
                                    className="btn btn-gray text-lg text-white font-bold"
                                    onClick={() => { props.deleteOrganisationRole(props.organisationKeyRoleDetail.individualMemberId) }}
                                >
                                    Delete Profile
                                </button>
                                {/* :
                                        null */}
                                {/* } */}
                            </div>
                            {/* {
                                props.isAddEditAOrganisation && props.isAddEditAOrganisation === true ?
                                    <div className="savebtn">
                                        <ButtonLoader />
                                    </div>
                                    : */}

                            <div className="savebtn">
                                <button
                                    className="text-lg font-bold btn btn-pink text-white"
                                    onClick={() => props.addEditOraganisation("Save")}
                                >
                                    Save
                                </button>
                            </div>
                            {/* } */}
                        </div>
                    </div>
                </div>
            </div>
        </ModalBasic>
    )
}
export default AddEditOrganisationKeyRole;