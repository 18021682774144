import React from "react";

const RadioButton = (props) => {
    return (
        <>
            <label className="grid grid-cols-3 gap-4">
                <span className="col-span-2 text-lg font-medium theme-color">
                    {props.radioLabel}
                </span>
                <input
                    type="radio"
                    name={props.name}
                    id={props.id}
                    className="form-radio"
                    value={props.value}
                    onChange={(event) =>
                        props.handleRadioButtons(event)
                    }
                    checked={props.checked}
                />
            </label>
        </>
    );
}
export default RadioButton;