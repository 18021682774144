import React from "react";
import ButtonLoader from "../common/buttonLoader/ButtonLoader";
const CategoryCampaignButtonGroup = (props) => {
    return (
        <>
            <div className="grid grid-cols-12 gap-6">
                <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                    <div className="flex justify-start">
                        <div className="h-full pb-1">

                            <button
                                className="btn btn-lighblue-border text-[#81A3B8]"
                                onClick={props.editDetails}
                            >
                                <span className="text-xl font-semibold tracking-normal">
                                    Edit Details
                                </span>
                            </button>

                        </div>
                    </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                    <div className="flex justify-end">
                        <div className="px-4">
                            <div className="h-full pb-1">
                                <button className="btn btn-gray text-white" onClick={() => props.redirectToViewEntries()}>
                                    <span className="text-xl text-white font-semibold tracking-normal">
                                        Back
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="px-4">
                            <div className="h-full pb-1">
                                {props.isSaveLoading ? (
                                    <ButtonLoader />
                                ) : (
                                    <button
                                        className="btn btn-lighblue text-white"
                                        onClick={() => props.addEditCampaignTaggingDetails(2,"isSave")}
                                    >
                                        <span className="text-xl text-white font-semibold tracking-normal">
                                            Save
                                        </span>
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="px-4">
                            <div className="h-full pb-1">
                                {props.isLoading ? (
                                    <ButtonLoader />
                                ) : (
                                    <button
                                        className="btn btn-pink text-white"
                                        onClick={() => props.addEditCampaignTaggingDetails(4,"isApprove")}
                                    >
                                        <span className="text-xl text-white font-semibold tracking-normal">
                                            Approve
                                        </span>
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="px-4">
                            <div
                                className="h-full pb-1 pt-4"
                                onClick={() => props.addEditCampaignTaggingDetails(1)}
                            >
                                <button>
                                    <span className="text-xl font-semibold text-[#81A3B8] tracking-normal">
                                        Draft
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* <div className="xl:col-start-1 col-end-1  col-span-12">
                        <div className="h-full pb-1">
                            <Link to="">
                                <button className="btn bg-[#181818] text-white">
                                    <span className="text-xl text-white font-semibold tracking-normal">
                                        Cancel
                                    </span>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="xl:col-start-3 col-end-3 ">
                        <div className="h-full pb-1">
                            <Link to="">
                                <button className="btn bg-[#81A3B8] text-white">
                                    <span className="text-xl text-white font-semibold tracking-normal">
                                        Save
                                    </span>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="xl:col-start-10 col-end-10 lg:col-span-2 col-span-12">
                        <div className="h-full pb-1">
                            <Link to="">
                                <button className="btn btn-red text-white">
                                    <span className="text-xl text-white font-semibold tracking-normal">
                                        Approve
                                    </span>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="xl:col-start-12 col-end-12 lg:col-span-2 col-span-12">
                        <div className="h-full pb-1 pt-4">
                            <Link to="">
                                <span className="text-xl font-semibold text-[#81A3B8] tracking-normal">
                                    Draft
                                </span>
                            </Link>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
}
export default CategoryCampaignButtonGroup;