import React, { Component } from 'react';
import ButtonLoader from '../../components/common/buttonLoader/ButtonLoader';
import LogoImage from "../../images/logo.png";
import { Navigate } from 'react-router-dom';
import { isValidForm, validate } from '../../utils/validation/CommonValidator';
import { ErrorMessage } from '../../utils/Messages';
import ValidationText from '../../utils/validation/ValidationText';
import AuthenticationServices from '../../services/axiosServices/apiServices/AuthenticationServices';
import SwalServices from '../../services/swalServices/SwalServices';
import { decryptionAPI } from '../../utils/Encryption';
export default class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.adminServices = new AuthenticationServices();
        this.swalServices = new SwalServices();
        this.state = {
            route: "",
            isLoading: false,
            adminId: 0,
            passwordDetails: {
                password: "",
                confirmPassword: "",
                adminID: 0,
            },
            validationRules: {
                password: [
                    {
                        type: "require",
                        message: ErrorMessage.FieldRequired.replace("{0}", "password"),
                    },
                    {
                        type: "password",
                        message: ErrorMessage.PasswordFormat,
                    },
                ],
                confirmPassword: [
                    {
                        type: "require",
                        message: ErrorMessage.FieldRequired.replace("{0}", "confirm password"),
                    },
                    {
                        type: "compare",
                        compareEle: "password",
                        message: ErrorMessage.ComparePassword,
                    },


                ],
            },
            validState: {
                isValid: true,
                error: {},
            },
        }
    }
    componentDidMount() {
        // let urlString = new URLSearchParams(window.location.href);
        // let paramString = urlString.split('?')[1];
        let queryString = new URLSearchParams(window.location.search);
        for (let pair of queryString.entries()) {
            if (pair[0] === "secret") {

                let req = {
                    isEnType: true,
                    responseData: atob(pair[1]),
                    responseType: 1,
                };
                pair[1] = decryptionAPI(req);
                this.setState({ adminId: pair[1] });
            }
        }

    }

    // getParameters() {
    //     let urlString =
    //         "https://www.example.com/login.php?a=GeeksforGeeks&b=500&c=Hello Geeks";
    //     let paramString = urlString.split('?')[1];
    //     let queryString = new URLSearchParams(paramString);
    //     for (let pair of queryString.entries()) {
    //     }
    // }

    resetPassword = () => {
        if (this.isValidateAllFields()) {
            let request = this.state.passwordDetails;
            request.adminID = this.state.adminId;
            this.adminServices.resetPassword(request).then((response) => {

                if (response.statusCode === 200 && response.responseItem != null) {
                    this.swalServices
                        .SuccessWithAction("Password has been changed successfully. Please login again with your new password.", "Ok")
                        .then((response) => {
                            this.setState({ route: "/Login" });
                        });
                }
                else {
                    this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
            })
        }
    }

    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.passwordDetails,
            this.state.validationRules,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.passwordDetails,
            this.state.validationRules,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let details = this.state.passwordDetails;
        details[name] = value;
        this.setState({ passwordDetails: details });
    }

    render() {
        if (this.state.route !== "") {
            return <Navigate to={this.state.route} />;
        }
        return (
            <div>
                <div className="main-login-sec">
                    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-white w-full">
                        <div className="login-main-bg max-w-md w-full space-y-8 p-8 rounded-sm shadow-red">
                            <div className="">
                                <img
                                    className="mx-auto h-[51px] w-auto"
                                    src={LogoImage}
                                    alt="logo"
                                />
                                <h2 className="mt-9 text-center text-3xl font-extrabold text-[#181818]">
                                    New Password
                                </h2>
                            </div>
                            <input type="hidden" name="remember" value="true" />
                            <div className="rounded-md space-y-8 relative">
                                <div>
                                    <label htmlFor="email-address" className="text-base text-[#181818]">
                                        Enter New Password
                                    </label>
                                    <input
                                        id="email-address"
                                        name="password"
                                        type="password"
                                        autoComplete="email"
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-[#af0c0c] placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#c71212] focus:border-[#c71212] focus:z-10 sm:text-sm bg-white"
                                        placeholder="Password"
                                        value={this.state.passwordDetails.password}
                                        onChange={(event) => this.handleChange(event)}
                                        onBlur={() => this.validateField("password")}
                                    />
                                    <ValidationText
                                        className="absolute left-0 top-[100%]"
                                        error={this.state.validState.error.password}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="email-address" className="text-base text-[#181818]">
                                        Confirm  New Password
                                    </label>
                                    <input
                                        id="email-address"
                                        name="confirmPassword"
                                        type="password"
                                        autoComplete="email"
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-[#af0c0c] placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#c71212] focus:border-[#c71212] focus:z-10 sm:text-sm bg-white"
                                        placeholder="Confirm Password"
                                        value={this.state.passwordDetails.confirmPassword}
                                        onChange={(event) => this.handleChange(event)}
                                        onBlur={() => this.validateField("confirmPassword")}
                                    />
                                    <ValidationText
                                        className="absolute left-0 top-[100%]"
                                        error={this.state.validState.error.confirmPassword}
                                    />
                                </div>


                            </div>

                            <div className="flex justify-center">
                                {this.state.isLoading ? (
                                    <ButtonLoader />
                                ) : (

                                    <button
                                        type="submit"
                                        className="group relative w-full flex items-center justify-center py-3 px-4 h-[48px] border border-transparent text-base font-medium text-white bg-[#AA3361] hover:bg-[#aa0000] focus:outline-none"
                                        onClick={() => this.resetPassword()}
                                    >
                                        Continue
                                    </button>


                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
