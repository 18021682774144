//This set to use Production Mode or DevelopmentMode
export const IsProdMode = false;

export const IsEncryption = true;

export const IsQueryParamsEncryption = true;


// AESKey for encryption and decryption
export const Value1 = process.env.REACT_APP_Value1;

// AESIV for encryption and decryption
export const Value2 = process.env.REACT_APP_Value2;

export const IsCookieEncryption = true;

// Web URL Path

export const WebURLPath = "http://sfcweb.infixsofttech.com/pageManagement/";

export const WebPath = "http://sfcweb.infixsofttech.com/";

export const GoogleMapKey = "AIzaSyCT-iv_tIKc1sINLPPiPr5RTPpFTHHI1Y0";

export const Value3 = "AuthUserA111";

export const Value4 = "TokenA111";

export const IsStagingEnvironment = false;

//export const IsUnderMaintenance = false;
