import React, { Component } from 'react'
import Table from '../../../components/table/Table'
import moment from 'moment';
import { Navigate } from 'react-router-dom';
import ActionEditButton from '../../../components/UIComponent/ActionTableComponent/ActionEditButton';
import CreateButton from '../../../components/UIComponent/Buttons/CreateButton';
import SwalServices from '../../../services/swalServices/SwalServices';
import MembershipCampaignServices from '../../../services/axiosServices/apiServices/MembershipCampaignServices';
import ActionContainer from '../../../components/UIComponent/ActionTableComponent/actionContainer';
import { encryptAES } from '../../../utils/Encryption';
import ActionToggleButton from '../../../components/UIComponent/ActionTableComponent/ActionToggleButton';

export default class MembershipCampaign extends Component {
    constructor(props) {
        super(props);
        this.swalServices = new SwalServices();
        this.MembershipCampaignServices = new MembershipCampaignServices();
        this.state = {
            redirect: null,
            totalResultes: 0,
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
            },
            getMembershipCampaign: [],
            totalCount: 0
        }

    }

    onEdit = (value, index) => {
        let editMembershipCampaign = [...this.state.getMembershipCampaign];
        this.setState({ redirect: '/AddEditMembershipCampaign/membershipCampaignId?=' + encryptAES(editMembershipCampaign[index].membershipCampaignId) });
    }

    onToggleChage = (index, event) => {
        let membershipCampaignData = this.state.getMembershipCampaign;
        let request = this.state.getMembershipCampaign[index];
        request.MembershipCampaignId = membershipCampaignData[index].MembershipCampaignId;
        if (request.statusId === 7) {
          request.statusId = 8;
        }
        else if (request.statusId === 8) {
          request.statusId = 7;
        }
        this.ActiveInActiveCampaign(request);
      }

      ActiveInActiveCampaign = (request) => {
        this.MembershipCampaignServices.activeInActiveMembershipCampaign(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            let newGetmembershipCampaign = this.state.getMembershipCampaign;
            newGetmembershipCampaign.statusId = response.responseItem.responseContent.statusId;
            this.setState({ getMembershipCampaign: { ...newGetmembershipCampaign } });
            this.GetMembershipCampaignList();
          }
          else {
            this.swalServices.Error(response.message);
          }
        })
      }

    actions = (element, index, value) => {
        return element !== "MembershipCampaignId" ? null : (
            <td className="whitespace-nowrap tble-td-txt break-all text-left">
                <button
                    className="flex items-center justify-center"
                    onClick={(e) => {
                        // this.getPartnerMemberDetails(e, value)
                    }}
                >
                    <ActionContainer>
                        <ActionEditButton
                            value={value}
                            id={index}
                            index={index}
                            onActionClick={this.onEdit.bind(this)}
                            text="Edit"
                        />
                    </ActionContainer>
                    <ActionToggleButton
                        className="mr-2"
                        index={index}
                        data={this.state.getMembershipCampaign}
                        onToggleChage={this.onToggleChage.bind(this)}
                    />
                    {/* <div className="toggle-class">
                        <input type="checkbox" id="switch"
                            // value={this.state.addcareerDetails.isJobPositionStatus}
                            identity="addcareerDetails"
                            //   checked={this.state.addcareerDetails.isJobPositionStatus}
                            name="isJobPositionStatus"
                        //   onChange={(event) => this.toggleJobPositionStatus(event)}
                        />
                        <label for="switch">Toggle</label>
                    </div> */}
                </button>
            </td>
        );

    };


    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.GetMembershipCampaignList();
    }

    componentDidMount() {
        this.GetMembershipCampaignList();
    }

    GetMembershipCampaignList = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });
        this.MembershipCampaignServices.getMembershipCampaignList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ getMembershipCampaign: response.responseItem.responseContent.itemList });
                this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
                let newGetmembershipCampaign = response.responseItem.responseContent.itemList;
                newGetmembershipCampaign.map((key, index) => {
                    key.campaignStartDate = moment(key.campaignStartDate).format('DD MMM YYYY');
                    key.campaignEndDate = moment(key.campaignEndDate).format('DD MMM YYYY');
                    key.createdAt = moment(key.createdAt).format('DD MMM YYYY');
                    key.campaignMinimumPaymentTerm = key.campaignMinimumPaymentTerm + " Year";
                    if (key.statusId === 7) {
                        key.IsActive = false;
                    }
                    else if (key.statusId === 8) {
                        key.IsActive = true;
                    }
                });
                this.setState({ getMembershipCampaign: newGetmembershipCampaign });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <>
                <div className="main-body-section">
                    <div className="flex">
                        <div className="relative flex flex-col flex-1 ">
                            <div className="mb-4 sm:mb-0">
                                {/* <h1 className="table-title-txt theme-color font-bold">
                                    Membership Campaign Table
                                </h1> */}
                                <CreateButton
                                    redirect='/AddEditMembershipCampaign'
                                    text='Create New'
                                />
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <div className="w-full mx-auto">

                            <div className="relative">
                                <div>
                                    <div className=" mt-6">
                                        <Table
                                            columns={[
                                                { name: "campaignStartDate", title: "Start Date" },
                                                { name: "campaignEndDate", title: "End Date" },
                                                { name: "campaignMinimumPaymentTerm", title: "Payment Term" },
                                                { name: "createdBy", title: "Created By" },
                                                { name: "createdAt", title: "Created Date" },
                                                { name: "statusName", title: "Status" },
                                                { name: "MembershipCampaignId", title: "Action" },
                                            ]}
                                            rows={this.state.getMembershipCampaign}
                                            isLoading={false}
                                            sortingColumns={["statusName", "campaignMinimumPaymentTerm"]}
                                            pagination={this.state.pagination}
                                            totalResultes={this.state.totalResultes}
                                            totalCount={this.state.totalResultes}
                                            setPagination={this.setPagination.bind(this)}
                                            customScope={[
                                                {
                                                    column: "MembershipCampaignId",
                                                    renderTableData: this.actions.bind(this),
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        )
    }
}
