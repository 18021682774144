import { CommonValidationMessages } from "../../../utils/Messages";

export const addEditCreditNoteValRules = {
    cancellationReason: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "cancellation reason"),
        },
    ],

};
export const addEditCreditNoteValRules2 = {
    cancellationReason: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "other cancellation reason"),
        },
    ],
    entranceRefund: [
        {
            type: "maxNumber",
            compareEle: "compareEntranceRefund",
            message: CommonValidationMessages.MaxAmount.replace("{0}", "entrance amount"),
        },
    ],
    annualRefund: [
        {
            type: "maxNumber",
            compareEle: "compareAnnualRefund",
            message: CommonValidationMessages.MaxAmount.replace("{0}", "annual amount"),
        },
    ],
    chapterRefund: [
        {
            type: "maxNumber",
            compareEle: "compareChapterRefund",
            message: CommonValidationMessages.MaxAmount.replace("{0}", "chapter amount"),
        },
    ],
    creditNoteMonth: [{
        type: "require",
        message: CommonValidationMessages.SelectRequired.replace("{0}", "effective date"),

    }]
};
export const searchByEmailValRule = {
    searchByEmail: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "valid email address"
            ),
        },
        {
            type: "maxLength",
            maxLength: 150,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "less than 150 characters"),
        },
    ],
};
export const organizationEmployeesValRules = {


    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "first name"),
        },
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "last name"),
        },
    ],
    emailText: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "email address"),
        },
    ],
    emailString: [
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
    ],
    selectedDesignation: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "designation"),
        },
    ],
    searchByEmail: [
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
        {
            type: "maxLength",
            maxLength: 150,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "less than 150 characters"),
        },

    ],

};
