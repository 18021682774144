import React from "react";
import MyEditor from "../../ckEditor/Editor";
import TinyMCEEditor from "../../ckEditor/TinyMCEEditor";
import ImageCropperWithUpload from "../../ImageUpload/UploadImage";
import { CloseButtonIcon } from "../../SVGConstants";

export default function AddGuard(props) {
    return (
        <>
          <div className="px-5 py-4 pt-10">
            <div className="mb-0">
              <div className="grid grid-cols-12 gap-6 relative">
                <div className="close-sign absolute top-0 right-0">
                  <button className="h-7 w-7 flex items-center justify-center text-[#181818] hover:text-[#3a3a3a] rounded-full border-2 border-[#181818]"
                    onClick={() => props.removeAdditionalGuest(props.identity)}>
                    <CloseButtonIcon />
                  </button>
                </div>
                <div className="2xl:col-span-8 lg:col-span-8 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="2col-span-12 lg:col-span-12 col-span-12">
                      <div>
                        <div className="grid grid-cols-12 gap-6 mb-6">
                          <div className="filter-types 2xl:col-span-8 lg:col-span-9 col-span-12">
                            <label
                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              htmlFor="default"
                            >
                              Name of Guest{" "}
                              <span className="text-[#AA3361]">*</span>
                            </label>
                            <input
                              id="default"
                              className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#181818]"
                              type="text"
                              name="eventSpeakerName"
                              value={props.speakerDetails.eventSpeakerName}
                              onChange={(event) =>
                                props.handleChange(
                                  event,
                                  "AdditionalGuest",
                                  props.identity
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-12 gap-6 mb-6">
                          <div className="filter-types 2xl:col-span-8 lg:col-span-9 col-span-12">
                            <label
                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              htmlFor="default"
                            >
                              Company <span className="text-[#AA3361]">*</span>
                            </label>
                            <input
                              id="default"
                              className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#181818]"
                              type="text"
                              name="eventSpeakerCompany"
                              value={props.speakerDetails.eventSpeakerCompany}
                              onChange={(event) =>
                                props.handleChange(
                                  event,
                                  "AdditionalGuest",
                                  props.identity
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-12 gap-6 mb-6">
                          <div className="filter-types 2xl:col-span-8 lg:col-span-9 col-span-12">
                            <label
                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              htmlFor="default"
                            >
                              Designation <span className="text-[#AA3361]">*</span>
                            </label>
                            <input
                              id="default"
                              className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#181818]"
                              type="text"
                              name="eventSpeakerDesignation"
                              value={props.speakerDetails.eventSpeakerDesignation}
                              onChange={(event) =>
                                props.handleChange(
                                  event,
                                  "AdditionalGuest",
                                  props.identity
                                )
                              }
                            />
                          </div>
                        </div>
    
                        <div className="grid grid-cols-12 gap-6">
                          <div className="filter-types 2xl:col-span-12 lg:col-span-12 col-span-12">
                            <label
                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              htmlFor="default"
                            >
                              Description <span className="text-[#AA3361]">*</span>
                            </label>
                            <div className="ck-editor-sec speaker-sec">
                              <TinyMCEEditor
                                  // key={`AdditionalDescription${identity}`}
                                  onChange={(data) => {
                                    props.setEditorData(data, "AdditionalGuest", props.identity);
                                  }}
                                  data={props.speakerDetails.eventSpeakerDescription}
                                />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    
                <div className="2xl:col-span-4 lg:col-span-4 col-span-12 mt-14">
                  <div className="speaker-img-cropper">
                    <ImageCropperWithUpload
                      key={`Guard${props.identity}`}
                      keyId={`GuardImg_${props.identity}`}
                      height={250}
                      width={250}
                      isCheckValidation={true}
                      MaxFileSize={2097152}
                      MaxFileSizeMessage={"2MB"}
                      onSaveImage={(file, id, imageIdentity) =>
                        props.saveMainImage(file, id, imageIdentity, props.identity)
                      }
                      imageIdentity={"\\Events\\AdditionalGuest"}
                      intitImage={props.speakerDetails.eventSpeakerImagePath}
                      aspactRatio={550 / 550}
                      uploadBtn={"Upload Guest Image"}
                      dimensionText={
                        "(Dimension: 250px by 250px Maximum Size: 1MB)"
                      }
                      // keyId={props.identity}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
}
