import React from "react";
import ButtonLoader from "../../common/buttonLoader/ButtonLoader";
import HistoryTable from "../../table/HistoryTable";
import ValidationText from "../../../utils/validation/ValidationText";
import moment from "moment";
import DropdownSelect from "../../dropdown/Dropdown";
import DatePicker from "../../datePicker/DatePicker";
import Selector from "../../dropdown/commonDropdown/Selector";

function EngagementTrackingNotes(props) {
  return (
    <>
      <div className="p-[25px]">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="history-table pb-4 ">
              <HistoryTable
                engagementTrackingList={props.engagementTrackingList}
                onEdit={props.onEdit}
                isCorporate={props.isCorporate}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 w-full">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Engagement Type
                </h2>
                <DropdownSelect
                  // disabled={true}
                  drpIdentity={"EngagementType"}
                  optionArray={props.engagement}
                  setFilterParameters={props.setFilterParameters}
                  value={props.engagementTrackingDetails.corporateEngagementTypeId}
                // onBlur={(e) => props.validate("isAccountActive", props.corporateSIISAccount)}
                />
                {/* <Validation error={props.validState.error.isAccountActive} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Engagement Date
                </h2>
                <div className="">
                  <DatePicker
                    name="corporateEngagementDate"
                    handleChange={props.handleChange}
                    identity="EngagementTracking"
                    value={moment(props.engagementTrackingDetails.corporateEngagementDate).format("yyyy-MM-DD")}

                  // onBlur={(e) => props.validate("corporateJoinedDate", props.corporateSIISAccount)}
                  />
                </div>
                {/* <Validation error={props.validState.error.corporateJoinedDate} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Description
                </h2>
                <textarea
                  id="default"
                  className="signup-text-area form-input rounded-none w-full shadow-red  text-lg"
                  name="corporateEngagementDescription"
                  rows="4"
                  value={props.engagementTrackingDetails.corporateEngagementDescription}
                  onChange={(event) =>
                    props.handleChange(event, "EngagementTracking")
                  }
                // onBlur={(e) =>
                //   props.validate(
                //     "corporateEngagementDescription",
                //     props.engagementTrackingDetails
                //   )
                // }
                ></textarea>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Recorded by
                  <span className="text-[#AA3361]"> *</span>
                </h2>
                {/* <Selector
                  drpIdentity="RecordedBy_Engagement"
                  options={props._by}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={false}
                  value={props._by.filter(x => x.value === props.engagementTrackingDetails.corporateEngagementRecordedById)}
                /> */}
                <DropdownSelect
                  // disabled={true}
                  drpIdentity={"RecordedBy_Engagement"}
                  optionArray={props._by}
                  setFilterParameters={props.setFilterParameters}
                  value={props.engagementTrackingDetails.corporateEngagementRecordedById}
                />
                <ValidationText error={props.validState.error.corporateEngagementRecordedById} />
              </div>
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Follow Up by
                </h2>
                <DropdownSelect
                  // disabled={true}
                  drpIdentity={"FollowUpBy"}
                  optionArray={props._by}
                  setFilterParameters={props.setFilterParameters}
                  value={props.engagementTrackingDetails.corporateEngagementFollowUpById}
                />
                {/* <Validation error={props.validState.error.isAccountActive} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-12 lg:col-span-12 col-span-12 mt-5">
                <div className="flex items-center justify-end">
                  {props && props.isLoading === true ? (
                    <ButtonLoader />
                  ) : (
                    <button
                      className="btn btn-pink text-white py-3"
                      onClick={() => props.onClick()}
                    >
                      <span className="text-2xl text-white font-semibold mr-2 rounded-full border-2 border-white h-7 w-7 flex items-center justify-center">
                        +
                      </span>
                      <span className="text-xl text-white font-semibold tracking-normal">
                        Add/Save
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EngagementTrackingNotes;
