import React from "react";
import ModalBasic from "../../../component/ModalBasic";

const SendQuotationModal = (props) => {
    return (
        <ModalBasic
            id="send-quotation-modal"
            modalOpen={props.setSendQuotationModal}
            setModalOpen={props.setOpenModal}
            title=""
            noCloseIcon={true}
        >
            <div className="text-center pb-2">
                <h1 className="text-xl text-[#181818] font-normal leading-loose p-8 py-4">
                    {/* The quotation of {props.companyName} has ben sent successfully to {props.email} */}
                    The quotation of {props.companyName} has been sent to the Official Rep and Billing Rep for this account.
                </h1>
                <button
                    className="bg-[#AA3361] text-base text-[#fff] font-bold px-12 py-2 my-4"
                    onClick={props.onClose}
                >
                    OK
                </button>
            </div >
        </ModalBasic>
    );
}
export default SendQuotationModal;   