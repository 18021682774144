import React from "react";

const SectionWithBorder = (props) => {
    return (
        <>
            <div className="border-solid border border-[#181818] w-full mb-8">
                <div className=" grid grid-cols-grid-3 border-b border-[#181818]">
                    <div className="text-xl text-left py-4 pl-4 text-[#181818] font-bold"><h2>{props.title}</h2></div>
                </div>
                {props.children}
            </div>
        </>
    );
}
export default SectionWithBorder;