import React from 'react';
import DropdownSelect from '../../dropdown/Dropdown';
import TextInput from '../../UIComponent/TextInput';
import ValidationText from '../../../utils/validation/ValidationText';

function IndividualInstitutionInformation(props) {
  return (
    <>
      <div className='p-[25px]'>
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Name of Institute
                  <span className="text-[#AA3361]">*</span>
                </h2>
                <DropdownSelect
                  // disabled={true}
                  drpIdentity={"Select_Institute"}
                  optionArray={props.institutes}
                  setFilterParameters={props.setFilterParameters}
                  value={props.institutionInformation.instituteId}
                />
                {/* <TextInput
                  placeholder=""
                  name="instituteName"
                  autoComplete={"off"}
                  identity="Institute_Information"
                  value={props.institutionInformation.instituteName}
                  handleChange={props.handleChange}
                  onBlur={() => props.validateField("instituteName", "Institute_Information")}
                /> */}
                <ValidationText error={props.validStateInstituteInformation.error.instituteId} />
              </div>
            </div>
          </div>
          {
            props && props.institutionInformation.isOtherInstitute ?
              <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
                <div className="flield-sec">
                  <label className="text-[#000] text-[20px] big-bullet-points font-bold mb-5 inline-block mobile-14">
                    Other Institute Name <span className="text-[#C00000]">*</span>
                  </label>
                  <TextInput
                    name="otherInstituteName"
                    type="text"
                    value={props.institutionInformation.otherInstituteName}
                    identity="Institute_Information"
                    handleChange={props.handleChange}
                    validateField={() => props.validateField("otherInstituteName", "Institute_Information")}
                  />
                  <ValidationText error={props.validStateInstituteInformation.error.otherInstituteName} />
                </div>
              </div>
              :
              null
          }
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Institution Website
                  <span className="text-[#AA3361]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  name="instituteWebsite"
                  autoComplete={"off"}
                  identity="Institute_Information"
                  value={props.institutionInformation.instituteWebsite}
                  handleChange={props.handleChange}
                  onBlur={() => props.validateField("instituteWebsite", "Institute_Information")}
                />
                <ValidationText error={props.validStateInstituteInformation.error.instituteWebsite} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Country of Studies
                  <span className="text-[#AA3361]">*</span>
                </h2>
                <DropdownSelect
                  // disabled={true}
                  drpIdentity={"Country_Studies"}
                  optionArray={props.studiesType}
                  setFilterParameters={props.setFilterParameters}
                  value={props.institutionInformation.countryOfStudiesId}
                />
                <ValidationText error={props.validStateInstituteInformation.error.countryOfStudiesId} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Pursuing Qualification
                  <span className="text-[#AA3361]">*</span>
                </h2>
                <DropdownSelect
                  // disabled={true}
                  drpIdentity={"Pursuing_Qualification"}
                  optionArray={props.pursuingQualifiedType}
                  setFilterParameters={props.setFilterParameters}
                  value={props.institutionInformation.pursuingQualificationId}
                />
                <ValidationText error={props.validStateInstituteInformation.error.pursuingQualificationId} />
              </div>
            </div>
          </div>
          <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="flield-sec">
              <label className="text-[#181818] text-[20px] big-bullet-points font-bold 2xl:mb-1 inline-block mobile-14">
                Specialisation / Discipline <span className="text-[#AA3361]">*</span>
              </label>
              <div className="flield-sec">
                <TextInput
                  placeholder=""
                  name="specialisation"
                  autoComplete={"off"}
                  identity="Institute_Information"
                  value={props.institutionInformation.specialisation}
                  handleChange={props.handleChange}
                  onBlur={() => props.validateField("specialisation", "Institute_Information")}
                />
                <ValidationText error={props.validStateInstituteInformation.error.specialisation} />
              </div>
            </div>
          </div>
          {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Year of Commencement
                  <span className="text-[#AA3361]">*</span>
                </h2>
                <DropdownSelect */}
                  {/* // disabled={true} */}
                  {/* drpIdentity={"Year_Commencement"}
                  optionArray={props.yearOfCompletedType}
                  setFilterParameters={props.setFilterParameters}
                  value={props.institutionInformation.yearOfCommencementId}
                />
                <ValidationText error={props.validStateInstituteInformation.error.yearOfCommencementId} />
              </div>
            </div>
          </div> */}
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Year of Completion
                  <span className="text-[#AA3361]">*</span>
                </h2>
                <DropdownSelect
                  // disabled={true}
                  drpIdentity={"Year_Completion"}
                  optionArray={props.yearOfCompletedType}
                  setFilterParameters={props.setFilterParameters}
                  value={props.institutionInformation.yearOfCompletionId}
                />
                <ValidationText error={props.validStateInstituteInformation.error.yearOfCompletionId} />
              </div>
            </div>
          </div>
        </div>
        <div className="xl:col-span-12 lg:col-span-12 col-span-12">
          <div className="flex items-center justify-end">
            <button
              className="btn btn-pink text-xl text-white font-semibold"
              onClick={() => props.addEditIndividualInstituteInformationDetails()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
export default IndividualInstitutionInformation;