import React, { useEffect, useState } from "react";
import ToggleButton from "../../common/ToggleButton";
import EntryManagementServices from "../../../services/axiosServices/apiServices/EntryManagementServices";
import SwalServices from "../../../services/swalServices/SwalServices";
import CommonServices from "../../../services/axiosServices/apiServices/CommonServices";
import { CloseButtonIcon } from "../../SVGConstants";

const entryServices = new EntryManagementServices();
const swalServices = new SwalServices();
const commonServices = new CommonServices();

const questionObj = {
  eventQuestionId: 0,
  eventId: 0,
  isMandatoryQuestion: false,
  eventQuestion: "",
  eventQuestionTypeId: 0,
  isFeedbackQuestion: false,
  questionIndex: 0,
  answers: [],
};

const answerObj = {
  eventQuestionAnswerId: 0,
  eventQuestionId: 0,
  eventQuestionAnswer: "",
  isOther: false,
};
const documentObj = {
  filename: "",
};

const validStateObj = {
  isValid: true,
  error: [],
};

const questionArray = [
  { label: "Textbox", questionTypeId: 1 },
  { label: "Radio Buttons", questionTypeId: 2 },
  { label: "Checkboxes", questionTypeId: 3 },
  { label: "Drop-down", questionTypeId: 4 },
  { label: "Multi-Select Drop-down", questionTypeId: 5 },
  { label: "Document Upload", questionTypeId: 6 },
  { label: "Label", questionTypeId: 7 },
];

function Questions(props) {
  const [questionArr, setQuestionArr] = useState([]);
  const [answerArr, serAnswerArr] = useState([]);
  const [addNewQuestion, setAddNewQuestion] = useState(false);
  const [document, setDocument] = useState(documentObj);
  const [validStateQuestion, setValidStateQuestion] = useState(validStateObj);
  const addQuestion = (isFeedbackQuestion, questionType) => {
    let question = [...questionArr];
    let isFeedback = isFeedbackQuestion;

    if (questionType === 1) {
      const obj = {
        eventQuestionId: 0,
        eventId: props.eventId,
        isMandatoryQuestion: false,
        eventQuestion: "",
        eventQuestionTypeId: 1,
        isFeedbackQuestion: isFeedback,
        answers: [],
        fileDisplayName: "",
      };

      question.push(obj);
    } else if (questionType === 2) {
      const obj = {
        eventQuestionId: 0,
        eventId: props.eventId,
        isMandatoryQuestion: false,
        eventQuestion: "",
        eventQuestionTypeId: 2,
        isFeedbackQuestion: isFeedback,
        answers: [],
        isOther: false,
        fileDisplayName: "",
      };

      obj.answers.push(answerObj);
      obj.answers.push(answerObj);
      question.push(obj);
    } else if (questionType === 3) {
      const obj = {
        eventQuestionId: 0,
        eventId: props.eventId,
        isMandatoryQuestion: false,
        eventQuestion: "",
        eventQuestionTypeId: 3,
        isFeedbackQuestion: isFeedback,
        answers: [],
        isOther: false,
        fileDisplayName: "",
      };
      obj.answers.push(answerObj);
      obj.answers.push(answerObj);
      question.push(obj);
    } else if (questionType === 4) {
      const obj = {
        eventQuestionId: 0,
        eventId: props.eventId,
        isMandatoryQuestion: false,
        eventQuestion: "",
        eventQuestionTypeId: 4,
        isFeedbackQuestion: isFeedback,
        answers: [],
        isOther: false,
        fileDisplayName: "",
      };
      obj.answers.push(answerObj);
      obj.answers.push(answerObj);
      question.push(obj);
    } else if (questionType === 5) {
      const obj = {
        eventQuestionId: 0,
        eventId: props.eventId,
        isMandatoryQuestion: false,
        eventQuestion: "",
        eventQuestionTypeId: 5,
        isFeedbackQuestion: isFeedback,
        answers: [],
        isOther: false,
        fileDisplayName: "",
      };
      obj.answers.push(answerObj);
      obj.answers.push(answerObj);
      question.push(obj);
    } else if (questionType === 6) {
      const obj = {
        eventQuestionId: 0,
        eventId: props.eventId,
        isMandatoryQuestion: false,
        eventQuestion: "",
        eventQuestionTypeId: 6,
        isFeedbackQuestion: isFeedback,
        answers: [],
        fileDisplayName: "",
      };
      obj.answers.push(answerObj);
      question.push(obj);
    } else if (questionType === 7) {
      const obj = {
        eventQuestionId: 0,
        eventId: props.eventId,
        isMandatoryQuestion: false,
        eventQuestion: "",
        eventQuestionTypeId: 7,
        isFeedbackQuestion: isFeedback,
        answers: [],
        isOther: false,
        fileDisplayName: "",
      };
      question.push(obj);
    }

    setAddNewQuestion(false);
    setQuestionArr(question);
  };

  const handleChange = (e, type, questionIndex, answerIndex) => {
    const value = e.target.value;
    const name = e.target.name;
    if (type === "Question") {
      let detail = [...questionArr];
      detail[questionIndex][name] = value;
      setQuestionArr(detail);
    } else if (type === "EventAnswer") {
      let queArr = [...questionArr];
      let ansArr = [...queArr[questionIndex].answers];

      ansArr[answerIndex] = {
        ...ansArr[answerIndex],
        eventQuestionAnswer: value,
      };

      queArr[questionIndex].answers = ansArr;
      setQuestionArr([...queArr]);
    }
  };
  const handleChangeToggle = (value, e, questionIndex, identity) => {
    let detail = [...questionArr];
    const ansObj = {
      eventQuestionAnswerId: 0,
      eventQuestionId: 0,
      eventQuestionAnswer: "",
      isOther: true,
    };

    if (identity === "Others") {
      var fields = questionIndex.split("/");
      let index = fields[1];
      detail[index].isOther = e.target.checked;
      if (e.target.checked === true) {
        detail[index].answers.push(ansObj);
      } else {
        let answer = detail[index].answers;
        let removedAns = answer.slice(0, -1);
        detail[index].answers = removedAns;
      }
    } else {
      detail[questionIndex].isMandatoryQuestion = e.target.checked;
    }
    setQuestionArr(detail);
  };

  const addAnswer = (ansArr, index, identity) => {
    let question = [...questionArr];
    let answer = [...ansArr];

    if (identity === "CheckBox") {
      if (question[index].isOther === true) {
        swalServices.Error(
          "You cant't insert new answer until other is enabled."
        );
      } else {
        answer.push(answerObj);
      }
    } else {
      answer.push(answerObj);
    }
    question[index].answers = answer;
    setQuestionArr(question);
  };

  const deleteAnswer = (ansArr, index, identity) => {
    if (ansArr.length > 2) {
      let answer = [...ansArr];
      let question = [...questionArr];
      let removedAns = [];
      const answerId = Number(ansArr[(ansArr.length - 1)].eventQuestionAnswerId);
      if (answerId > 0) {
        entryServices.deleteEventAnswerById(answerId).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (identity === "CheckBox") {
              if (question[index].isOther === true) {
                swalServices.Error("Please disable other toggle to add new options.");
                removedAns = answer;
              } else {
                removedAns = answer.slice(0, -1);
              }
            } else {
              removedAns = answer.slice(0, -1);
            }
            question[index].answers = removedAns;
            setQuestionArr(question);
            swalServices.Success("Option has been deleted successfully.");
          } else {
            swalServices.Error(response.message);
          }
        });
      }
      else {
        if (identity === "CheckBox") {
          if (question[index].isOther === true) {
            swalServices.Error("Please disable other toggle to add new options.");
            removedAns = answer;
          } else {
            removedAns = answer.slice(0, -1);
          }
        } else {
          removedAns = answer.slice(0, -1);
        }

        question[index].answers = removedAns;
        setQuestionArr(question);
      }
    } else {
      swalServices.Error("You can't remove options.");
    }
  };

  // const deleteAnswer = (ansArr, index, identity) => {
  //   if (ansArr.length > 2) {
  //     let answer = [...ansArr];
  //     let question = [...questionArr];
  //     let removedAns = [];
  //     if (identity === "CheckBox") {
  //       if (question[index].isOther === true) {
  //         swalServices.Error(
  //           "You cant't insert new answer until other is enabled."
  //         );
  //         removedAns = answer;
  //       } else {
  //         removedAns = answer.slice(0, -1);
  //       }
  //     } else {
  //       removedAns = answer.slice(0, -1);
  //     }
  //     question[index].answers = removedAns;
  //     setQuestionArr(question);
  //   } else {
  //     swalServices.Error("You can't remove options.");
  //   }
  // };

  const onFileChange = (event, questionIndex, answerIndex) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      let uploadFile = { ...document };
      uploadFile.filename = file.name;
      uploadFile["file"] = file;
      uploadFile.storagePath = "Resource";
      commonServices.uploadPDF(uploadFile).then((response) => {
        if (response != null) {
          let queArr = [...questionArr];
          let ques = queArr[questionIndex];
          let ansArr = [ques.answers];
          ansArr[answerIndex] = {
            ...ansArr[answerIndex],
            eventQuestionAnswer: response.fileName,
          };
          ques.fileDisplayName = uploadFile.filename;
          ques.answers = ansArr;
          queArr[questionIndex] = ques;

          setQuestionArr(queArr);

          swalServices.Success("File uploaded successfully.");
        } else {
          swalServices.Error(response.message);
        }
      });
    } else {
      swalServices.Error("Please Upload only PDF file !");
    }
  };
  const addEditFormQuestion = () => {
    let isValidQues = validateQuestions();
    if (isValidQues && questionArr.length > 0) {
      let request = [...questionArr];
      request.forEach((question, questionIndex) => {
        question.questionIndex = (questionIndex + 1);
      });
      entryServices.addEditFormQuestion(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          swalServices.Success("Form question inserted successfully.");
          props.navigateTo("/ViewEditEntries");
        } else {
          swalServices.Error(response.message);
        }
      });
    }
    else {
      if (questionArr.length === 0) {
        swalServices.Alert('Please add question to submit the entry');
      }
    }
  };

  const getFormQuestionByEventId = (eventId) => {
    entryServices.getFormQuestionByEventId(eventId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        setQuestionArr(response.responseItem.responseContent);
      } else {
        swalServices.Error(response.message);
      }
    });
  };

  // Validate question
  const validateQuestions = () => {
    var message = "";
    var field = "";
    var valState = { ...validStateQuestion };
    valState.isValid = true;
    var valError = [];
    questionArr.forEach((que, queIndex) => {
      if (que.eventQuestion === "") {
        message = "Please enter question " + (queIndex + 1);
        field = "question[" + (queIndex + 1) + "]";
        valError[field] = message;
        valState.isValid = false;
      }
      if (que.answers.length > 0 && !que.questionTypeId === 6) {
        que.answers.forEach((ans, ansIndex) => {
          if (ans.eventQuestionAnswer === "") {
            message =
              "Please select answer " +
              (ansIndex + 1) +
              " of question " +
              (queIndex + 1);
            field =
              "eventQuestionAnswer[" +
              (queIndex + 1) +
              "][" +
              (ansIndex + 1) +
              "]";
            valError[field] = message;
            valState.isValid = false;
          }
        });
      }
    });
    valState.error = Object.values(valError);
    setValidStateQuestion(valState);
    return valState.isValid;
  };

  useEffect(() => {
    if (props.eventId && props.eventId > 0) {
      getFormQuestionByEventId(props.eventId);
    }
  }, []);

  const deleteEventQuestionById = (index, eventQuestionId) => {
    if (Number(eventQuestionId) > 0) {
      entryServices.deleteEventQuestionById(eventQuestionId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let questionList = [...questionArr];
          questionList.splice(index, 1);
          setQuestionArr(questionList);
          swalServices.Success('Question deleted successfully');
        } else {
          swalServices.Error(response.message);
        }
      });
    }
    else {
      let questionList = [...questionArr];
      questionList.splice(index, 1);
      setQuestionArr(questionList);
    }
  }

  const moveQuestionUp = (questionIndex) => {
    let questionList = [...questionArr];
    if (questionIndex > 0) {
      let element = questionList[questionIndex];
      questionList.splice(questionIndex, 1);
      questionList.splice((questionIndex - 1), 0, element);
      setQuestionArr(questionList);
    }
  }

  const moveQuestionDown = (questionIndex) => {
    let questionList = [...questionArr];
    if (questionIndex > -1 && questionIndex < (questionList.length - 1)) {
      let element = questionList[questionIndex];
      questionList.splice(questionIndex, 1);
      questionList.splice((questionIndex + 1), 0, element);
      setQuestionArr(questionList);
    }
  }

  return (
    <div>
      {/* Form Questions Start */}
      <div className="bg-white">
        <div className="heading-part pl-7 py-4 border-b">
          <div className="flex items-center justify-between">
            <h4 className="small-title font-bold theme-color">
              Form Questions (Interest to Participate)
            </h4>
          </div>
          <div>
            <ul>
              {validStateQuestion.error.map((x, key) => {
                return <li key={key} className=" text-[#AA3361]">{x}</li>;
              })}
            </ul>
          </div>
        </div>
        <div className="2xl:px-9 2xl:py-7 lg:pr-5 lg:py-5">
          <div className="grid grid-cols-12 gap-6">
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <div className="grid grid-cols-12 gap-6 mt-7 pl-8">
                    <div className="lg:col-span-8 col-span-8">
                      <div className="question-main-sec">
                        {questionArr.map((que, questionIndex) => {
                          return (
                            <>
                              {/* TextBox */}
                              {que.eventQuestionTypeId === 1 ? (
                                <>
                                  <div
                                    className="question-types text-boxes mb-7 last:mb-0 relative"
                                    key={questionIndex}
                                  >
                                    <div className="close-sign absolute top-[-10px] right-[-10px]">
                                      <button className="h-7 w-7 flex items-center justify-center text-[#181818] hover:text-[#3a3a3a] rounded-full border-2 border-[#181818]"
                                        onClick={() => deleteEventQuestionById(questionIndex, que.eventQuestionId)}>
                                        <CloseButtonIcon />
                                      </button>
                                    </div>
                                    <div className="flex items-start w-full">
                                      <div className="arrow-btns w-10">
                                        <button className="text-[#181818]" onClick={() => moveQuestionUp(questionIndex)}>
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows-up.png"
                                            alt=""
                                          />
                                        </button>
                                        <button className="text-[#181818]" onClick={() => moveQuestionDown(questionIndex)}>
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows-down.png"
                                            alt=""
                                          />
                                        </button>
                                      </div>

                                      <div className="w-full">
                                        <div className="question-sec border border-[#e5e7eb] w-full p-6 pt-4">
                                          <div className="flex items-center justify-between mb-3">
                                            <div>
                                              {
                                                questionArray.find(
                                                  (x) =>
                                                    x.questionTypeId ===
                                                    que.eventQuestionTypeId
                                                ).label
                                              }
                                            </div>
                                            <div>
                                              <div className="required-btn text-[#3b4356]">
                                                <ToggleButton
                                                  onHandleToggle={
                                                    handleChangeToggle
                                                  }
                                                  label="Required"
                                                  setToggle={
                                                    que.isMandatoryQuestion
                                                  }
                                                  index={questionIndex}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="question-answer-sec text-[#181818] w-full">
                                            <div className="question-input">
                                              <input
                                                className="w-full border border-[#e5e7eb] font-bold text-[24px]"
                                                type="text"
                                                name="eventQuestion"
                                                value={que.eventQuestion}
                                                onChange={(e) =>
                                                  handleChange(
                                                    e,
                                                    "Question",
                                                    questionIndex
                                                  )
                                                }
                                                placeholder={`Question ${questionIndex + 1
                                                  }`}
                                              />
                                            </div>

                                            {/* {que.answers.map((ans, index) => {
                                            return (
                                              <>
                                                <div
                                                  className="answer-input"
                                                  key={index}
                                                >
                                                  <input
                                                    className="w-full mt-3 border border-[#e5e7eb] font-bold text-[24px]"
                                                    type="text"
                                                    name="eventQuestionAnswer"
                                                    value={
                                                      ans.eventQuestionAnswer
                                                    }
                                                    onChange={(e) =>
                                                      handleChange(
                                                        e,
                                                        "EventAnswer",
                                                        questionIndex,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </>
                                            );
                                          })} */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              {/* TextBox End*/}
                              {/* Radio Button */}
                              {que.eventQuestionTypeId === 2 ? (
                                <>
                                  <div
                                    className="question-types radio-buttons mb-7 last:mb-0 relative"
                                    key={questionIndex}
                                  >
                                    <div className="close-sign absolute top-[-10px] right-[-10px]">
                                      <button className="h-7 w-7 flex items-center justify-center text-[#181818] hover:text-[#3a3a3a] rounded-full border-2 border-[#181818]"
                                        onClick={() => deleteEventQuestionById(questionIndex, que.eventQuestionId)}>
                                        <CloseButtonIcon />
                                      </button>
                                    </div>
                                    <div className="flex items-start w-full">
                                      <div className="arrow-btns w-10">
                                        <button className="text-[#181818]" onClick={() => moveQuestionUp(questionIndex)}>
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows-up.png"
                                            alt=""
                                          />
                                        </button>
                                        <button className="text-[#181818]" onClick={() => moveQuestionDown(questionIndex)}>
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows-down.png"
                                            alt=""
                                          />
                                        </button>
                                      </div>

                                      <div className="question-sec border border-[#e2e2e2] w-full p-6 pt-4">
                                        <div className="flex items-center justify-between mb-3">
                                          <div>
                                            {
                                              questionArray.find(
                                                (x) =>
                                                  x.questionTypeId ===
                                                  que.eventQuestionTypeId
                                              ).label
                                            }
                                          </div>
                                          <div className="required-btn text-[#3b4356]">
                                            <ToggleButton
                                              onHandleToggle={
                                                handleChangeToggle
                                              }
                                              label="Required"
                                              setToggle={
                                                que.isMandatoryQuestion
                                              }
                                              index={questionIndex}
                                            />
                                          </div>
                                        </div>
                                        <div className="question-answer-sec text-[#181818] w-full">
                                          <div className="question-input">
                                            <input
                                              className="w-full border border-[#e2e2e2] font-bold text-[24px]"
                                              type="text"
                                              name="eventQuestion"
                                              value={que.eventQuestion}
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  "Question",
                                                  questionIndex
                                                )
                                              }
                                              placeholder={`Question ${questionIndex + 1
                                                }`}
                                            />
                                          </div>
                                          <div className="answer-input">
                                            <div className="radio-answer">
                                              <div className="mt-5">
                                                <div className="grid grid-cols-12 gap-6 items-end">
                                                  <div className="col-span-10">
                                                    <div className="grid grid-cols-12 gap-6">
                                                      {que.answers.map(
                                                        (ans, index) => {
                                                          return (
                                                            <>
                                                              <div
                                                                className="2xl:col-span-6 lg:col-span-6 col-span-12"
                                                                key={index}
                                                              >
                                                                <label className="flex items-center w-full">
                                                                  <input
                                                                    type="radio"
                                                                    name="radio1"
                                                                    id="isPage"
                                                                    className="form-radio"
                                                                  //   value={props.resource.isPage}
                                                                  //   onChange={(event) =>
                                                                  //     props.handleRadioButtons(event)
                                                                  //   }
                                                                  //   checked={props.resource.isPage === true}
                                                                  />
                                                                  {/* Akshay */}
                                                                  <div className="text-xl  font-medium theme-color ml-3 w-full">
                                                                    <input
                                                                      id={
                                                                        "Ans" +
                                                                        index
                                                                      }
                                                                      className="h-[40px] border border-[#e2e2e2] text-[#181818] w-full"
                                                                      type="text"
                                                                      name="eventQuestionAnswer"
                                                                      value={
                                                                        ans.eventQuestionAnswer
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChange(
                                                                          e,
                                                                          "EventAnswer",
                                                                          questionIndex,
                                                                          index
                                                                        )
                                                                      }
                                                                      placeholder={`Radio Answer ${index +
                                                                        1
                                                                        }`}
                                                                    />
                                                                  </div>
                                                                </label>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-span-2 mb-1 pr-2">
                                                    <div className="add-buttons flex items-center justify-end w-full ml-2">
                                                      <button
                                                        className="text-white btn-gray bg-[#b3b3b3] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center mr-2"
                                                        onClick={() =>
                                                          deleteAnswer(
                                                            que.answers,
                                                            questionIndex
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          -
                                                        </span>
                                                      </button>
                                                      <button
                                                        className="text-white btn-lighblue bg-[#81a3b8] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center"
                                                        onClick={() =>
                                                          addAnswer(
                                                            que.answers,
                                                            questionIndex
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          +
                                                        </span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              {/* Radio Button End*/}
                              {/* CheckBox */}
                              {que.eventQuestionTypeId === 3 ? (
                                <>
                                  <div
                                    className="question-types check-boxes mb-7 last:mb-0 relative"
                                    key={questionIndex}
                                  >
                                    <div className="close-sign absolute top-[-10px] right-[-10px]">
                                      <button className="h-7 w-7 flex items-center justify-center text-[#181818] hover:text-[#3a3a3a] rounded-full border-2 border-[#181818]"
                                        onClick={() => deleteEventQuestionById(questionIndex, que.eventQuestionId)}>
                                        <CloseButtonIcon />
                                      </button>
                                    </div>
                                    <div className="flex items-start w-full">
                                      <div className="arrow-btns w-10">
                                        <button className="text-[#181818]" onClick={() => moveQuestionUp(questionIndex)}>
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows-up.png"
                                            alt=""
                                          />
                                        </button>
                                        <button className="text-[#181818]" onClick={() => moveQuestionDown(questionIndex)}>
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows-down.png"
                                            alt=""
                                          />
                                        </button>
                                      </div>

                                      <div className="question-sec border border-[#e2e2e2] w-full p-6 pt-4">
                                        <div className="flex items-center justify-between mb-3">
                                          <div>
                                            {
                                              questionArray.find(
                                                (x) =>
                                                  x.questionTypeId ===
                                                  que.eventQuestionTypeId
                                              ).label
                                            }
                                          </div>
                                          <div className="required-btn text-[#3b4356] flex">
                                            <div>
                                              <ToggleButton
                                                onHandleToggle={
                                                  handleChangeToggle
                                                }
                                                label="Required"
                                                setToggle={
                                                  que.isMandatoryQuestion
                                                }
                                                index={questionIndex}
                                              />
                                            </div>
                                            <div>
                                              <ToggleButton
                                                onHandleToggle={
                                                  handleChangeToggle
                                                }
                                                label="Others"
                                                setToggle={que.isOther}
                                                index={`Others/${questionIndex}`}
                                                identity="Others"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="question-answer-sec text-[#181818] w-full">
                                          <div className="question-input ">
                                            <input
                                              className="w-full border border-[#e2e2e2] font-bold text-[24px]"
                                              type="text"
                                              name="eventQuestion"
                                              value={que.eventQuestion}
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  "Question",
                                                  questionIndex
                                                )
                                              }
                                              placeholder={`Question ${questionIndex + 1
                                                }`}
                                            />
                                          </div>
                                          <div className="answer-input">
                                            <div className="radio-answer">
                                              <div className="mt-5">
                                                <div className="grid grid-cols-12 gap-6 items-end">
                                                  <div className="col-span-10">
                                                    <div className="grid grid-cols-12 gap-6">
                                                      {que.answers.map(
                                                        (ans, index) => {
                                                          return (
                                                            <>
                                                              {ans.isOther ===
                                                                true ? (
                                                                <div className="2xl:col-span-12 lg:col-span-12 col-span-12 other-checkbox" key={index}>
                                                                  <label className="flex items-center w-full custom-checkbox-set">
                                                                    <input
                                                                      type="checkbox"
                                                                      name="checkbox-buttons"
                                                                      className="cursor-pointer h-4 w-4 text-[#AA3361] focus:ring-[#AA3361] bg-white rounded check-box-custom"
                                                                    />
                                                                    <div className="text-xl  font-medium theme-color ml-3 w-full">
                                                                      <input
                                                                        className="h-[40px] border border-[#e2e2e2] text-[#181818] w-full"
                                                                        type="text"
                                                                        name="eventQuestionAnswer"
                                                                        value={
                                                                          ans.eventQuestionAnswer
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleChange(
                                                                            e,
                                                                            "EventAnswer",
                                                                            questionIndex,
                                                                            index
                                                                          )
                                                                        }
                                                                        placeholder="Others"
                                                                      />
                                                                    </div>
                                                                  </label>
                                                                </div>
                                                              ) : (
                                                                <>
                                                                  <div
                                                                    className="2xl:col-span-6 lg:col-span-6 col-span-12"
                                                                    key={index}
                                                                  >
                                                                    <label className="flex items-center w-full custom-checkbox-set">
                                                                      <input
                                                                        type="checkbox"
                                                                        name="checkbox-buttons"
                                                                        className="cursor-pointer h-4 w-4 text-[#AA3361] focus:ring-[#AA3361] bg-white rounded check-box-custom"
                                                                      />
                                                                      <div className="text-xl  font-medium theme-color ml-3 w-full">
                                                                        <input
                                                                          className="h-[40px] border border-[#e2e2e2] text-[#181818] w-full"
                                                                          type="text"
                                                                          name="eventQuestionAnswer"
                                                                          value={
                                                                            ans.eventQuestionAnswer
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            handleChange(
                                                                              e,
                                                                              "EventAnswer",
                                                                              questionIndex,
                                                                              index
                                                                            )
                                                                          }
                                                                          placeholder={`CheckBox ${index +
                                                                            1
                                                                            }`}
                                                                        />
                                                                      </div>
                                                                    </label>
                                                                  </div>
                                                                </>
                                                              )}
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-span-2 mb-1 pr-2">
                                                    <div className="add-buttons flex items-center justify-end w-full ml-2">
                                                      <button
                                                        className="text-white btn-gray bg-[#b3b3b3] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center mr-2"
                                                        onClick={() =>
                                                          deleteAnswer(
                                                            que.answers,
                                                            questionIndex,
                                                            "CheckBox"
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          -
                                                        </span>
                                                      </button>
                                                      <button
                                                        className="text-white btn-lighblue bg-[#81a3b8] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center"
                                                        onClick={() =>
                                                          addAnswer(
                                                            que.answers,
                                                            questionIndex,
                                                            "CheckBox"
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          +
                                                        </span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              {/* CheckBox End*/}

                              {/* Dropdown */}
                              {que.eventQuestionTypeId === 4 ? (
                                <>
                                  <div
                                    className="question-types check-boxes mb-7 last:mb-0 relative"
                                    key={questionIndex}
                                  >
                                    <div className="close-sign absolute top-[-10px] right-[-10px]">
                                      <button className="h-7 w-7 flex items-center justify-center text-[#181818] hover:text-[#3a3a3a] rounded-full border-2 border-[#181818]"
                                        onClick={() => deleteEventQuestionById(questionIndex, que.eventQuestionId)}>
                                        <CloseButtonIcon />
                                      </button>
                                    </div>
                                    <div className="flex items-start w-full">
                                      <div className="arrow-btns w-10">
                                        <button className="text-[#181818]" onClick={() => moveQuestionUp(questionIndex)}>
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows-up.png"
                                            alt=""
                                          />
                                        </button>
                                        <button className="text-[#181818]" onClick={() => moveQuestionDown(questionIndex)}>
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows-down.png"
                                            alt=""
                                          />
                                        </button>
                                      </div>

                                      <div className="question-sec border border-[#e2e2e2] w-full p-6 pt-4">
                                        <div className="flex items-center justify-between mb-3">
                                          <div>
                                            {
                                              questionArray.find(
                                                (x) =>
                                                  x.questionTypeId ===
                                                  que.eventQuestionTypeId
                                              ).label
                                            }
                                          </div>
                                          <div className="required-btn text-[#3b4356]">
                                            <div>
                                              <ToggleButton
                                                onHandleToggle={
                                                  handleChangeToggle
                                                }
                                                label="Required"
                                                setToggle={
                                                  que.isMandatoryQuestion
                                                }
                                                index={questionIndex}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="question-answer-sec text-[#181818] w-full">
                                          <div className="question-input dropdown-question">
                                            <input
                                              className="w-full border border-[#e2e2e2] font-bold text-[24px]"
                                              type="text"
                                              name="eventQuestion"
                                              value={que.eventQuestion}
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  "Question",
                                                  questionIndex
                                                )
                                              }
                                              placeholder={`Question ${questionIndex + 1
                                                }`}
                                            />
                                          </div>
                                          <div className="answer-input">
                                            <div className="radio-answer">
                                              <div className="mt-5">
                                                <div className="grid grid-cols-12 gap-6 items-end">
                                                  <div className="col-span-10">
                                                    <div className="grid grid-cols-12 gap-6">
                                                      {que.answers.map(
                                                        (ans, index) => {
                                                          return (
                                                            <>
                                                              <div
                                                                className="2xl:col-span-6 lg:col-span-6 col-span-12"
                                                                key={index}
                                                              >
                                                                <label className="flex items-center w-full custom-checkbox-set">
                                                                  <span>
                                                                    {index + 1}.
                                                                  </span>
                                                                  <div className="text-xl  font-medium theme-color ml-3 w-full">
                                                                    <input
                                                                      className="h-[40px] border border-[#e2e2e2] text-[#181818] w-full"
                                                                      type="text"
                                                                      name="eventQuestionAnswer"
                                                                      value={
                                                                        ans.eventQuestionAnswer
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChange(
                                                                          e,
                                                                          "EventAnswer",
                                                                          questionIndex,
                                                                          index
                                                                        )
                                                                      }
                                                                      placeholder={`Answer ${index +
                                                                        1
                                                                        }`}
                                                                    />
                                                                  </div>
                                                                </label>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-span-2 mb-1 pr-2">
                                                    <div className="add-buttons flex items-center justify-end w-full ml-2">
                                                      <button
                                                        className="text-white btn-gray bg-[#b3b3b3] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center mr-2"
                                                        onClick={() =>
                                                          deleteAnswer(
                                                            que.answers,
                                                            questionIndex
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          -
                                                        </span>
                                                      </button>
                                                      <button
                                                        className="text-white btn-lighblue bg-[#81a3b8] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center"
                                                        onClick={() =>
                                                          addAnswer(
                                                            que.answers,
                                                            questionIndex
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          +
                                                        </span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              {/* Dropdown  End*/}
                              {/* Multi Select */}
                              {que.eventQuestionTypeId === 5 ? (
                                <>
                                  <div
                                    className="question-types check-boxes mb-7 last:mb-0 relative"
                                    key={questionIndex}
                                  >
                                    <div className="close-sign absolute top-[-10px] right-[-10px]">
                                      <button className="h-7 w-7 flex items-center justify-center text-[#181818] hover:text-[#3a3a3a] rounded-full border-2 border-[#181818]"
                                        onClick={() => deleteEventQuestionById(questionIndex, que.eventQuestionId)}>
                                        <CloseButtonIcon />
                                      </button>
                                    </div>
                                    <div className="flex items-start w-full">
                                      <div className="arrow-btns w-10">
                                        <button className="text-[#181818]" onClick={() => moveQuestionUp(questionIndex)}>
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows-up.png"
                                            alt=""
                                          />
                                        </button>
                                        <button className="text-[#181818]" onClick={() => moveQuestionDown(questionIndex)}>
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows-down.png"
                                            alt=""
                                          />
                                        </button>
                                      </div>

                                      <div className="question-sec border border-[#e2e2e2] w-full p-6 pt-4">
                                        <div className="flex items-center justify-between mb-3">
                                          <div>
                                            {
                                              questionArray.find(
                                                (x) =>
                                                  x.questionTypeId ===
                                                  que.eventQuestionTypeId
                                              ).label
                                            }
                                          </div>
                                          <div className="required-btn text-[#3b4356]">
                                            <div>
                                              <ToggleButton
                                                onHandleToggle={
                                                  handleChangeToggle
                                                }
                                                label="Required"
                                                setToggle={
                                                  que.isMandatoryQuestion
                                                }
                                                index={questionIndex}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="question-answer-sec text-[#181818] w-full">
                                          <div className="question-input dropdown-multi-question">
                                            <input
                                              className="w-full border border-[#e2e2e2] font-bold text-[24px]"
                                              type="text"
                                              name="eventQuestion"
                                              value={que.eventQuestion}
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  "Question",
                                                  questionIndex
                                                )
                                              }
                                              placeholder={`Question ${questionIndex + 1
                                                }`}
                                            />
                                          </div>
                                          <div className="answer-input">
                                            <div className="radio-answer">
                                              <div className="mt-5">
                                                <div className="grid grid-cols-12 gap-6 items-end">
                                                  <div className="col-span-10">
                                                    <div className="grid grid-cols-12 gap-6">
                                                      {que.answers.map(
                                                        (ans, index) => {
                                                          return (
                                                            <>
                                                              <div
                                                                className="2xl:col-span-6 lg:col-span-6 col-span-12"
                                                                key={index}
                                                              >
                                                                <label className="flex items-center w-full custom-checkbox-set">
                                                                  <span>
                                                                    {index + 1}.
                                                                  </span>
                                                                  <div className="text-xl  font-medium theme-color ml-3 w-full">
                                                                    <input
                                                                      className="h-[40px] border border-[#e2e2e2] text-[#181818] w-full"
                                                                      type="text"
                                                                      name="eventQuestionAnswer"
                                                                      value={
                                                                        ans.eventQuestionAnswer
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleChange(
                                                                          e,
                                                                          "EventAnswer",
                                                                          questionIndex,
                                                                          index
                                                                        )
                                                                      }
                                                                      placeholder={`Answer ${index +
                                                                        1
                                                                        }`}
                                                                    />
                                                                  </div>
                                                                </label>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-span-2 mb-1 pr-2">
                                                    <div className="add-buttons flex items-center justify-end w-full ml-2">
                                                      <button
                                                        className="text-white btn-gray bg-[#b3b3b3] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center mr-2"
                                                        onClick={() =>
                                                          deleteAnswer(
                                                            que.answers,
                                                            questionIndex
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          -
                                                        </span>
                                                      </button>
                                                      <button
                                                        className="text-white btn-lighblue bg-[#81a3b8] min-w-[28px] min-h-[28px] max-w-[28px] max-h-[28px] rounded-[7px] text-center"
                                                        onClick={() =>
                                                          addAnswer(
                                                            que.answers,
                                                            questionIndex
                                                          )
                                                        }
                                                      >
                                                        <span className="text-[25px] leading-[28px]">
                                                          +
                                                        </span>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              {/* Multi Select End*/}
                              {/* Document Upload */}
                              {que.eventQuestionTypeId === 6 ? (
                                <>
                                  <div
                                    className="question-types check-boxes mb-7 last:mb-0 relative"
                                    key={questionIndex}
                                  >
                                    <div className="close-sign absolute top-[-10px] right-[-10px]">
                                      <button className="h-7 w-7 flex items-center justify-center text-[#181818] hover:text-[#3a3a3a] rounded-full border-2 border-[#181818]"
                                        onClick={() => deleteEventQuestionById(questionIndex, que.eventQuestionId)}>
                                        <CloseButtonIcon />
                                      </button>
                                    </div>
                                    <div className="flex items-start w-full">
                                      <div className="arrow-btns w-10">
                                        <button className="text-[#181818]" onClick={() => moveQuestionUp(questionIndex)}>
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows-up.png"
                                            alt=""
                                          />
                                        </button>
                                        <button className="text-[#181818]" onClick={() => moveQuestionDown(questionIndex)}>
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows-down.png"
                                            alt=""
                                          />
                                        </button>
                                      </div>

                                      <div className="question-sec border border-[#e2e2e2] w-full p-6 pt-4">
                                        <div className="flex items-center justify-between mb-3">
                                          <div>
                                            {
                                              questionArray.find(
                                                (x) =>
                                                  x.questionTypeId ===
                                                  que.eventQuestionTypeId
                                              ).label
                                            }
                                          </div>
                                          <div className="required-btn text-[#3b4356]">
                                            <div>
                                              <ToggleButton
                                                onHandleToggle={
                                                  handleChangeToggle
                                                }
                                                label="Required"
                                                setToggle={
                                                  que.isMandatoryQuestion
                                                }
                                                index={questionIndex}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="question-answer-sec text-[#181818] w-full">
                                          <div className="question-input">
                                            <input
                                              className="w-full border border-[#e2e2e2] font-bold text-[24px]"
                                              type="text"
                                              name="eventQuestion"
                                              value={que.eventQuestion}
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  "Question",
                                                  questionIndex
                                                )
                                              }
                                              placeholder={`Question ${questionIndex + 1
                                                }`}
                                            />
                                            {/* {que.answers.map((ans, index) => {
                                              return (
                                                <>
                                                  <div
                                                    className="mt-5"
                                                    key={index}
                                                  >
                                                    <div className="flex items-center url-part">
                                                      <div className="flex item-center flex-col">
                                                        <form className="custom-uploader border flex items-center border-[#c2c2c2] text-center px-10 py-1 cursor-pointer">
                                                          <label
                                                            htmlFor={`FileUploadQ${questionIndex}A${index}`}
                                                            className="font-bold text-[24px] block text-sm text-[#c2c2c2] cursor-pointer"
                                                          >
                                                            Upload
                                                          </label>
                                                          <input
                                                            key={`FileUploadQ${questionIndex}A${index}`}
                                                            className="sr-only"
                                                            id={`FileUploadQ${questionIndex}A${index}`}
                                                            type="file"
                                                            onChange={(event) =>
                                                              onFileChange(
                                                                event,
                                                                questionIndex,
                                                                index
                                                              )
                                                            }
                                                          />
                                                        </form>
                                                      </div>
                                                      <div>
                                                        {que.fileDisplayName}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            })} */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              {/* Document Upload End*/}
                              {/* label */}
                              {que.eventQuestionTypeId === 7 ? (
                                <>
                                  <div
                                    className="question-types check-boxes mb-7 last:mb-0 relative"
                                    key={questionIndex}
                                  >
                                    <div className="close-sign absolute top-[-10px] right-[-10px]">
                                      <button className="h-7 w-7 flex items-center justify-center text-[#181818] hover:text-[#3a3a3a] rounded-full border-2 border-[#181818]"
                                        onClick={() => deleteEventQuestionById(questionIndex, que.eventQuestionId)}>
                                        <CloseButtonIcon />
                                      </button>
                                    </div>
                                    <div className="flex items-start w-full">
                                      <div className="arrow-btns w-10">
                                        <button className="text-[#181818]" onClick={() => moveQuestionUp(questionIndex)}>
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows-up.png"
                                            alt=""
                                          />
                                        </button>
                                        <button className="text-[#181818]" onClick={() => moveQuestionDown(questionIndex)}>
                                          <img
                                            className="w-5"
                                            src="/awipe-arrows-down.png"
                                            alt=""
                                          />
                                        </button>
                                      </div>

                                      <div className="question-sec border border-[#e2e2e2] w-full p-6 pt-4">
                                        <div className="flex items-center justify-between mb-3">
                                          <div>
                                            {
                                              questionArray.find(
                                                (x) =>
                                                  x.questionTypeId ===
                                                  que.eventQuestionTypeId
                                              ).label
                                            }
                                          </div>
                                          <div className="required-btn text-[#3b4356]">
                                            <div>
                                              <ToggleButton
                                                onHandleToggle={
                                                  handleChangeToggle
                                                }
                                                label="Required"
                                                setToggle={
                                                  que.isMandatoryQuestion
                                                }
                                                index={questionIndex}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="question-answer-sec text-[#181818] w-full">
                                          <div className="question-input">
                                            <textarea
                                              className="w-full border border-[#e2e2e2] font-bold text-[24px] text-area-space h-[110px]"
                                              type="text"
                                              name="eventQuestion"
                                              value={que.eventQuestion}
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  "Question",
                                                  questionIndex
                                                )
                                              }
                                              placeholder={`Question ${questionIndex + 1
                                                }`}
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              {/* label End*/}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="lg:col-span-4 col-span-4">
                      <div className="add-question relative w-full flex justify-end">
                        <button
                          className="p-3 btn btn-sm btn-pink text-lg font-bold text-white"
                          onClick={() => {
                            setAddNewQuestion(true);
                          }}
                        >
                          Add a Question
                          <span className="">
                            <img src="/angle-up.svg" alt="angle-up" className="ml-4 w-4 h-4"></img>
                          </span>
                        </button>

                        {addNewQuestion === true ? (
                          <div
                            className="add-question-part down-option-section border bg-white border-[#e2e2e2] absolute w-full top-[150%] z-10"
                            onMouseLeave={() => setAddNewQuestion(false)}
                          >
                            <ul>
                              <li className="transition-[0.8s] hover:transition-[0.8s] hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                                <button
                                  className="flex 2xl:items-center lg:items-start items-start"
                                  onClick={() => addQuestion(false, 1)}
                                >
                                  <div className="icon-left">
                                    <img
                                      src="/textbox.jpg"
                                      className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                      alt=""
                                    />
                                  </div>
                                  <div className="right-menu-tab pl-4 text-[#3b4356]">
                                    <div className="title-txt text-left 2xl:text-[16px] lg:text-[14px] text-[14px]">
                                      Textbox
                                    </div>
                                    <div className="title-desc text-[12px]">
                                      Provide an open-text response
                                    </div>
                                  </div>
                                </button>
                              </li>
                              <li className="transition-[0.8s] hover:transition-[0.8s] hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                                <button
                                  className="flex 2xl:items-center lg:items-start items-start"
                                  onClick={() => addQuestion(false, 4)}
                                >
                                  <div className="icon-left">
                                    <img
                                      src="/dropdown.jpg"
                                      className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                      alt=""
                                    />
                                  </div>
                                  <div className="right-menu-tab pl-4 text-[#3b4356]">
                                    <div className="title-txt text-left 2xl:text-[16px] lg:text-[14px] text-[14px]">
                                      Drop-down
                                    </div>
                                    <div className="title-desc text-[12px]">
                                      Select a single answer from a list of
                                      options
                                    </div>
                                  </div>
                                </button>
                              </li>
                              <li className="transition-[0.8s] hover:transition-[0.8s] hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                                <button
                                  className="flex 2xl:items-center lg:items-start items-start"
                                  onClick={() => addQuestion(false, 5)}
                                >
                                  <div className="icon-left">
                                    <img
                                      src="/dropdown.jpg"
                                      className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                      alt=""
                                    />
                                  </div>
                                  <div className="right-menu-tab pl-4 text-[#3b4356]">
                                    <div className="title-txt text-left 2xl:text-[16px] lg:text-[14px] text-[14px]">
                                      Multi-Select Drop-down
                                    </div>
                                    <div className="title-desc text-[12px]">
                                      Select a multiple answer from a list of
                                      options
                                    </div>
                                  </div>
                                </button>
                              </li>
                              <li className="hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                                <button
                                  className="flex 2xl:items-center lg:items-start items-start"
                                  onClick={() => addQuestion(false, 2)}
                                >
                                  <div className="icon-left">
                                    <img
                                      src="/radio.jpg"
                                      className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                      alt=""
                                    />
                                  </div>
                                  <div className="right-menu-tab pl-4 text-[#3b4356]">
                                    <div className="title-txt text-left 2xl:text-[16px] lg:text-[14px] text-[14px]">
                                      Radio Buttons
                                    </div>
                                    <div className="title-desc text-[12px]">
                                      Select a single answer from a list of
                                      options
                                    </div>
                                  </div>
                                </button>
                              </li>
                              <li className="hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                                <button
                                  className="flex 2xl:items-center lg:items-start items-start"
                                  onClick={() => addQuestion(false, 3)}
                                >
                                  <div className="icon-left">
                                    <img
                                      src="/checkbox.jpg"
                                      className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                      alt=""
                                    />
                                  </div>
                                  <div className="right-menu-tab pl-4 text-[#3b4356]">
                                    <div className="title-txt text-left 2xl:text-[16px] lg:text-[14px] text-[14px]">
                                      Checkboxes
                                    </div>
                                    <div className="title-desc text-[12px]">
                                      Select multiple answer from a list of
                                      options
                                    </div>
                                  </div>
                                </button>
                              </li>
                              <li className="hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                                <button
                                  className="flex 2xl:items-center lg:items-start items-start"
                                  onClick={() => addQuestion(false, 6)}
                                >
                                  <div className="icon-left">
                                    <img
                                      src="/fileUploader.jpg"
                                      className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                      alt=""
                                    />
                                  </div>
                                  <div className="right-menu-tab pl-4 text-[#3b4356]">
                                    <div className="title-txt  text-left 2xl:text-[16px] lg:text-[14px] text-[14px]">
                                      Document Upload
                                    </div>
                                    <div className="title-desc text-[12px]">
                                      A button to upload files
                                    </div>
                                  </div>
                                </button>
                              </li>
                              <li className="hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                                <button
                                  className="flex 2xl:items-center lg:items-start items-start"
                                  onClick={() => addQuestion(false, 7)}
                                >
                                  <div className="icon-left">
                                    <img
                                      src="/lable.jpg"
                                      className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                      alt=""
                                    />
                                  </div>
                                  <div className="right-menu-tab pl-4 text-[#3b4356]">
                                    <div className="title-txt text-left 2xl:text-[16px] lg:text-[14px] text-[14px]">
                                      Label
                                    </div>
                                    <div className="title-desc text-[12px]">
                                      An instruction in a question
                                    </div>
                                  </div>
                                </button>
                              </li>
                            </ul>
                          </div>
                        ) : null}
                        <div className="down-option-section border bg-white border-[#e2e2e2] absolute w-full top-[150%] hidden">
                          <ul>
                            <li className="transition-[0.8s] hover:transition-[0.8s] hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                              <a className="flex 2xl:items-center lg:items-start items-start">
                                <div className="icon-left">
                                  <img
                                    src="/textbox.jpg"
                                    className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                    alt=""
                                  />
                                </div>
                                <div className="right-menu-tab pl-4 text-[#3b4356]">
                                  <div className="title-txt text-[16px]">
                                    Textbox
                                  </div>
                                  <div className="title-desc text-[12px]">
                                    Provide an open-text response
                                  </div>
                                </div>
                              </a>
                            </li>
                            {/* <li className="hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                            <a className="flex 2xl:items-center lg:items-start items-start">
                              <div className="icon-left">
                                <img
                                  src="/dropdown.jpg"
                                  className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                />
                              </div>
                              <div className="right-menu-tab pl-4 text-[#3b4356]">
                                <div className="title-txt text-[16px]">
                                  Drop-down
                                </div>
                                <div className="title-desc text-[12px]">
                                  Select a single answer from a list of
                                  options
                                </div>
                              </div>
                            </a>
                          </li> */}
                            <li className="hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                              <a className="flex 2xl:items-center lg:items-start items-start">
                                <div className="icon-left">
                                  <img
                                    src="/radio.jpg"
                                    className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                    alt=""
                                  />
                                </div>
                                <div className="right-menu-tab pl-4 text-[#3b4356]">
                                  <div className="title-txt text-[16px]">
                                    Radio Buttons
                                  </div>
                                  <div className="title-desc text-[12px]">
                                    Select a single answer from a list of
                                    options
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li className="hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                              <a className="flex 2xl:items-center lg:items-start items-start">
                                <div className="icon-left">
                                  <img
                                    src="/checkbox.jpg"
                                    className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                    alt=""
                                  />
                                </div>
                                <div className="right-menu-tab pl-4 text-[#3b4356]">
                                  <div className="title-txt text-[16px]">
                                    Checkboxes
                                  </div>
                                  <div className="title-desc text-[12px]">
                                    Select multiple answer from a list of
                                    options
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li className="hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                              <a className="flex 2xl:items-center lg:items-start items-start">
                                <div className="icon-left">
                                  <img
                                    src="/fileUploader.jpg"
                                    className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                    alt=""
                                  />
                                </div>
                                <div className="right-menu-tab pl-4 text-[#3b4356]">
                                  <div className="title-txt text-[16px]">
                                    Document Upload
                                  </div>
                                  <div className="title-desc text-[12px]">
                                    A button to upload files
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li className="hover:bg-[#e6ebf1] 2xl:p-[15px] lg:p-[10px] p-[7px]">
                              <a className="flex 2xl:items-center lg:items-start items-start">
                                <div className="icon-left">
                                  <img
                                    src="/lable.jpg"
                                    className="min-h-[70px] min-w-[70px] max-h-[70px] max-w-[70px]"
                                    alt=""
                                  />
                                </div>
                                <div className="right-menu-tab pl-4 text-[#3b4356]">
                                  <div className="title-txt text-[16px]">
                                    Label
                                  </div>
                                  <div className="title-desc text-[12px]">
                                    An instruction in a question
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Form Questions End */}

      {/* footer Button Start*/}
      <div className="grid grid-cols-12 gap-6">
        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
          <div className="flex flex-wrap justify-between space-x-10 mt-12">
            <div>
              <button
                className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white"
                onClick={() => {
                  props.backFromQuestions();
                  props.executeScroll();
                }}
              >
                Back
              </button>
            </div>
            <div>
              {/* {props.resource.isPage ? ( */}
              <button
                className="w-32 px-7 py-2 btn-sm btn-pink text-lg text-white"
                onClick={() => {
                  addEditFormQuestion();
                  props.executeScroll();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* footer Button End*/}
    </div>
  );
}
export default Questions;
