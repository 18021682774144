import React, { Component } from "react";
import { formatPrice } from "../../components/common/FormatePrice";

export default class CreditNoteApprovalProcess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setUpApprovalProcessTbleData: [
        {
          id: "1",
          startingAmount: "0",
          endingAmount: "1000",
          approvalEmail: "sookkim@sgtech.com",
        },
        {
          id: "2",
          startingAmount: "1000",
          endingAmount: "No Limit",
          approvalEmail: "ED@sgtech.com",
        },
      ],
    };
  }
  render() {
    return (
      <div className="main-body-section finance-body-sec">
        {/* Set Up Approval Process Start */}
        <div className="bg-white">
          <div className="heading-part pl-7 py-4 border-b">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                Set Up Approval Process
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-6 pb-12">
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
              <table className="w-full mx-auto my-5 fixed_header setup-approval-process-table">
                <thead>
                  <tr className="text-base font-bold flex">
                    <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[30%]">
                      <div className="font-bold tble-th-txt text-left">
                        Starting Amount
                      </div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[30%]">
                      <div className="font-bold tble-th-txt text-left">
                        Ending Amount
                      </div>
                    </th>
                    <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                      <div className="font-bold tble-th-txt text-left">
                        Approval Email
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody className="scrollit text-sm custom-tbody globle-setting-tbody">
                  {this.state.setUpApprovalProcessTbleData.map((e,key) => {
                    return (
                      <tr className="custom-tbl-td flex items-center" key={key}>
                        <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[30%] ">
                          {/* {e.startingAmount} */}
                         {formatPrice(e.startingAmount)}
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[30%]">
                          {/* {e.endingAmount} */}
                          {formatPrice(e.endingAmount)}
                        </td>
                        <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                          {e.approvalEmail}
                        </td>
                      </tr>
                    );
                  })}
                  {/* <tr className="grid justify-items-center">
              <td className="text-lg text-[#757575]	">No Records Found</td>
            </tr> */}
                </tbody>
              </table>
            </div>
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
              <div className="pt-7 flex items-center justify-end w-full">
                <button className="btn btn-red-border text-xl font-bold border text-white ">
                <span className="mr-3 fa fa-plus"></span>
                  Add More
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Set Up Approval Process End */}
      </div>
    );
  }
}
