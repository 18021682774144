import React, { Component } from "react";
import AccountInformation from "../../../components/membership/corporateMember/AccountInformation";
import EngagementTrackingNotes from "../../../components/membership/corporateMember/EngagementTrackingNotes";
import MembershipSubscription from "../../../components/membership/corporateMember/MembershipSubscription";
import CompanyProfile from "../../../components/membership/corporateMember/CompanyProfile";
import OrganisationRole from "../../../components/membership/corporateMember/OrganisationRole";
import TextInput from "../../../components/UIComponent/TextInput";
import EmployeeList from "../../../components/membership/corporateMember/EmployeeList";
import Finance from "../../../components/membership/corporateMember/Finance";
import FinancialsRecord from "../../../components/membership/corporateMember/FinancialsRecord";
import { validate, isValidForm } from "../../../utils/validation/CommonValidator";
import {
  EngagementTrackingValidationRules,
  CompanyProfileValidationRules,
  OrganisationKeyRoleValidationRules,
  PointOfContactInformationValidationRules,
  FinanceDepartmentValidationRules,
  OrganisationEmployeeValidationRules,
  RepresantativeInformationValidationRules,
} from "../../../utils/validation/AllValidationRules";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";
import { checkParameters } from "../../../utils/Encryption";
import { Navigate } from "react-router-dom";
import { CommonSuccessMessages, CommonValidationMessages, ErrorMessage, SuccessMessage } from "../../../utils/Messages";

// Import API Services
import MembershipCorporateServices from "../../../services/axiosServices/apiServices/MembershipCorporateServices";
import CommonServices from "../../../services/axiosServices/apiServices/CommonServices";
import SwalServices from "../../../services/swalServices/SwalServices";
import moment from "moment";
import { saveAs } from 'file-saver';
import FinanceServices from "../../../services/axiosServices/apiServices/FinanceServices";
import { addEditCreditNoteValRules, addEditCreditNoteValRules2, searchByEmailValRule, organizationEmployeesValRules, } from "./CorporateValidations";
import { monthDifference } from "../../../utils/DateUtils";
import { convertToDecimal, isNullBoolean, isNullString } from "../../../utils/Utils";
import BusinessPresence from "./BusinessPresence";
import OrganisationBasicInformation from "../../../components/membership/corporateMember/OrganisationBasicInformation";

export default class CreateCorporateMembers extends Component {
  constructor(props) {
    super(props);
    this.membershipCorporateServices = new MembershipCorporateServices();
    this.financeService = new FinanceServices();
    this.commonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.state = {
      open: 1,
      adminRoleId: 0,
      openSection: "CAI",
      isLoading: false,
      engagementTrackingList: [],
      status: [],
      reason: [],
      _by: [],
      recorded_by: [],
      billingCategory: [],
      membershipTypes: [],
      countriesSingleDrp: [],
      engagement: [],
      salutation: [],
      countries: [],
      companyType: [],
      annualTurnOver: [],
      companyEmployeementSize: [],
      companyBusinessType: [],
      companyInterestType: [],
      getToKnowUs: [],
      corporateMemberId: 0,
      disableUenNumber: false,
      setOrganisationKeyRoleModal: false,
      setOrganisationEmployeeModal: false,
      jobFunctionList: [],
      organisationRole: [],
      organisationRolesList: [],
      organizationRoleArray: [],
      organisationKeyRoleList: [],
      organisationEmployeeList: [],
      emailDomain: [],
      nameSearch: "",
      selectKnowAboutUsOthers: false,
      isEmployeeModalEditable: false,
      organisationKeyRoleModal: false,
      // Corporate Account Information
      accountBasicInformation: {
        corporateSIISAccountId: 0,
        siisAccountId: 0,
        accountStatus: 7,
        engagementScore: 0,
        corporateJoinedDate: null,
        corporateReasonLeavingId: 0,
        updatedAt: null,
        updatedBy: 0,
        createdBy: 1,
        updatedDate: "",
        // quotationNeeded:false,
        // sendInvoiceReminder:0,
        // creaditTermsId:0,
        companyDomain: "",
      },
      // Engagement Tracking and notes
      engagementTrackingDetails: {
        corporateEngagementFollowUpById: 0,
        corporateEngagementRecordedById: 0,
        corporateEngagementTrackingId: 0,
        corporateEngagementTypeId: 0,
        corporateMemberId: 0,
        corporateEngagementDate: "",
        corporateEngagementDescription: "",
        updatedAt: "",
        updatedBy: 0,
      },
      // Corporate Membership Subscription
      membershipSubscription: {
        corporateMembershipSubscriptionId: 0,
        membershipTypeId: 0,
        billingCategoryId: 0,
        subscriptionStartDate: null,
        subscriptionEndDate: null,
        updatedBy: 0,
        updatedAt: null,
        incorporationDate: "",
        membershipRevenueId: 0,
      },
      designationList: [],
      // Company Profile 
      corporateMemberDetail: {
        companyProfile: {
          companyProfileId: 0,
          companyName: "",
          brandName: "",
          description: "",
          companyUEN: "",
          incorporationDate: "",
          countryOfIncorporationId: 153,
          companyTypeId: 0,
          companyAddress1: "",
          companyAddress2: "",
          postalCode: "",
          countryId: 0,
          website: "",
          corporateMemberId: 0,
          updatedBy: 0,
          annualSalesTurnoverId: 0,
          employmentSizeId: 0,
          businessTypeId: 0,
          interestId: 0,
          knowAboutUsId: 0,
          aCRAFilePath: "",
          selectedBusinessIds: "",
          selectedInterestedIds: "",
          interestIds: [],
          businessTypeIds: [],
          businessRegistrationNo: "",
          getToKnow: "",
          otherBusinessTypeIds: "",
          otherCompanyIntereststed: "",
          knowAboutUsOthers: "",
          isOtherBusinessType: false,
          isOtherCompanyInterested: false,
          isKnowAboutUsOther: false,
          companyWebsite: "",
        },
        pointOfContactInformation: {
          salutationId: 0,
          firstName: "",
          lastName: "",
          designation: "",
          jobPositionId: 0,
          businessEmail: "",
          businessContactNumber: "",
        },
        // financeDepartment: {
        //   salutationId: 0,
        //   firstName: "",
        //   lastName: "",
        //   designation: "",
        //   jobPositionId: 0,
        //   isRequireQuotation: false,
        //   isDeclareCorrect: false,
        //   isAgreeTermsAndConditions: false,
        //   isGrantPermissionOfData: false,
        //   businessEmail: "",
        //   businessContactNumber: "",
        //   isFinanceDepartmentContact: false,
        // },

      },
      corporateOrganisationDetails: {
        mrMrsId: 0,
        firstName: "",
        lastName: "",
        designation: "",
        jobPositionId: 0,
        businessEmail: "",
        businessContactNo: "",
      },
      validStatePointofContactInformation: {
        isValid: true,
        error: {},
      },
      validStateFinanceDepartmentContact: {
        isValid: true,
        error: {},
      },
      validStateRepresantativeInformation: {
        isValid: true,
        error: {},
      },
      // Business Presence Market Interest 
      companyPresenceList: [],
      marketIntersetList: [],
      businessPresenceDetails: {
        selectedCompanyPresence: [],
        selectedMarketInterest: [],
        businessPresenceId: "",
        MarketInterestId: "",

      },
      employeeDetails: {
        mrMrs: 0,
        mrMrsId: 0,
        contactNo: "",
        corporateMemberId: 0,
        designation: "",
        email: "",
        firstName: "",
        individualMemberId: 0,
        lastName: "",
        selectedDesignation: [],
        selectedOrganisationRole: [],
        organisationRoleList: [],
        organisationRoleId: 0,
        organisationRole: "",
        emailDomain: "",
        title: "",
        emailDomainId: 0,
        searchByEmail: '',
        emailText: "",
        emailString: "",
      },

      corporateFinance: {
        quotationNeeded: false,
        sendInvoiceReminder: true,
        creditLimit: true,
        creditTermsId: 1,
        financeId: 0,
        corporateMemberId: 0,
      },
      validStateEmp: {
        isValid: true,
        error: {},
      },
      validStateRoles: {
        isValid: true,
        error: {},
      },

      // Organisation Key Role
      organisationKeyRoleDetail: {
        individualMemberId: 0,
        salutationId: 0,
        mrMrs: 0,
        firstName: "",
        lastName: "",
        email: "",
        contactNo: "",
        designation: "",
        organisationRole: 0,
        corporateMemberId: 0,
        emailDomainId: 0,
        emailText: "",
        emailString: "",
        selectedDesignation: [],
        selectedOrganisationRole: [],
        organisationRoleList: [],
        searchByEmail: '',
        organisationRoleId: 0,
        jobPositionId: 0,
        searchByEmail: '',

      },
      corporateCreditNotePaymentInfo: {},
      cancellationReason: [],
      isOtherCancellationReason: false,
      addEditCreditNote: {
        membershipPaymentId: 0,
        entranceRefund: 0,
        annualRefund: 0,
        chapterRefund: 0,
        subTotalRefund: 0,
        gstRefund: 0,
        discountRefund: 0,
        totalRefund: 0,
        cancellationReason: "",
        creditNoteMonth: "",
        compareEntranceRefund: 0,
        compareAnnualRefund: 0,
        compareChapterRefund: 0,
        membershipPaymentTypeId: 0,
        paidTotalAmount: 0,
        //added by jigar
        refundMembershipEntranceFee: 0,
        refundAnnualFee: 0,
        refundSubtotal: 0,
        refundGST: 0,
        refundDiscount: 0,
        refundTotalRefundAmount: 0
      },

      isViewOnly: false,
      companyUENValidationRule: [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace("{0}", "company UEN"),
        },
        {
          type: "isCompanyUEN",
          message: "Please enter valid company UEN"
        },
      ],
      aCRAFilePathValidationRule: [
        {
          type: "require",
          message: CommonValidationMessages.SelectRequired.replace("{0}", "ACRA file"),
        }
      ],
      businessRegistrationNoValidationRule: [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace("{0}", "business registration number"),
        },
      ],
      countryOfIncorporationIdValidationRule: [
        {
          type: "require",
          message: CommonValidationMessages.SelectRequired.replace("{0}", "country of incorporation"),
        }
      ],
      otherBusinessTypeValidationRule: [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace(
            "{0}",
            "other business interests"
          ),
        },
      ],
      otherCompanyIntereststedValidationRule: [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace(
            "{0}",
            "other company interests"
          ),
        },
      ],
      knowAboutUsOthersValidationRule: [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace(
            "{0}",
            "other channels"
          ),
        },
      ],
      searchByEmailValidationRule: [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace("{0}", "search email"),
        },
        {
          type: "email",
          message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        }
      ],
      validStateCompanyProfile: {
        isValid: true,
        error: {},
      },
      validStateEngagementTracking: {
        isValid: true,
        error: {},
      },
      validStateOrganisationKeyRole: {
        isValid: true,
        error: {},
      },
      validStateOrgEmployee: {
        isValid: true,
        error: {},
      },
      validCreditNote: {
        isValid: true,
        error: {}
      },
      validCreditNote2: {
        isValid: true,
        error: {}
      },
      isCreateNewOrgRoleForEmployee: true,
      isCreateNewOrgRole: false,
      corporateFinanceInfo: [],
    };
  }

  componentDidMount = () => {
    let params = new URLSearchParams(window.location.search);

    // Check for View Only
    let checkRead = window.location.search;
    let getRead = checkRead.split("=");
    let checkFinal = getRead[2];
    if (checkFinal === "ViewCorp") {
      this.setState({ isViewOnly: true });
    }

    let corporateId = params.get("")
    let loginUser = getAuthProps();
    if (loginUser?.adminUserId && loginUser.adminUserId > 0) {
      this.setState({ adminRoleId: loginUser.adminUserId });
    } else {
      this.setState({ adminRoleId: 0 });
    }
    if (corporateId && (corporateId = checkParameters(corporateId, "Number"))) {
      if (corporateId != null && corporateId > 0) {
        this.setState({ corporateMemberId: corporateId }, () => { this.setState({ disableUenNumber: true }); });
      }
    }
    this.getAllDropdownForCorporateMember();
  }

  // Get All Dropdown for Corporate Member Method
  getAllDropdownForCorporateMember = () => {
    this.commonServices.getAllDropdownForCorporateMember().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let status = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "AccountStatus"
        );
        status = status.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let reason = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "ReasonForLeaving"
        );
        reason = reason.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let _by = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "By"
        );
        _by = _by.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let billingCategory = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "BillingCategory"
        );
        billingCategory = billingCategory.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let membershipTypes = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "MembershipTypes"
        );
        membershipTypes = membershipTypes.map((p) => ({
          id: p.id,
          name: p.name
        }));
        membershipTypes = membershipTypes.filter(s => s.id === 1 || s.id === 2);
        let countriesSingleDrp = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Countries"
        );
        countriesSingleDrp = countriesSingleDrp.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let engagement = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "EngagementType"
        );
        engagement = engagement.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let salutation = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Salutation"
        );
        salutation = salutation.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let countries = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CountryOfIncorporation"
        );
        countries = countries.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let companyType = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CompanyType"
        );
        companyType = companyType.map((p) => ({
          id: p.id,
          name: p.name
        }));
        //companyType.splice(1, 1);
        let annualTurnOver = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CompanyAnnualSaleTurnover"
        );
        annualTurnOver = annualTurnOver.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let companyEmployeementSize = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CompanyEmploymentSize"
        );
        companyEmployeementSize = companyEmployeementSize.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let companyBusinessType = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CompanyBusinessType"
        );
        companyBusinessType = companyBusinessType.map((p) => ({
          value: p.id,
          label: p.name
        }));
        let companyInterestType = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CompanyInterestType"
        );
        companyInterestType = companyInterestType.map((p) => ({
          value: p.id,
          label: p.name
        }));
        let getToKnowUs = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "GetToKnowUs"
        );
        getToKnowUs = getToKnowUs.map((p) => {
          return {
            value: p.id,
            label: p.name
          }
        });
        let organisationRoles = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "OrganisationRoles");
        organisationRoles.map(function (item) {
          return delete item["dropdownName"];
        });
        let designation = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Designation");
        designation.map(function (item) {
          return delete item["dropdownName"];
        });
        designation = designation.map((p) => ({ id: p.id, name: p.name }));
        organisationRoles = organisationRoles.map((p) => ({ value: p.id, label: p.name }));

        // Company Presence Dropdown
        let companyPre = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "CompanyPresence");
        companyPre.map(function (item) {
          return delete item["dropdownName"];
        });
        companyPre = companyPre.map((p) => ({ value: p.id, label: p.name }));

        // Market Interest Dropdown
        let marketInter = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "MarketInterest");
        marketInter.map(function (item) {
          return delete item["dropdownName"];
        });
        marketInter = marketInter.map((p) => ({ value: p.id, label: p.name }));

        let domain = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Domains"
        );
        let domainArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Domains"
        );

        this.setState({
          status: status, reason: reason, _by: _by, billingCategory: billingCategory, membershipTypes: membershipTypes,
          countriesSingleDrp: countriesSingleDrp, engagement: engagement, salutation: salutation, countries: countries,
          companyType: companyType, annualTurnOver: annualTurnOver, companyEmployeementSize: companyEmployeementSize,
          companyBusinessType: companyBusinessType, companyInterestType: companyInterestType, getToKnowUs: getToKnowUs,
          organisationRolesList: organisationRoles, jobFunctionList: designation, companyPresenceList: companyPre, marketIntersetList: marketInter
        }, () => {
          if (this.state.corporateMemberId > 0) {
            this.getCorporateAccountInformationById(this.state.corporateMemberId);

            this.setState({ emailDomain: domain }, () =>
              this.getDomainByOrganisationId(this.state.corporateMemberId)
            );
            this.setState({ domainArr: domainArr }, () =>
              this.getDomainByOrganisationId(this.state.corporateMemberId)
            );
          }

        }
        )
      }
    })
  }
  validateSearchByEmailFieldForEmployee = (key) => {
    const returnValidState = validate(key, this.state.employeeDetails, searchByEmailValRule, this.state.validStateEmp);
    this.setState({ validStateEmp: returnValidState });
    return returnValidState.isValid;
  }
  validateEmployeeInfo = (key, object) => {
    const returnValidState = validate(key, this.state.employeeDetails, organizationEmployeesValRules, this.state.validStateEmp);
    this.setState({ validStateEmp: returnValidState, });
  };

  validateSearchByEmailField = (key) => {
    const returnValidState = validate(key, this.state.organisationKeyRoleDetail, searchByEmailValRule, this.state.validStateRoles);
    this.setState({ validStateRoles: returnValidState });
    return returnValidState.isValid;
  }
  // Get Corporate Account Inforamtion Detail Method
  getCorporateAccountInformationById = (corporateMemberId) => {
    if (corporateMemberId > 0) {
      this.membershipCorporateServices.getCorporateAccountInformationById(corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let res = { ...this.state.accountBasicInformation };
          res.corporateSIISAccountId = response.responseItem.responseContent.corporateSIISAccountId;
          res.siisAccountId = response.responseItem.responseContent.siisAccountId;
          // res.engagementScore = response.responseItem.responseContent.engagementScore;
          res.accountStatus = response.responseItem.responseContent.accountStatus;
          res.corporateJoinedDate = response.responseItem.responseContent.corporateJoinedDate;
          res.corporateReasonLeavingId = response.responseItem.responseContent.corporateReasonLeavingId;
          // res.renewalNotes = response.responseItem.responseContent.renewalNotes;
          res.updatedAt = response.responseItem.responseContent.updatedDate;
          res.updatedBy = response.responseItem.responseContent.updatedBy;
          res.updatedDate = response.responseItem.responseContent.updatedDate;
          // res.internalFlagId = response.responseItem.responseContent.internalFlagId;
          res.companyDomain = response.responseItem.responseContent.companyDomain;
          // res.isFeatured = response.responseItem.responseContent.isFeatured;
          // res.projectName = response.responseItem.responseContent.projectName;
          // res.isGoGlobal = response.responseItem.responseContent.isGoGlobal;
          this.setState({ accountBasicInformation: res });

          var finance = this.state.corporateFinance;
          finance.creditTermsId = response.responseItem.responseContent.creditTermsId !== null ? response.responseItem.responseContent.creditTermsId : 1;
          finance.quotationNeeded = response.responseItem.responseContent.quotationNeeded;
          finance.sendInvoiceReminder = response.responseItem.responseContent.sendInvoiceReminder === true ? "true" : "false";

          this.setState({ corporateFinance: finance })
        }
        else {
          // this.getOrganisationRoleList(this.state.corporateMemberId);
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }
  // Get Engagement Tracking and Notes List Method
  getEngagementTrackingListById = (id) => {
    if (id > 0 && id != null) {
      this.membershipCorporateServices.getEngagementTrackingListById(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.length > 0) {
            var updatedDate = new Date(Math.max(...response.responseItem.responseContent.map(e => new Date(e.lastUpdatedDate))));
            //const found = response.responseItem.responseContent.find(element => new Date(element.lastUpdatedDate) === updatedDate);
            var engagement = this.state.engagementTrackingDetails;
            engagement.lastUpdatedDate = updatedDate;
            engagement.updatedAt = updatedDate;
            engagement.updatedBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
            this.setState({
              engagementTrackingList: response.responseItem.responseContent,
            }, () => this.setState({ engagementTrackingDetails: engagement }));
          }
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  };
  // Get Engagement Tracking and Notes Detail By Id Method
  onEditEngagement = (id) => {
    this.getEngagementTrackingById(id);
  };
  getEngagementTrackingById = (id) => {
    this.membershipCorporateServices.getEngagmentDetailsById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let data = this.state.engagementTrackingDetails;
        let resp = response.responseItem.responseContent;

        data.corporateEngagementFollowUpById = resp.corporateEngagementFollowUpBy;
        data.corporateEngagementRecordedById = resp.corporateEngagementRecordedById;
        data.corporateEngagementTrackingId = resp.corporateEngagementTrackingId;
        data.corporateEngagementTypeId = resp.corporateEngagementTypeId;
        data.corporateMemberId = resp.corporateMemberId;
        data.corporateEngagementDate = resp.engagementDate;
        data.corporateEngagementDescription = resp.engagementDescription;

        this.setState({
          engagementTrackingDetails: data
        });
      } else {
        //this.swalServices.Error(response.message);
      }
    });
  };
  // Corporate Membership Subscription Detail Method
  getCorporateMembershipSubscriptionById = (id) => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      this.membershipCorporateServices.getCorporateMembershipSubscriptionById(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.membershipSubscription };
            //     corporateMemberSubscriptionId: 0,
            // membershipTypeId: 0,
            // billingCategoryId: 0,
            // subscriptionStartDate: null,
            // subscriptionEndDate: null,
            // updatedBy: 0,
            // updatedAt: null,
            // incorporationDate: "",
            // membershipRevenueId: 0,
            res.corporateMemberSubscriptionId = response.responseItem.responseContent.corporateMemberSubscriptionId;
            res.membershipTypeId = response.responseItem.responseContent.membershipTypeId;
            res.billingCategoryId = response.responseItem.responseContent.billingCategoryId;
            res.subscriptionStartDate = response.responseItem.responseContent.subscriptionStartDate;
            res.subscriptionEndDate = response.responseItem.responseContent.subcriptionEndDate;
            res.updatedBy = response.responseItem.responseContent.updatedBy;
            res.updatedAt = response.responseItem.responseContent.updatedAt;
            res.membershipRevenueId = response.responseItem.responseContent.membershipRevenueId;
            res.incorporationDate = response.responseItem.responseContent.incorporationDate;
            this.setState({ membershipSubscription: res });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }
  // Company Profile Detail Method
  getOrganisationProfileById = (id, isCorporate) => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      // let request = new Object;
      // request.id = this.state.corporateMemberId;
      // request.isCorporate = isCorporate;
      let request = {
        id: this.state.corporateMemberId,
        isCorporate: isCorporate
      }

      this.membershipCorporateServices.getOrganisationProfileById(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let detail = { ...this.state.corporateMemberDetail };
            let data = detail.companyProfile;
            let resp = response.responseItem.responseContent;
            data.companyName = resp.companyName;
            data.brandName = resp.brandName;
            data.companyTypeId = resp.companyTypeId;
            data.companyUEN = resp.companyUEN;
            data.businessRegistrationNo = resp.businessRegistrationNo;
            data.incorporationDate = resp.incorporationDate;
            data.countryOfIncorporationId = resp.countryOfIncorporationId;
            data.companyAddress1 = resp.companyAddress1;
            data.companyAddress2 = resp.companyAddress2;
            data.postalCode = resp.postalCode;
            data.countryId = resp.countryId;
            // data.companyWebsite = resp.companyWebsite;
            data.aCRAFilePath = resp.acraCertificatePath;
            data.description = resp.companyDescription;
            data.annualSalesTurnoverId = resp.companyAnnualSalesTurnoverId;
            data.employmentSizeId = resp.companyEmploymentSizeId;
            data.businessTypeIds = resp.typeOfBusinessId;
            data.interestIds = resp.companyInterestedId;
            data.corporateMemberId = resp.corporateMemberId;
            data.companyProfileId = resp.companyProfileId;
            data.website = resp.companyWebsite;
            data.knowAboutUsId = resp.aboutUsId;
            data.knowAboutUsOthers = resp.aboutUsOtherName;
            data.selectedBusinessIds = resp.typeOfBusinessId;
            data.selectedInterestedIds = resp.companyInterestedId;
            data.businessRegistrationNo = resp.businessRegistrationNo;

            data.otherCompanyIntereststed = resp.otherCompanyInterests;
            data.otherBusinessTypeIds = resp.otherBusinessTypeId;
            let array = data.selectedBusinessIds && data.selectedBusinessIds !== '0'
              ? data.selectedBusinessIds.split(",")
              : [];
            data.businessTypeIds = [];
            array.forEach((topic) => {
              data.businessTypeIds.push(
                this.state.companyBusinessType.find(
                  (topics) => topics.value === Number(topic)
                )
              );
            });

            array = (data.selectedInterestedIds && data.selectedInterestedIds !== '0') ? data.selectedInterestedIds.split(',') : [];
            data.interestIds = [];
            array.forEach(topic => {
              data.interestIds.push(this.state.companyInterestType.find(topics => topics.value === Number(topic)));
            });
            data.businessTypeIds.map((obj) => {
              if (obj.label === "Others (please specify)") {
                data.isOtherBusinessType = true;
              }
            });
            data.interestIds.map((obj) => {
              if (obj.label === "Others,please specify") {
                data.isOtherCompanyInterested = true;
              }
            });
            if (data.knowAboutUsId === 14) {
              this.setState({ selectKnowAboutUsOthers: true })
            }
            detail.companyProfile = data;
            this.setState({ corporateMemberDetail: detail });
            this.setState({ companyProfile: data });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
        // if (response.responseItem && response.responseItem.responseContent && response.responseItem.responseContent.aboutUsId === 6) {
        //   this.state.corporateMemberDetail.companyProfile.isKnowAboutUsOther = true;
        // }
        // else {
        //   this.state.corporateMemberDetail.companyProfile.isKnowAboutUsOther = false;
        // }
      })
    }
  }

  // // Company Business Presence and Market Interest
  getBusinessPresenceAndMarketInterestById = (id, isCorporate) => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      id = this.state.corporateMemberId;
      //request.isCorporate = isCorporate;
      this.membershipCorporateServices.getBusinessPresenceAndMarketInterestById(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let resp = response.responseItem.responseContent;
          let data = { ...this.state.businessPresenceDetails };
          // let data = detail.companyProfile;
          // let resp = response.responseItem.responseContent;
          data.businessPresenceId = resp.companyPresenceId;
          data.MarketInterestId = resp.marketInterestId;

          let array = (data.businessPresenceId && data.businessPresenceId !== '0') ? data.businessPresenceId.split(",") : [];
          data.selectedCompanyPresence = [];
          array.forEach(topic => {
            data.selectedCompanyPresence.push(this.state.companyPresenceList.find(topics => topics.value === Number(topic)));
          })

          array = (data.MarketInterestId && data.MarketInterestId !== '0') ? data.MarketInterestId.split(',') : [];
          data.selectedMarketInterest = [];
          array.forEach(topic => {
            data.selectedMarketInterest.push(this.state.marketIntersetList.find(topics => topics.value === Number(topic)));
          });
          this.setState({ businessPresenceDetails: data });
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  // Get All Organisation Key Role List
  getOrganisationKeyRolesListById = (id) => {
    if (id > 0 && id != null) {
      this.membershipCorporateServices.getOrganisationKeyRolesListById(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({ organisationKeyRoleList: response.responseItem.responseContent }, () => {
          });
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }
  // Get Organisation Key Role Detail Method
  getOrganisationKeyRolesById = (id) => {
    if (id > 0) {
      this.membershipCorporateServices.getOrganisationKeyRolesById(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {

          let data = { ...this.state.organisationKeyRoleDetail };
          let resp = response.responseItem.responseContent;

          data.corporateMemberId = resp.corporateMemberId;
          data.individualMemberId = resp.individualMemberId;
          data.salutationId = resp.mrMrs;
          data.firstName = resp.firstName;
          data.lastName = resp.lastName;
          data.email = resp.email;
          data.contactNo = resp.contactNo;
          data.designation = resp.individualMemberId;
          data.organisationRole = resp.organisationRole;
          data.organisationRoleId = resp.organisationRoleId;
          data.designation = resp.designation;
          data.jobPositionId = resp.jobPositionId

          let array = (data.organisationRole && data.organisationRole !== '0') ? data.organisationRole.split(",") : [];
          data.selectedOrganisationRole = [];
          array.forEach(topic => {
            data.selectedOrganisationRole.push(this.state.organisationRolesList.find(topics => topics.value === Number(topic)));
          })

          this.setState({
            organisationKeyRoleDetail: data
          });


          // let array2 = (data.jobPositionId && data.jobPositionId !== '0') ? data.jobPositionId : [];
          data.selectedDesignation = [];

          //  array2.forEach(topic => {
          if (data.jobPositionId > 0 && data.jobPositionId != null) {
            data.selectedDesignation.push(this.state.jobFunctionList.find(topics => topics.value === data.jobPositionId));
          }
          //  })

          this.setState({
            organisationKeyRoleDetail: data, isEmployeeModalEditable: true
          });

        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  };
  getDomainByOrganisationId = (id) => {
    // var domainId = this.state.emailDomain.filter(domain => domain.id === id);
    // this.setState({emailDomain : domainId});
    if (id > 0) {
      var domainId = this.state.emailDomain.find((domain) => domain.id === id);
      if (domainId) {
        let setDomain = this.state.employeeDetails;
        let setDomainOrg = this.state.organisationKeyRoleDetail;
        if (domainId.id && domainId.id > 0) {
          setDomain.emailDomainId = domainId.id;
          setDomainOrg.emailDomainId = domainId.id;
          this.setState({
            employeeDetails: setDomain,
            organisationDetails: setDomain,
          });
        }
      }
    }
  };
  searchIndividualMemberByEmailForOrgRole = (identity) => {
    debugger
    if (this.state.corporateMemberId > 0 && ((identity === "Organisarion Key Roles" && this.validateSearchByEmailField("searchByEmail")) || (identity === "Organisarion Employees" && this.validateSearchByEmailFieldForEmployee("searchByEmail")))) {
      let req = {
        email: '',
        corporateMemberId: 0,
      }
      if (identity === "Organisarion Key Roles") {
        req.email = this.state.organisationKeyRoleDetail.searchByEmail;
      }
      if (identity === "Organisarion Employees") {
        req.email = this.state.employeeDetails.searchByEmail;
      }
      req.corporateMemberId = this.state.corporateMemberId;
      this.membershipCorporateServices.searchIndividualMemberByEmailForOrgRole(req).then((response) => {
        console.log("By Email Search :", response.responseItem.responseContent)
        if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.hasError === '0') {
          let isEditable = true;
          if (response.responseItem.responseContent.mrMrsId > 0) {
            response.responseItem.responseContent.mrMrsId = response.responseItem.responseContent.mrMrsId;
          } else {
            isEditable = true
          }
          response.responseItem.responseContent.title = response.responseItem.responseContent.designation;
          if (response.responseItem.responseContent.designation !== null && response.responseItem.responseContent.designation !== "") {
            var array = response.responseItem.responseContent.designation.split(',');
            var designation = [];
            array.forEach(topic => {
              designation.push(this.state.designationList.find(deg => deg.label === topic));
            });
          } else {
            isEditable = true
          }
          if (response.responseItem.responseContent.organisationRole !== null && response.responseItem.responseContent.organisationRole !== "") {
            var array2 = response.responseItem.responseContent.organisationRole.split(',');
            var organisationRole = [];
            array2.forEach(topic => {
              organisationRole.push(this.state.organisationRolesList.find(deg => deg.value === Number(topic)));
            });
          } else {
            isEditable = true
          }
          if (identity === "Organisarion Key Roles") {
            let organisationDetailsObj = { ...this.state.organisationKeyRoleDetail };
            response.responseItem.responseContent.searchByEmail = organisationDetailsObj.searchByEmail;
            this.setState(
              { organisationKeyRoleDetail: response.responseItem.responseContent },
              () => {
                let data = { ...this.state.organisationKeyRoleDetail };
                data.selectedDesignation = designation;
                data.selectedOrganisationRole = organisationRole;
                data.mrMrs = response.responseItem.responseContent.mrMrsId;
                this.setState({ organisationKeyRoleDetail: data });
              }
            );
            // this.resetEmployeeInfo()
            let details = response.responseItem.responseContent;
            // var fields = details.email.split("@");
            // details.emailText = fields[0];
            details.emailText = req.email;
            details.emailDomainId = details.corporateMemberId;
            this.setState({ organisationKeyRoleDetail: details, isEmployeeModalEditable: isEditable });
            // isEditable = false;
          }
          if (identity === "Organisarion Employees") {
            // individual employess 
            let employeeDetailsObj = { ...this.state.employeeDetails };
            response.responseItem.responseContent.searchByEmail = employeeDetailsObj.searchByEmail;
            this.setState(
              { employeeDetails: response.responseItem.responseContent },
              () => {
                let data = { ...this.state.employeeDetails };
                data.selectedDesignation = designation;
                data.selectedOrganisationRole = organisationRole;
                this.setState({ employeeDetails: data });
              }
            );
            // this.resetEmployeeInfo()
            let details = response.responseItem.responseContent;
            // var fields = details.email.split("@");
            // details.emailText = fields[0];
            details.emailText = req.email;
            details.emailDomainId = details.corporateMemberId;
            this.setState({ employeeDetails: details, isEmployeeModalEditable: isEditable });
          }
          //this.resetEmployeeInfo()
        }
        else if (response.responseItem.responseContent.hasError === '1') {

          this.setState({ isOrganisationModalEditable: true, isEmployeeModalEditable: true }, () => {
            if (identity === "Organisarion Key Roles") {
              // this.resetEmployeeInfo("")
              // this.handleReset(req.email);
              this.resetEmployeeInfo(req.email);
            }
            if (identity === "Organisarion Employees") {
              // this.resetEmployeeInfo("")
              this.resetEmployeeInfo(req.email)
            }
            this.swalServices.Alert('No records found');
          });
        }
        else if (response.responseItem.responseContent.hasError === '2') {
          this.setState({ isOrganisationModalEditable: false, isEmployeeModalEditable: false, isCreateNewOrgRoleForEmployee: true, isCreateNewOrgRole: true }, () => {
            if (identity === "Organisarion Key Roles") {
              this.resetEmployeeInfo("")
            }
            if (identity === "Organisarion Employees") {
              this.resetEmployeeInfo("")
            }
            this.swalServices.Alert(response.responseItem.responseContent.error);
          });
        }
        else {
          this.swalServices.Error(response.message);
        }
      });
    }
    else {
      this.swalServices.Alert('Please enter valid email address');
    }
  };

  //Get Corporate Representative Details By Id
  getCorporateRepresentativeDetailsById = (corporateMemberId) => {
    if (corporateMemberId > 0) {
      this.membershipCorporateServices.getCorporateRepresentativeDetailsById(corporateMemberId).then((response) => {
        debugger;
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          let data = { ...this.state.corporateOrganisationDetails }
          let resp = response.responseItem.responseContent;
          data.firstName = resp.firstName;
          data.lastName = resp.lastName;
          data.jobPositionId = resp.jobPositionId;
          data.mrMrsId = resp.salutationId;

          data.selectedDesignation = [];

          //  array2.forEach(topic => {
          if (data.jobPositionId > 0 && data.jobPositionId != null) {
            data.selectedDesignation.push(this.state.jobFunctionList.find(topics => topics.value === data.jobPositionId));
          }
          data.designation = resp.designation;
          data.businessEmail = resp.businessEmail;
          data.businessContactNo = resp.businessContactNo;

          this.setState({ corporateOrganisationDetails: data })
          console.log("Corporate Representative Details By Id", this.state.corporateOrganisationDetails);
        }
        else {
          this.swalServices.Error(response.message);
        }
      })
    }
  }
  // Get Employee List Method
  searchByName = (value) => {
    if (value.length > 2) {
      this.getEmployeeList(this.state.corporateMemberId, value)
    }
    else if (value.length < 3) {
      this.getEmployeeList(this.state.corporateMemberId, "")
    }
  }
  getEmployeeList = (id, nameSearch) => {
    if (id > 0 && id != null) {
      let request = [id, nameSearch]
      this.membershipCorporateServices.getEmployeeList(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {

          this.setState({ organisationEmployeeList: response.responseItem.responseContent });
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }
  getEmployeeInfoById = (id) => {
    //akshay
    this.corporateMemberServices.getEmployeeInfoById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {


        if (response.responseItem.responseContent.title !== null && response.responseItem.responseContent.title !== "") {
          var array = response.responseItem.responseContent.title.split(',');
          var designation = [];
          array.forEach(topic => {
            designation.push(this.state.designationList.find(deg => deg.label === topic));
          });
        }
        this.setState({
          employeeDetails: response.responseItem.responseContent, isEmployeeModalEditable: false,
          // isCreateNewOrgRoleForEmployee: false
        }, () => {
          let data = { ...this.state.employeeDetails };
          data.selectedDesignation = designation;
          this.setState({ employeeDetails: data });
        });

        let details = response.responseItem.responseContent;
        // var fields = details.email.split("@");
        // details.emailText = fields[0];
        details.emailText = details.email;
        details.emailDomainId = details.corporateMemberId;
        this.setState({ organisationRoleDetail: details });
      } else {
        // this.swalServices.Error(response.message);
      }
    });
  };
  // Get Emloyee Detail By Id Method
  getEmployeeInformationById = (id) => {
    this.membershipCorporateServices.getEmployeeInformationById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {

        let data = { ...this.state.employeeDetails };
        let resp = response.responseItem.responseContent;

        data.corporateMemberId = resp.corporateMemberId;
        data.individualMemberId = resp.individualMemberId;
        data.mrMrsId = resp.mrMrsId;
        data.firstName = resp.firstName;
        data.lastName = resp.lastName;
        data.email = resp.email;
        data.contactNo = resp.contactNo;
        data.designation = resp.title;
        data.organisationRole = resp.organisationRole;
        data.organisationRoleId = resp.organisationRoleId;
        data.jobPositionId = resp.jobPositionId;

        let array = (data.organisationRole && data.organisationRole !== '0') ? data.organisationRole.split(",") : [];
        data.selectedOrganisationRole = [];
        array.forEach(topic => {
          data.selectedOrganisationRole.push(this.state.organisationRolesList.find(topics => topics.value === Number(topic)));
        })
        this.setState({
          employeeDetails: data
        });

        data.selectedDesignation = [];
        if (data.jobPositionId > 0 && data.jobPositionId != null) {
          data.selectedDesignation.push(this.state.jobFunctionList.find(topics => topics.value === data.jobPositionId));
        }
        this.setState({ employeeDetails: data });

        if (response.responseItem.responseContent.title !== null && response.responseItem.responseContent.title !== "") {
          var array1 = response.responseItem.responseContent.title.split(',');
          var designation = [];
          array1.forEach(topic => {
            designation.push(this.state.designationList.find(deg => deg.label === topic));
          });
        }
        this.setState({
          employeeDetails: response.responseItem.responseContent, isEmployeeModalEditable: true,
          // isCreateNewOrgRoleForEmployee: false 
        },
          () => {
            let data1 = { ...this.state.employeeDetails };
            data1.selectedDesignation = designation;
            this.setState({ employeeDetails: data1 });
          });

        let details1 = response.responseItem.responseContent;
        details1.emailText = details1.email;
        details1.emailDomainId = details1.corporateMemberId;
        this.setState({ organisationRoleDetail: details1 });
      } else {
        this.swalServices.Error("Not get the response");
      }
    });
  };

  // Get Finance Information Method

  // Get Financial Record Method
  getCorporateFinancialInfo = (corporateMemberId) => {
    if (corporateMemberId > 0 && corporateMemberId != null) {
      this.membershipCorporateServices.getCorporateFinancialInfo(corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          if (response.responseItem.responseContent.length > 0) {
            this.setState({ corporateFinanceInfo: response.responseItem.responseContent });
          }
          else {
            this.swalServices.Alert('No records found.');
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    }
  }


  /* ---------- Add Edit All Section Handle Method Start ----------- */

  // Add Edit Coporate Account Inforamtion
  addEditSIISCorporateAccountInformation = () => {
    let request = this.state.accountBasicInformation;
    request.corporateMemberId = this.state.corporateMemberId;
    request.accountStatus = request.accountStatus === 0 ? 7 : request.accountStatus;
    request.createdBy = request.createdBy === 0 ? 1 : request.createdBy;
    request.createdAppId = 115;
    //if (this.isValidSiisAccountInfo()) {
    this.setState({ isLoading: true });
    this.membershipCorporateServices.addEditSIISCorporateAccountInformation(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent.errorMessage === '') {
          this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Membership ID Information"));
          this.setState({ corporateMemberId: response.responseItem.responseContent.keyId },
            () => this.getCorporateAccountInformationById(response.responseItem.responseContent.keyId));
        }
        else {
          this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Membership ID Information"));
        }
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
    // }
  }

  // Add Edit Represantative Information
  addEditRepresantativeInformationById = () => {
    let request = this.state.corporateOrganisationDetails;
    request.corporateMemberId = this.state.corporateMemberId;
    request.salutationId = this.state.corporateOrganisationDetails.mrMrsId
    request.firstName = this.state.corporateOrganisationDetails.firstName;
    request.lastName = this.state.corporateOrganisationDetails.lastName;
    request.designation = this.state.corporateOrganisationDetails.designation;
    request.jobPositionId = this.state.corporateOrganisationDetails.jobPositionId;
    request.businessEmail = this.state.corporateOrganisationDetails.businessEmail;
    request.businessContactNo = this.state.corporateOrganisationDetails.businessContactNo;
    this.setState({ isLiading: true });
    this.membershipCorporateServices.addEditRepresantativeInformationById(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices.Success("Represantative Inserted Successfully");
        this.getCorporateAccountInformationById(this.state.corporateMemberId);
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })

  }
  // Add Edit Engagement Tracking and Notes
  addEditEngagementTracking = () => {
    let request = this.state.engagementTrackingDetails;
    request.corporateMemberId = this.state.corporateMemberId;
    request.createdBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
    if (this.state.corporateMemberId) {
      if (this.isValidateAllFields('Engagement_Tracking')) {
        this.setState({ isLoading: true });
        this.membershipCorporateServices.addEditEngagementTracking(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem !== null) {
            this.getEngagementTrackingListById(this.state.corporateMemberId);
            let details = this.state.engagementTrackingDetails;

            details.corporateEngagementTrackingId = 0;
            details.corporateEngagementTypeId = 0;
            details.corporateEngagementDate = "";
            details.corporateEngagementDescription = "";
            details.corporateEngagementRecordedById = 0;
            details.corporateEngagementFollowUpById = 0;
            details.corporateMemberId = 0;
            details.updatedBy = this.state.adminRoleId ? this.state.adminRoleId : 0;

            this.swalServices.Success("Engagement Inserted Successfully");
          }
          else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        })
      }
    } else {
      this.swalServices.Warning("Please enter Membership ID corporate account information.");
    }
  };
  // Add Edit Corporate Membership Subscription
  addEditCorporateMembershipSubscription = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      var request = this.state.membershipSubscription;
      request.corporateMembershipSubscriptionId = this.state.membershipSubscription.corporateMembershipSubscriptionId;
      request.membershipTypeId = this.state.membershipSubscription.membershipTypeId;
      request.billingCategoryId = this.state.membershipSubscription.billingCategoryId;
      request.subscriptionStartDate = this.state.membershipSubscription.subscriptionStartDate;
      request.subcriptionEndDate = this.state.membershipSubscription.subscriptionEndDate;
      request.corporateMemberId = this.state.corporateMemberId;
      request.createdBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
      request.createdAppId = 115;
      //if (this.isValidMemberSub()) {
      this.setState({ isLoading: true });
      this.membershipCorporateServices.addEditCorporateMembershipSubscription(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorMessage === '') {
            var res = this.state.membershipSubscription;
            res.updatedAt = new Date();
            res.updatedBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
            res.corporateMembershipSubscriptionId = response.responseItem.responseContent.keyId;
            this.setState({ membershipSubscription: res },
              () => {
                this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Corporate membership subscription"));
              });
          } else {
            this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Corpoorate membership subscription"));
          }
        } else {
          this.swalServices.Error(ErrorMessage.DefaultMessage);
        }
        this.setState({ isLoading: false });
      })
      //}
    } else {
      this.swalServices.Warning("Please select Membership ID corporate account information");
    }
  }
  // Add Edit Company Profile
  addEditCorporateCompanyProfile = () => {
    if (this.state.corporateMemberId) {
      if (this.isValidateAllFields("Company_Profile")) {


        // let request = { companyProfile: this.state.corporateMemberDetail.companyProfile };
        let request = { ...this.state.corporateMemberDetail };
        //request.companyProfileId = this.state.companyProfile.companyProfileId;
        request.companyProfile.corporateMemberId = this.state.corporateMemberId;
        request.companyName = this.state.corporateMemberDetail.companyProfile.companyName;
        request.companyTypeId = this.state.corporateMemberDetail.companyProfile.companyTypeId;

        if (request.companyProfile.businessTypeIds && request.companyProfile.businessTypeIds.length > 0) {
          let arr1 = request.companyProfile.businessTypeIds.map(function (items) {
            return items.value;
          });

          request.selectedBusinessIds = arr1.join([","]);
        } else {
          request.selectedBusinessIds = "";
        }

        if (request.companyProfile.interestIds && request.companyProfile.interestIds.length > 0) {
          let arr2 = request.companyProfile.interestIds.map(function (items) {
            return items.value;
          });
          request.selectedInterestedIds = arr2.join([',']);
        } else {
          request.selectedInterestedIds = "";
        }
        request.companyUEN = this.state.corporateMemberDetail.companyProfile.companyUEN;
        request.brandName = this.state.corporateMemberDetail.companyProfile.brandName;
        // request.companyDescription = this.state.corporateMemberDetail.companyProfile.description;
        request.companyProfile.filePath = this.state.corporateMemberDetail.companyProfile.aCRAFilePath;
        request.incorporationDate = this.state.corporateMemberDetail.companyProfile.incorporationDate;
        request.countryOfIncorporationId = this.state.corporateMemberDetail.companyProfile.countryOfIncorporationId;
        request.companyAddress1 = this.state.corporateMemberDetail.companyProfile.companyAddress1;
        request.companyAddress2 = this.state.corporateMemberDetail.companyProfile.companyAddress2;
        request.postalCode = this.state.corporateMemberDetail.companyProfile.postalCode;
        request.countryId = this.state.corporateMemberDetail.companyProfile.countryId;
        request.companyProfile.companyWebsite = this.state.corporateMemberDetail.companyProfile.website;
        request.createdBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
        request.companyProfile.companyAnnualId = this.state.corporateMemberDetail.companyProfile.annualSalesTurnoverId;
        request.companyProfile.employementSizeId = this.state.corporateMemberDetail.companyProfile.employmentSizeId;
        //request.typeOfBusinessId = this.state.companyProfile.businessTypeId;
        // request.companyInterestedId = this.state.companyProfile.interestId;
        request.companyProfile.typeOfBusinessIds = request.selectedBusinessIds;
        request.companyProfile.companyInterestedIds = request.selectedInterestedIds;
        request.companyProfile.aboutUsId = this.state.corporateMemberDetail.companyProfile.knowAboutUsId;
        if (this.state.selectKnowAboutUsOthers) {
          request.companyProfile.aboutUsOtherName = this.state.corporateMemberDetail.companyProfile.knowAboutUsOthers;
        }
        else {
          request.companyProfile.aboutUsOtherName = "";
        }

        //request.companyProfile.aboutUsName = this.state.getToKnowUs.filter(s => s.id == this.state.corporateMemberDetail.companyProfile.knowAboutUsId)[0].id;
        request.companyProfile.companyDescription = this.state.corporateMemberDetail.companyProfile.description
        request.companyProfile.businessRegistrationNo = this.state.corporateMemberDetail.companyProfile.businessRegistrationNo;
        request.companyProfile.interestIds = request.selectedInterestedIds;
        request.companyProfile.businessTypeIds = request.selectedBusinessIds;
        request.createdAppId = 115;


        //pointOfContactInformation

        request.updatedBy = this.state.adminRoleId ? this.state.adminRoleId : 0;
        // if (this.isValidateAllFields("Company_Profile")
        //   //  && this.isValidateAllFields("Point_Of_Contact_Information")
        //   //  && this.isValidateAllFields("Finance_Department_Contact")
        // ) {
        this.setState({ isLoading: true });

        this.membershipCorporateServices.addEditCorporateCompanyProfile(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.errorMessage === '') {
              var resp = this.state.corporateMemberDetail;
              var res = resp.companyProfile;
              res.companyProfileId = response.responseItem.responseContent.keyId;
              // res.updatedAt = new Date();
              // res.updatedBy = 1;

              this.setState({ corporateMemberDetail: resp });
              this.setState({ companyProfile: res }, () => {
                this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Company Profile"));
                this.getOrganisationProfileById(this.state.corporateMemberId, true);
              });
            } else {
              this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Company Profile"));
            }
          } else {
            this.swalServices.Error(ErrorMessage.DefaultMessage);
          }
          this.setState({ isLoading: false });
        })
      }
      else {
        this.swalServices.Warning("Please fill all mandatory fields")
      }
    }
    else {
      this.swalServices.Warning("Please enter Membership ID corporate account information.")
    }
  }
  // Add Edit Business Presence / Market Interest
  addEditBusinessPresenceAndMarketInterest = () => {
    if (this.state.corporateMemberId) {
      let request = this.state.businessPresenceDetails;
      request.corporateMemberId = this.state.corporateMemberId;
      if (request.selectedCompanyPresence && request.selectedCompanyPresence.length > 0) {
        let arr1 = request.selectedCompanyPresence.map(function (items) {
          return items.value;
        });
        request.CompanyPresenceId = arr1.join([',']);
      } else {
        request.CompanyPresenceId = "";
      }

      if (request.selectedMarketInterest && request.selectedMarketInterest.length > 0) {
        let arr2 = request.selectedMarketInterest.map(function (items) {
          return items.value;
        });
        request.MarketInterestId = arr2.join([',']);
      } else {
        request.MarketInterestId = "";
      }
      // if (this.isValidateAllFields("Company_Profile")) {
      this.setState({ isLoading: true });
      this.membershipCorporateServices.addEditBusinessPresenceAndMarketInterest(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorMessage === '') {
            var res = this.state.corporateMemberDetail.companyProfile;
            res.companyProfileId = response.responseItem.responseContent.keyId;
            this.setState({ companyProfile: res }, () => {
              this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Business presence/market interest"));
            });
          } else {
            this.swalServices.Error("details already exist");
          }
        } else {
          this.swalServices.Error(ErrorMessage.DefaultMessage);
        }
        this.setState({ isLoading: false });
      })
      // }
      // else {
      //   this.swalServices.Warning("Please fill all mandatory fields")
      // }
    }
    else {
      this.swalServices.Warning("Please enter Membership ID corporate account information.")
    }
  }

  // Add Edit Organasation Key Role
  addEditOraganisation = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      if (this.isValidateAllFields("Organisation_Key_Role")) {
        let request = this.state.organisationKeyRoleDetail;
        request.emailString = request.email;
        request.corporateMemberId = this.state.corporateMemberId;
        request.designation = "";
        if (request.selectedOrganisationRole && request.selectedOrganisationRole.length > 0) {
          let array = request.selectedOrganisationRole.map(function (item) {
            delete item.bad;
            return item.value;
          });
          request.organisationRole = array.join([',']);
        } else {
          request.organisationRole = "";
        }
        request.organisationRoleList = request.selectedOrganisationRole;
        this.setState({ isAddEditAOrganisation: true });
        this.membershipCorporateServices.addEditOrganisation(request).then((response) => {
          if (
            response.statusCode === 200 &&
            response.responseItem != null &&
            response.responseItem.responseContent.errorType === "EXISTS"
          ) {
            this.setState({ setOrganisationKeyRoleModal: false });
            this.swalServices.Error("Member already exists with same email address.");

          } else if (
            response.statusCode === 200 &&
            response.responseItem != null
          ) {
            this.swalServices.Success(
              "Employee information inserted successfully."
            );
            this.setState({ setOrganisationKeyRoleModal: false });
            this.getOrganisationKeyRolesListById(this.state.corporateMemberId);
          } else {
            this.swalServices.Error("Error");
          }
        });
      }
    } else {
      this.swalServices.Warning("Please enter Membership ID corporate account information.");
    }
  };
  // Edit Organasation Employee
  ediCorporateEmployeeDetails = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      if (this.isValidateAllFields('Organisation_Employee')) {
        let request = this.state.employeeDetails;
        request.corporateMemberId = this.state.corporateMemberId;
        request.designation = this.state.employeeDetails.title;
        request.mrMrs = this.state.employeeDetails.mrMrsId;
        let splitEmail = request.email.split("@");
        request.emailDomain = splitEmail[1];

        // if (request.selectedDesignation && request.selectedDesignation?.length > 0) {
        //   let arr1 = request.selectedDesignation.map(function (item) {
        //     delete item.bad;
        //     return item.label;
        //   });
        //   request.designation = arr1.join([',']);
        // } 
        // if (request.selectedDesignation) {
        //   request.designation = request.selectedDesignation.value?.toString();
        // }
        // else {
        //   request.designation = "";
        // }
        // request.designation = "";
        // if (request.selectedOrganisationRole && request.selectedOrganisationRole.length > 0) {
        //   let array = request.selectedOrganisationRole.map(function (item) {
        //     delete item.bad;
        //     return item.value;
        //   });
        //   request.organisationRole = array.join([',']);
        // } else {
        //   request.organisationRole = "";
        // }
        // request.organisationRoleList = request.selectedOrganisationRole;
        // if (request.selectedDesignation) {
        //   request.jobPositionId = request.selectedDesignation.value;
        // } else {
        //   request.jobPositionId = null
        // }
        request.updatedBy = 139;
        this.setState({ isAddEditAOrganisation: true });
        this.membershipCorporateServices.ediCorporateEmployeeDetails(request).then((response) => {
          if (
            response.statusCode === 200 &&
            response.responseItem != null &&
            response.responseItem.responseContent.errorType === "EXISTS"
          ) {
            this.setState({ setOrganisationKeyRoleModal: false });
            this.swalServices.Error("Member already exists with same email address.");

          } else if (
            response.statusCode === 200 &&
            response.responseItem != null
          ) {
            this.swalServices.Success(
              "Employee information inserted successfully."
            );
            this.setState({ setOrganisationEmployeeModal: false });
            this.getEmployeeList(this.state.corporateMemberId, "");
          } else {
            this.swalServices.Error("Error");
          }
        });
      }
    } else {
      this.swalServices.Warning("Please enter Membership ID corporate account information.");
    }
  };
  // Reset Employee Information Method
  resetEmployeeInfo = (email = '') => {
    let reset = this.state.employeeDetails;
    // var domainId = this.state.emailDomain.find(
    //   (domain) => domain.id === this.state.corporateMemberId
    // ).id;
    reset.mrMrsId = 0;
    reset.individualMemberId = 0;
    reset.lastName = "";
    reset.firstName = "";
    reset.email = email;
    reset.title = "";
    reset.designation = "";
    reset.contactNo = "";
    // reset.emailDomainId = domainId;
    reset.selectedDesignation = [];
    reset.searchByEmail = "";
    reset.jobPositionId = 0;

    let res = this.state.validStateEmp;
    res.isValid = true;
    res.error = {};

    this.setState({ employeeDetails: reset, organisationKeyRoleDetail: reset, validStateEmp: res });
  }

  // Delete Employee
  deleteEmployee = (id) => {
    // this.setLoader(true);
    this.membershipCorporateServices.markIndivisualMemberAsNonMember(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        //this.setState({ employeeList: response.responseItem.responseContent })
        this.swalServices.Success("Delete employee information successfully");
        this.empModalOnOff(false);
        this.getEmployeeList(this.state.corporateMemberId, "");
        // this.setLoader(false);

      } else {
        this.swalServices.Error(response.message);
        // this.setLoader(false);
      }
    });
  }

  //Delete Role
  deleteOrganisationRole = (id) => {
    if (id > 0 && id != null) {
      let request = this.state.organisationKeyRoleDetail;
      request.individualMemberId = id;
      this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Organisation Role"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Organisation Role"),
        CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
          if (response) {
            this.membershipCorporateServices.deleteCorporateOrganisationRole(request).then((response) => {
              if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.hasError !== true) {
                this.swalServices.Success(
                  "Organisation role deleted successfully."
                );
                let reset = this.state.organisationKeyRoleDetail;
                reset.organisationRoleId = 0;
                reset.mrMrs = "";
                reset.firstName = "";
                reset.lastName = "";
                reset.email = "";
                reset.contactNo = "";
                reset.designation = "";
                reset.organisationRole = "";
                reset.emailString = "";
                reset.individualMemberId = 0;
                reset.selectedDesignation = [];
                reset.selectedOrganisationRole = [];
                this.setState({ organisationKeyRoleDetail: reset });
                this.getOrganisationKeyRolesListById(this.state.corporateMemberId);
                this.onModalOnOff(false);
              }
              else if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "OrganisationRoleId") {
                if (response.responseItem.responseContent.errorMessage === "2") {
                  this.swalServices.Error('Last official representative cannot be deleted.');
                }
                else if (response.responseItem.responseContent.errorMessage === "3") {
                  this.swalServices.Error('Last finance/billing rep cannot be deleted.');
                }
              }
              else {
                this.swalServices.Error(response.message);
              }
            });
          }
        });
    }
  };




  /* ---------- Add Edit All Section Handle Method End ----------- */

  getCorporateFinanceReceiptInvoice = (receiptInvoiceId, isReceipt, invoiceReceiptNo) => {
    if (receiptInvoiceId > 0 && receiptInvoiceId != null) {
      let request = [
        this.state.corporateMemberId,
        receiptInvoiceId,
        isReceipt
      ]
      if (isReceipt === null) {
        let request2 = [
          //this.state.corporateMemberId,
          receiptInvoiceId,
          isReceipt]
        this.membershipCorporateServices.GetCorporateFinanceForCreditNote(request2).then((response) => {
          if (response.data !== null && response.status === 200) {
            // var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")

            var filename = "";
            if (isReceipt === true) {
              filename = ` ${invoiceReceiptNo}`;
            } else if (isReceipt === false) {
              filename = `${invoiceReceiptNo}`;
            } else {
              filename = invoiceReceiptNo;
            }
            var file = new Blob([response.data], { type: "application/pdf" });

            URL.createObjectURL(file);
            window.open(URL.createObjectURL(file), "_blank");

            saveAs(file, filename + ".pdf");
          } else {
            this.swalServices.Error(response.message);
          }
        });
      } else {
        this.membershipCorporateServices.getCorporateFinanceReceiptInvoice(request).then((response) => {
          if (response.data !== null && response.status === 200) {
            var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")

            var filename = "";
            if (isReceipt === true) {
              filename = `${invoiceReceiptNo}`
            } else if (isReceipt === false) {
              filename = `${invoiceReceiptNo}`;
            } else {
              filename = invoiceReceiptNo;
            }
            var file = new Blob([response.data], { type: "application/pdf" });

            URL.createObjectURL(file);
            window.open(URL.createObjectURL(file), "_blank");

            saveAs(file, filename + ".pdf");
          } else {
            this.swalServices.Error(response.message);
          }
        });
      }

    } else {
      this.swalServices.Warning("Please enter Membership ID corporate account information.");
    }
  }

  // Internal Handling Method Section Start
  saveMainImage = (file, keyId, imageIdentity) => {
    this.setState({ isLoading: true });
    file.storagePath = "CorporateMembers";
    this.commonServices.storeBase64Image(file).then((response) => {
      if (response !== null) {
        this.swalServices.Success("File Uploaded");
        if (file.storagePath === "CorporateMembers") {
          let details = this.state.corporateMemberDetail.companyProfile;
          details["aCRAFilePath"] = response.fileName;
          this.setState({ companyDetails: { ...details } }, () => this.setState({ isLoading: false }));
        }
      }
      else {
        this.swalServices.Error(response.message);
      }
    })
  }
  // Handle Upload File Change method
  onFileChange = (event, fileType) => {
    const file = event.target.files[0];
    if (file.size < 5242880) {
      // if (fileType === "Upload_WP_Record") {
      if (
        (file && file.type === "application/pdf") ||
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/msword" ||
        file.type === "application/msexcel"
      ) {
        let uploadFile = { ...this.state.resFile };
        uploadFile.filename = file.name;
        uploadFile["file"] = file;
        uploadFile.uploadId = `UplaodFile`;
        uploadFile.storagePath = "ACRA_FILE";
        let detail = { ...this.state.corporateMemberDetail };
        let data = (detail.companyProfile.aCRAFilePath = file.name);
        // detail.aCRAFilePath = file.name;
        this.setState({ corporateMemberDetails: data }, () =>
          this.isValidateAllFields("aCRAFilePath", "Corporate_Company_Profile")
        );

        this.setState({ isLiading: true });
        this.commonServices.uploadPDF(uploadFile).then((response) => {
          if (response != null) {
            let detail = { ...this.state.corporateMemberDetail };
            detail.companyProfile.aCRAFilePath = response.fileName;
            this.setState({ corporateMemberDetail: detail });
            this.swalServices.Success("File uploaded sucessfully");
            this.validateField("aCRAFilePath", "Corporate_Company_Profile");
          }
          else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLiading: false });
        })
      } else {
        this.swalServices.Error(
          "Please upload only PDF, DOC, DOCX, XLS, XLSX files & Max of 5MB !"
        );
      }
      // }
    } else {
      this.swalServices.Error("File size must be less than 5 MB!");
    }
  };
  // Handle All Input Fields Method
  handleChange(event, identity) {
    const value = event.target.value;
    const name = event.target.name;
    if (identity === "SIISInformation") {
      let detail = this.state.accountBasicInformation;
      detail[name] = value;
      this.setState({ accountBasicInformation: { ...detail } });
    }
    else if (identity === "Organisation_Employee") {
      let detail = this.state.employeeDetails;
      detail[name] = value;
      if (name === "email") {
        //detail["emailString"] = value + "@abc.com";
        detail["emailString"] = value;
        this.validateEmployeeInfo("emailString");
      }
      if (name === "searchByEmail") {
        detail.searchByEmail = value;
        this.setState({ employeeDetails: { ...detail } }, () => {
          this.validateSearchByEmailFieldForEmployee("searchByEmail");
        });
      }
      detail[name] = value;
      this.setState({ employeeDetails: { ...detail } });
    }

    else if (identity === "Organisation_Key_Role") {
      let detail = this.state.organisationKeyRoleDetail;
      detail[name] = value;
      this.setState({ organisationKeyRoleDetail: { ...detail } });
    }
    else if (identity === "Finance_Department_CheckBoxRadio") {
      let details = this.state.corporateMemberDetail;
      details.financeDepartment[name] = !details.financeDepartment[name];
      this.setState({ corporateMemberDetail: details });
      if (details.financeDepartment.isFinanceDepartmentContact === true) {
        details.financeDepartment.salutationId = details.pointOfContactInformation.salutationId;
        details.financeDepartment.firstName = details.pointOfContactInformation.firstName;
        details.financeDepartment.lastName = details.pointOfContactInformation.lastName;
        details.financeDepartment.designation = details.pointOfContactInformation.designation;
        details.financeDepartment.jobPositionId = details.pointOfContactInformation.jobPositionId;
        details.financeDepartment.businessEmail = details.pointOfContactInformation.businessEmail;
        details.financeDepartment.businessContactNumber = details.pointOfContactInformation.businessContactNumber;
        this.setState({ corporateMemberDetail: { ...details } });
      }
      else {
        details.financeDepartment.salutationId = 0;
        details.financeDepartment.firstName = "";
        details.financeDepartment.lastName = "";
        details.financeDepartment.designation = "";
        details.financeDepartment.jobPositionId = 0;
        details.financeDepartment.businessEmail = "";
        details.financeDepartment.businessContactNumber = "";
        this.setState({ corporateMemberDetail: { ...details } });
      }
    }

    else if (identity === "Company_Profile") {
      let detail = this.state.corporateMemberDetail;
      let companyProf = detail.companyProfile;
      companyProf[name] = value;
      detail.companyProfile = companyProf;
      this.setState({ corporateMemberDetail: { ...detail } });
    }
    else if (identity === "pointOfContactInformation") {
      let detail = this.state.corporateMemberDetail;
      let companyProf = detail.pointOfContactInformation;
      companyProf[name] = value;
      detail.pointOfContactInformation = companyProf;
      this.setState({ corporateMemberDetail: { ...detail } });
    }
    else if (identity === "financeDepartment") {
      let detail = this.state.corporateMemberDetail;
      let companyProf = detail.financeDepartment;
      companyProf[name] = value;
      detail.financeDepartment = companyProf;
      this.setState({ corporateMemberDetail: { ...detail } });
    }
    else if (identity === "BasicInformation") {
      let detail = this.state.organizationBasicInformation;
      detail[name] = value;
      if (name === "email") {
        let fields = detail.email.split("@");
        detail["companyDomain"] = fields[1];
      }
      this.setState({ organizationBasicInformation: { ...detail } });
    }

    else if (identity === "OrganizationProfile") {
      let detail = this.state.organizationProfile;
      detail[name] = value;
      this.setState({ organizationProfile: { ...detail } }, () => {
        if (name === "companyDescription") {
          let str = this.state.organizationProfile.companyDescription;
          this.setState({ countCompanyDescriptionForSIIS: str.length });
        }

      });
    }
    else if (identity === "EngagementTracking") {
      let detail = this.state.engagementTrackingDetails;
      detail[name] = value;
      this.setState({ engagementTrackingDetails: { ...detail } });
    }
    else if (identity === "MemebershipSubscription") {
      let detail = this.state.membershipSubscription;
      detail[name] = value;
      this.setState({ membershipSubscription: { ...detail } });
    }
    else if (identity === "ProspectInformation") {
      let detail = this.state.prospectInformation;
      detail[name] = value;
      this.setState({ prospectInformation: { ...detail } });
    }
    else if (identity === "IndividualContribution") {
      let detail = this.state.contributionDetails;
      detail[name] = value;
      this.setState({ contributionDetails: { ...detail } });
    }
    else if (identity === "Employee") {
      let detail = this.state.employeeDetails;
      detail[name] = value;
      if (name === "emailText") {
        //detail["emailString"] = value + "@abc.com";
        detail["emailString"] = value;
        this.validateEmployeeInfo("emailString");
      }
      if (name === "searchByEmail") {
        detail.searchByEmail = value;
        this.setState({ employeeDetails: { ...detail } }, () => {
          this.validateSearchByEmailFieldForEmployee("searchByEmail");
        });
      }
      detail[name] = value;
      this.setState({ employeeDetails: { ...detail } });

    }
    else if (identity === "Organisation") {
      let detail = this.state.organisationKeyRoleDetail;
      detail[name] = value;
      if (name === "emailText") {
        // detail["emailString"] = value + "@abc.com";
        detail["emailString"] = value;
        this.validateEmployeeInfo("emailString");
      }
      if (name === 'searchByEmail') {
        detail["searchByEmail"] = value;
        this.setState({ organisationKeyRoleDetail: { ...detail } }
          // , () => {
          // this.validateSearchByEmailField("searchByEmail");
          // }
        );
      }
      detail[name] = value;
      this.setState({ organisationKeyRoleDetail: { ...detail } });
    }
    else if (identity === "CreditNote") {
      let detail = { ...this.state.addEditCreditNote };
      let val = value;
      let usedMonth = 0;
      let totalMonth = monthDifference(this.state.corporateCreditNotePaymentInfo.subcriptionEndDate, this.state.corporateCreditNotePaymentInfo.subscriptionStartDate, false);;
      //let data = { ...this.state.corporateCreditNotePaymentInfo };
      if (name === "creditNoteMonth") {
        if (detail.paymentStatus !== 'Pending') {

          detail[name] = val;
          usedMonth = monthDifference(detail.creditNoteMonth, this.state.corporateCreditNotePaymentInfo.subscriptionStartDate, false);
          let paidMonth = ((totalMonth - usedMonth) / totalMonth)

          detail.entranceRefund = this.state.corporateCreditNotePaymentInfo.entranceFee;
          detail.annualRefund = this.state.corporateCreditNotePaymentInfo.annualFee;
          detail.chapterRefund = this.state.corporateCreditNotePaymentInfo.addtionalChapterFee;

          detail.entranceRefund = parseFloat((paidMonth * detail.entranceRefund).toFixed(2));
          detail.annualRefund = parseFloat((paidMonth * detail.annualRefund).toFixed(2));
          detail.chapterRefund = parseFloat((paidMonth * detail.chapterRefund).toFixed(2));

          detail.subTotalRefund = parseFloat((parseFloat(detail.entranceRefund + parseFloat(detail.annualRefund) + parseFloat(detail.chapterRefund)).toFixed(2)));
          detail.gstRefund = parseFloat((parseFloat(detail.subTotalRefund - (detail.discountRefund > 0 ? detail.discountRefund : 0)) * (this.state.gstRate / 100)).toFixed(2));
          detail.totalRefund = parseFloat((parseFloat(detail.subTotalRefund) + parseFloat(detail.gstRefund) - parseFloat(detail.discountRefund)).toFixed(2));

        }
      }
      else if ((val = convertToDecimal(value)) || val === 0) {
        // if(data.entranceFee < val ){
        detail[name] = val;
        this.validFinanceCreditNoteForOtherReason(name, detail);
        // }
        //detail[name] = val;
        detail.subTotalRefund = parseFloat((parseFloat(detail.entranceRefund) + parseFloat(detail.annualRefund) + parseFloat(detail.chapterRefund)).toFixed(2));
        detail.gstRefund = parseFloat((parseFloat(detail.subTotalRefund) * (this.state.gstRate / 100)).toFixed(2));
        detail.totalRefund = parseFloat((parseFloat(detail.subTotalRefund) + parseFloat(detail.gstRefund) - parseFloat(detail.discountRefund)).toFixed(2));
        detail.cancellationReason = value;
      }
      this.setState({ addEditCreditNote: { ...detail } });
    }
    else if (identity === "Organisation_Profile") {
      let detail = this.state.corporateOrganisationDetails;
      detail[name] = value;
      this.setState({ corporateOrganisationDetails: { ...detail } });
    }
  }
  // Handle Change In Multiselect Dropdown
  handleChangeMultiDropdown = (selected, identity) => {
    if (identity === "RecordedBy_Engagement") {
      let engagement = this.state.engagementTrackingDetails;
      engagement.corporateEngagementRecordedById = selected && selected.value && selected.value > 0 ? selected.value : 0;
      this.setState({ engagementTrackingDetails: engagement });
    }
    // else if (identity === "knowAboutUsId") {
    //   let engagement = this.state.corporateMemberDetail.companyProfile;
    //   engagement.knowAboutUsId = selected && selected.value && selected.value > 0 ? selected.value : 0;
    //   this.setState({ engagement: engagement });
    //   if( engagement.knowAboutUsId  === 6){
    //     this.state.corporateMemberDetail.companyProfile.isKnowAboutUsOther = true;
    //   }
    //   else{
    //     this.state.corporateMemberDetail.companyProfile.isKnowAboutUsOther = false;
    //   }
    //   this.setState({ bookingDetails: this.state.corporateMemberDetail.companyProfile.isKnowAboutUsOther})

    // }
    else if (identity === "knowAboutUsId") {
      let engagement = this.state.corporateMemberDetail.companyProfile;
      engagement.knowAboutUsId = selected && selected.value && selected.value > 0 ? selected.value : 0;
      // let checkIsKnowAboutUsTrue = selected.label === "Other channel, please specify"
      if (selected.label === "Other channel, please specify") {
        this.setState({ selectKnowAboutUsOthers: true });
      }
      else {
        this.setState({ selectKnowAboutUsOthers: false });
      }
      this.setState({ engagement: engagement });
    }


    else if (identity === "Employee_OrganisationRoles") {
      let employee = this.state.employeeDetails;
      employee.selectedOrganisationRole = selected;
      this.setState({ employeeDetails: employee });
    }
    // else if (identity === "interestId") {
    //   let profile = this.state.corporateMemberDetail;
    //   profile.companyProfile.interestIds = selected;
    //   this.setState({ corporateMemberDetail: profile });
    //   profile.companyProfile.interestIds.map((x) => {
    //     if (x.label === 'Others,please specify') {
    //       return profile.companyProfile.isOtherCompanyInterested = true;
    //     }
    //     else {
    //       return profile.companyProfile.isOtherCompanyInterested = false;
    //     }
    //   })
    // }
    // else if (identity === "businessTypeId") {
    //   let profile = this.state.corporateMemberDetail;
    //   profile.companyProfile.businessTypeIds = selected;

    //   profile.companyProfile.businessTypeIds.map((x) => {
    //     if (x.label === 'Others (please specify)') {
    //       return profile.companyProfile.isOtherBusinessType = true;
    //     }
    //     else {
    //       return profile.companyProfile.isOtherBusinessType = false;
    //     }
    //   })

    //   this.setState({ corporateMemberDetail: profile });
    // }
    else if (identity === "interestId") {
      let profile = this.state.corporateMemberDetail;
      profile.companyProfile.interestIds = selected;

      let isOtherCompanyInterested = false; // Assume initially it's not 'Others,please specify'

      profile.companyProfile.interestIds.forEach((x) => {
        if (x.label === 'Others,please specify') {
          isOtherCompanyInterested = true; // Set to true if 'Others,please specify' is selected
        }
      });

      profile.companyProfile.isOtherCompanyInterested = isOtherCompanyInterested; // Set the isOtherCompanyInterested flag

      // Set the state of bookingDetails after the iteration has completed
      this.setState({ bookingDetails: isOtherCompanyInterested });
      this.setState({ corporateMemberDetail: profile });
    }
    else if (identity === "businessTypeId") {
      let profile = this.state.corporateMemberDetail;
      profile.companyProfile.businessTypeIds = selected;

      let isOtherBusinessType = false; // Assume initially it's not 'Others (please specify)'

      profile.companyProfile.businessTypeIds.forEach((x) => {
        if (x.label === 'Others (please specify)') {
          isOtherBusinessType = true; // Set to true if 'Others (please specify)' is selected
        }
      });

      profile.companyProfile.isOtherBusinessType = isOtherBusinessType; // Set the isOtherBusinessType flag

      // Set the state of bookingDetails after the iteration has completed
      this.setState({ bookingDetails: isOtherBusinessType });
      this.setState({ corporateMemberDetail: profile });
    }
    else if (identity === "Designation_KeyRoles") {
      let profile = this.state.organisationKeyRoleDetail;
      profile.selectedDesignation = selected;
      this.setState({ organisationKeyRoleDetail: profile });
    }
    // else if (identity === "Designation_KeyRoles") {
    //   let profile = this.state.organisationKeyRoleDetail;
    //   profile.selectedDesignation = selected;
    //   this.setState({ organisationKeyRoleDetail: profile });
    // }
    else if (identity === "Employee_Job_Function") {
      let profile = this.state.employeeDetails;
      profile.selectedDesignation = selected;
      this.setState({ employeeDetails: profile });
    }
    else if (identity === "Org_OrganisationRoles") {
      let profile = this.state.organisationKeyRoleDetail;
      profile.selectedOrganisationRole = selected;
      this.setState({ organisationKeyRoleDetail: profile });
    }
    // Business Presence / Market Interest
    else if (identity === "Company_presence") {
      let profile = this.state.businessPresenceDetails;
      profile.selectedCompanyPresence = selected;
      this.setState({ businessPresenceDetails: profile });
    }
    else if (identity === "Market_interest") {
      let profile = this.state.businessPresenceDetails;
      profile.selectedMarketInterest = selected;
      this.setState({ businessPresenceDetails: profile });
    }

  }

  // Handle Single Select Dropdown Method
  setFilterParameters = (id, drpIdentity) => {
    let engagement = this.state.engagementTrackingDetails;
    let basicInfo = this.state.organizationBasicInformation;
    let siisInfo = this.state.accountBasicInformation;
    let orgProfile = this.state.organizationProfile;
    let bizDetails = this.state.organizationBusinessDetails;
    let subscription = this.state.membershipSubscription;
    let prospect = this.state.prospectInformation;
    let org = this.state.organisationDetails;
    let cancel_reason = this.state.cancellationReason;
    let corpMemberDetail = this.state.corporateMemberDetail;
    let orgKeyRolDetail = this.state.organisationKeyRoleDetail;
    let empDetails = this.state.employeeDetails;
    if (drpIdentity === "updatedById") {
      siisInfo["createdBy"] = id
    }
    else if (drpIdentity === "Employee_Salutation") {
      empDetails["mrMrsId"] = id;
      this.validateField("mrMrsId", "Organisation_Employee")
    }
    else if (drpIdentity === "Employee_KeyRoles") {
      empDetails["jobPositionId"] = id;
      this.validateField("jobPositionId", "Organisation_Employee")
    }
    else if (drpIdentity === "finance__jobpositionId") {
      corpMemberDetail.financeDepartment["jobPositionId"] = id;
    }
    else if (drpIdentity === "finance_salutationId") {
      corpMemberDetail.financeDepartment["salutationId"] = id;
    }
    else if (drpIdentity === "contactinfo_salutationId") {
      corpMemberDetail.pointOfContactInformation["salutationId"] = id;
    }
    else if (drpIdentity === "contactinfo_jobpositionId") {
      corpMemberDetail.pointOfContactInformation["jobPositionId"] = id;
    }
    else if (drpIdentity === "KeyRole_Salutation") {
      orgKeyRolDetail["mrMrs"] = id;
    }
    else if (drpIdentity === "Designation_KeyRoles") {
      orgKeyRolDetail["jobPositionId"] = id;
    }
    else if (drpIdentity === "Company_Type") {
      corpMemberDetail.companyProfile["companyTypeId"] = id;
    }
    else if (drpIdentity === "Select_Country") {
      corpMemberDetail.companyProfile["countryId"] = id;
    }
    else if (drpIdentity === "Company_Annual_Turnover") {
      corpMemberDetail.companyProfile["annualSalesTurnoverId"] = id;
    }
    else if (drpIdentity === "Employment_Size") {
      corpMemberDetail.companyProfile["employmentSizeId"] = id;
    }
    else if (drpIdentity === "corporateReasonLeaving") {
      siisInfo["corporateReasonLeavingId"] = id;
    }
    else if (drpIdentity === "corporateInternalFlags") {
      siisInfo["internalFlagId"] = id;
    }
    else if (drpIdentity === "grossTurnOver") {
      orgProfile["orgGrossTurnoverId"] = id;
      this.validateOrganisationProfile("orgGrossTurnoverId");
    }
    else if (drpIdentity === "orgCountry") {
      orgProfile["countryId"] = id;
      this.validateOrganisationProfile("countryId");
    }
    else if (drpIdentity === "AuthorizedCap") {
      bizDetails["authorizedCapId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "ShareHoldingLocal") {
      bizDetails["shareHoldingLocalId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "RevFromOutsideSG") {
      bizDetails["revFromOutsideSGId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "PaidUpCap") {
      bizDetails["paidUpCapId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "ShareHoldingForeign") {
      bizDetails["shareHoldingForeignId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "StaffStrength") {
      bizDetails["staffStrengthId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "Membership_Type") {
      subscription["membershipTypeId"] = id;
      // this.memberSubValidate("membershipTypeId");
    }
    else if (drpIdentity === "Billing_Category") {
      subscription["billingCategoryId"] = id;
      // this.memberSubValidate("membershipTypeId");
    }
    else if (drpIdentity === "TACFromTypeSubscription") {

      subscription["tacFromId"] = id;
      subscription["tacName"] = id > 1 && this.state.tacNames.find(x => x.id === id) ? this.state.tacNames?.find(x => x.id === id)?.name : '';

    }
    else if (drpIdentity === "ProspectSource1") {
      prospect["source1Id"] = id;
      this.prospectMemValidate("source1Id");
    }
    else if (drpIdentity === "ProspectSource2") {
      prospect["source2Id"] = id;
      this.prospectMemValidate("source2Id");
    }
    else if (drpIdentity === "ProspectPIC1") {
      prospect["pic1Id"] = id;
      this.prospectMemValidate("pic1Id");
    }
    else if (drpIdentity === "ProspectPIC2") {
      prospect["pic2Id"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "ProspectStatus") {
      prospect["prospectStatusId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "getToKnow") {
      prospect["getToKnow"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "RecordedBy_Engagement") {
      engagement["corporateEngagementRecordedById"] = id;
      this.validateField("corporateEngagementRecordedById", "Engagement_Tracking");
    }
    else if (drpIdentity === "EngagementType") {
      engagement["corporateEngagementTypeId"] = id;
      //this.engagementValidate("corporateEngagementTypeId");
    }
    else if (drpIdentity === "RecordedBy") {
      engagement["corporateEngagementRecordedById"] = id;
      //this.engagementValidate("corporateEngagementRecordedById");
    }
    else if (drpIdentity === "FollowUpBy") {
      engagement["corporateEngagementFollowUpById"] = id;
      // this.engagementValidate("corporateEngagementFollowUpById");
    }
    else if (drpIdentity === "Org_MrMrs") {
      org["mrMrs"] = id;
      // this.validateOrganisationRole("mrMrs");
      this.validateOrganisationKeyRoles("mrMrs");
    }
    else if (drpIdentity === "Org_EmailDomain") {
      org["emailDomainId"] = id;

      //this.validateField("membershipTypeId");
    }
    else if (drpIdentity === "Org_OrganisationRoles") {
      org["organisationRole"] = id;
      this.validateOrganisationKeyRoles("organisationRole");

      // this.validateOrganisationRole("organisationRole");
    }
    else if (drpIdentity === "NamePrefix") {
      basicInfo["mrMrsId"] = id;
      this.orgBasicInfoValidate("mrMrsId");
    }
    else if (drpIdentity === "cancel_reason") {
      //let othersArr = cancel_reason.filter((x) => x.id == id).find(({ name }) => name === "Others");
      //this.setState({ isOtherCancellationReason: othersArr !== null && othersArr !== undefined ? true : false });
      let data = { ...this.state.addEditCreditNote }
      let details = { ...this.state.corporateCreditNotePaymentInfo }
      let findData = cancel_reason.filter((x) => x.id === id);
      if (findData.length > 0) {
        details.cancellationReason = findData[0].id;
        if (findData[0].name === "Others") {
          data.cancellationReason = '';
          this.setState({ isOtherCancellationReason: true });
          this.validFinanceCreditNoteForOtherReason("cancellationReason", data);
        } else {
          data.cancellationReason = findData[0].name;
          this.setState({ isOtherCancellationReason: false });
        }
      } else {
        data.cancellationReason = '';
        details.cancellationReason = 0;
        this.setState({ isOtherCancellationReason: false, corporateCreditNotePaymentInfo: details });
      }
      this.validateFinanceCreditNote("cancellationReason", details);
      this.setState({ addEditCreditNote: data, corporateCreditNotePaymentInfo: details })
    }
    this.setState({
      accountBasicInformation: siisInfo, organizationProfile: orgProfile,
      membershipSubscription: subscription, prospectInformation: prospect, engagementTrackingDetails: engagement,
      organisationDetails: org,
      corporateMemberDetail: corpMemberDetail,
      organisationKeyRoleDetail: orgKeyRolDetail,
      employeeDetails: empDetails
    });
  };

  setFilterParametersEmployee = (id, drpIdentity) => {
    let request = this.state.employeeDetails;

    if (drpIdentity === "Employee_Salutation") {
      request["mrMrs"] = id;
    }
    this.setState({ employeeDetails: request });
  }

  setFilterParametersOrg = (id, drpIdentity) => {
    let orgKeyRolDetail = this.state.corporateOrganisationDetails;

    if (drpIdentity === "salutationId") {
      orgKeyRolDetail["mrMrsId"] = id;
      this.setState({ corporateOrganisationDetails: orgKeyRolDetail }, () => {
        this.validateField("salutationId", "Organisation_Profile")
      });
    }

    else if (drpIdentity === "jobPositionId") {
      orgKeyRolDetail["jobPositionId"] = id;
      this.setState({ corporateOrganisationDetails: orgKeyRolDetail }, () => {
        this.validateField("jobPositionId", "Organisation_Profile")
      });
    }
  }


  //Validation Method Declaration Section
  validateField = (key, section) => {
    if (section === "Company_Profile") {
      let validationRule = { ...CompanyProfileValidationRules };
      if (this.state.corporateMemberDetail.companyProfile.isOtherBusinessType) {
        validationRule.otherBusinessTypeIds = this.state.otherBusinessTypeValidationRule;
      }
      if (this.state.corporateMemberDetail.companyProfile.isOtherCompanyInterested) {
        validationRule.otherCompanyIntereststed = this.state.otherCompanyIntereststedValidationRule;
      }
      if (this.state.corporateMemberDetail.companyProfile.companyTypeId !== 1) {
        validationRule.countryOfIncorporationId = this.state.countryOfIncorporationIdValidationRule;
      }
      if (this.state.selectKnowAboutUsOthers) {
        validationRule.knowAboutUsOthers = this.state.knowAboutUsOthersValidationRule;
      }
      if (this.state.corporateMemberDetail.companyProfile.companyTypeId === 1) {
        validationRule.companyUEN = this.state.companyUENValidationRule;
        validationRule.aCRAFilePath = this.state.aCRAFilePathValidationRule;
      }
      else if (this.state.corporateMemberDetail.companyProfile.companyTypeId === 2) {
        validationRule.businessRegistrationNo = this.state.businessRegistrationNoValidationRule;
      }
      const returnValidState = validate(
        key,
        this.state.corporateMemberDetail.companyProfile,
        validationRule,
        this.state.validStateCompanyProfile);
      this.setState({ validStateCompanyProfile: returnValidState });
    }
    else if (section === "Point_Of_Contact_Information") {
      const newValidState = validate(
        key,
        this.state.corporateMemberDetail.pointOfContactInformation,
        PointOfContactInformationValidationRules,
        this.state.validStatePointofContactInformation
      );
      this.setState({ validStatePointofContactInformation: newValidState });
    }
    else if (section === "Finance_Department_Contact") {
      const newValidState = validate(
        key,
        this.state.corporateMemberDetail.financeDepartment,
        FinanceDepartmentValidationRules,
        this.state.validStateFinanceDepartmentContact
      );
      this.setState({ validStateFinanceDepartmentContact: newValidState });
    }
    else if (section === "Engagement_Tracking") {
      const returnValidState = validate(
        key,
        this.state.engagementTrackingDetails,
        EngagementTrackingValidationRules,
        this.state.validStateEngagementTracking);
      this.setState({ validStateEngagementTracking: returnValidState });
    }
    else if (section === "Organisation_Key_Role") {
      const returnValidState = validate(
        key,
        this.state.organisationKeyRoleDetail,
        OrganisationKeyRoleValidationRules,
        this.state.validStateOrganisationKeyRole);
      this.setState({ validStateOrganisationKeyRole: returnValidState });
    }
    else if (section === "Organisation_Employee") {
      const returnValidState = validate(
        key,
        this.state.employeeDetails,
        OrganisationEmployeeValidationRules,
        this.state.validStateOrgEmployee);
      this.setState({ validStateOrgEmployee: returnValidState });
    }
    else if (section === "Organisation_Profile") {
      const newValidState = validate(
        key,
        this.state.corporateOrganisationDetails,
        RepresantativeInformationValidationRules,
        this.state.validStateRepresantativeInformation
      );
      this.setState({ validStateRepresantativeInformation: newValidState });
    }
  }

  isValidateAllFields = (section) => {
    if (section === "Company_Profile") {
      let validationRule = { ...CompanyProfileValidationRules };
      if (this.state.corporateMemberDetail.companyProfile.isOtherBusinessType) {
        validationRule.otherBusinessTypeIds = this.state.otherBusinessTypeValidationRule;
      }
      if (this.state.corporateMemberDetail.companyProfile.isOtherCompanyInterested) {
        validationRule.otherCompanyIntereststed = this.state.otherCompanyIntereststedValidationRule;
      }
      if (this.state.selectKnowAboutUsOthers === true) {
        validationRule.knowAboutUsOthers = this.state.knowAboutUsOthersValidationRule;
      }
      if (this.state.corporateMemberDetail.companyProfile.companyTypeId !== 1) {
        validationRule.countryOfIncorporationId = this.state.countryOfIncorporationIdValidationRule;
      }
      if (this.state.corporateMemberDetail.companyProfile.companyTypeId === 1) {
        validationRule.companyUEN = this.state.companyUENValidationRule;
        validationRule.aCRAFilePath = this.state.aCRAFilePathValidationRule;
      }
      else if (this.state.corporateMemberDetail.companyProfile.companyTypeId === 2) {
        validationRule.businessRegistrationNo = this.state.businessRegistrationNoValidationRule;
      }
      const newValidState = isValidForm(
        this.state.corporateMemberDetail.companyProfile,
        validationRule,
        this.state.validStateCompanyProfile,
      );
      this.setState({ validStateCompanyProfile: newValidState });
      return newValidState.isValid;
    }
    else if (section === "Point_Of_Contact_Information") {
      const newValidState = isValidForm(
        this.state.corporateMemberDetail.pointOfContactInformation,
        PointOfContactInformationValidationRules,
        this.state.validStatePointofContactInformation
      )
      this.setState({ validStatePointofContactInformation: newValidState });
      return newValidState.isValid;
    }
    else if (section === "Finance_Department_Contact") {
      const newValidState = isValidForm(
        this.state.corporateMemberDetail.financeDepartment,
        FinanceDepartmentValidationRules,
        this.state.validStateFinanceDepartmentContact
      )
      this.setState({ validStateFinanceDepartmentContact: newValidState });
      return newValidState.isValid;
    }
    else if (section === "Engagement_Tracking") {
      const newValidState = isValidForm(
        this.state.engagementTrackingDetails,
        EngagementTrackingValidationRules,
        this.state.validStateEngagementTracking
      );
      this.setState({ validStateEngagementTracking: newValidState });
      return newValidState.isValid;
    }
    else if (section === "Organisation_Key_Role") {
      const newValidState = isValidForm(
        this.state.organisationKeyRoleDetail,
        OrganisationKeyRoleValidationRules,
        this.state.validStateOrganisationKeyRole,
      );
      this.setState({ validStateOrganisationKeyRole: newValidState });
      return newValidState.isValid;
    }
    else if (section === "Organisation_Employee") {
      const newValidState = isValidForm(
        this.state.employeeDetails,
        OrganisationEmployeeValidationRules,
        this.state.validStateOrgEmployee,
      );
      this.setState({ validStateOrgEmployee: newValidState });
      return newValidState.isValid;
    }
    else if (section === "Organisation_Profile") {
      const newValidState = isValidForm(
        this.state.corporateOrganisationDetails,
        RepresantativeInformationValidationRules,
        this.state.validStateRepresantativeInformation
      )
      this.setState({ validStateRepresantativeInformation: newValidState });
      return newValidState.isValid;
    }
  };

  // Internal Data Handling Method Section
  onModalOnOff = (state, roleId, isCreateNewOrgRole) => {
    this.resetValidation("resetValidation1");
    this.setState({ isCreateNewOrgRole: isCreateNewOrgRole, organisationKeyRoleModal: state })
    if (roleId) {
      this.getOrganisationKeyRolesById(roleId);
    }
    if (state === true) {
      // this.resetValidation("resetValidation1");
      this.resetOrgRoleEmpModal("Organisation_Role");
    }
    this.setState({ setOrganisationKeyRoleModal: state });
  }

  empModalOnOff = (state, empId, isCreateNewOrgRoleForEmployee) => {
    this.resetValidation("resetValidation2");
    this.setState({ isCreateNewOrgRoleForEmployee: isCreateNewOrgRoleForEmployee, setOrganisationEmployeeModal: state });
    if (empId) {
      this.getEmployeeInformationById(empId);
    }
    if (state === false) {
      this.resetOrgRoleEmpModal("Edit_Employee");
    }
    // Add condition for isCreateNewOrgRoleForEmployee
    // else if (isCreateNewOrgRoleForEmployee === true) {
    //   this.setState({ isAddEditProfileButtonLoader: false, isCreateNewOrgRoleForEmployee: true });
    // }
    else {
      this.setState({ isAddEditProfileButtonLoader: false });
    }
  }

  // isEditable = (state) => {
  //   this.setState({ isEmployeeModalEditable: state });
  // }

  // Reset Organisation Role Detail Method
  resetOrgRoleEmpModal = (identity) => {
    let reset1 = this.state.organisationKeyRoleDetail;
    let reset2 = this.state.employeeDetails;
    if (identity === "Organisation_Role") {
      //reset1.organisationRoleId= 0;
      reset1.individualMemberId = 0;
      reset1.salutationId = 0;
      reset1.mrMrs = 1;
      reset1.firstName = "";
      reset1.lastName = "";
      reset1.email = "";
      reset1.contactNo = "";
      reset1.designation = "";
      reset1.organisationRole = 0;
      reset1.corporateMemberId = 0;
      reset1.emailDomainId = 0;
      reset1.emailText = "";
      reset1.emailString = "";
      reset1.selectedDesignation = [];
      reset1.selectedOrganisationRole = [];
      reset1.organisationRoleList = [];
      reset1.searchByEmail = '';
      reset1.organisationRoleId = 0;
      reset1.jobPositionId = 0;
    }
    else if (identity === "Edit_Employee") {
      reset2.mrMrsId = 0;
      reset2.contactNo = "";
      reset2.corporateMemberId = 0;
      reset2.designation = "";
      reset2.email = "";
      reset2.firstName = "";
      reset2.individualMemberId = 0;
      reset2.lastName = "";
      reset2.selectedDesignation = [];
      reset2.selectedOrganisationRole = [];
      reset2.organisationRoleList = [];
      reset2.organisationRoleId = 0;
      reset2.organisationRole = "";
      reset2.emailDomain = "";
    }
  }
  // Reset Validation Method
  resetValidation = (identity) => {
    let resetVal1 = this.state.validStateOrganisationKeyRole;
    let resetVal2 = this.state.validStateOrgEmployee;
    if (identity === "resetValidation1") {
      resetVal1.isValid = true;
      resetVal1.error = {};
    }
    else if (identity === "resetValidation2") {
      resetVal2.isValid = true;
      resetVal2.error = {};
    }
    this.setState({ validStateOrganisationKeyRole: resetVal1, validStateOrgEmployee: resetVal2 });
  }

  // handle Open Close Each Section of View/Create Corporate Member
  openSection = (section) => {
    // if (section === "CAI") {
    //   this.getCorporateMembershipSubscriptionById(this.state.corporateMemberId);
    // }
    if (section === "ETAN") {
      this.getEngagementTrackingListById(this.state.corporateMemberId);
    }
    else if (section === "CMS") {
      this.getCorporateMembershipSubscriptionById(this.state.corporateMemberId);
    }
    else if (section === "companyprofile") {
      this.getOrganisationProfileById(this.state.corporateMemberId, true);
    }
    else if (section === "OrganisationBasicInformation") {
      this.getCorporateRepresentativeDetailsById(this.state.corporateMemberId, true);
    }
    else if (section === "Business_Presence") {
      this.getBusinessPresenceAndMarketInterestById(this.state.corporateMemberId, true);
    }
    else if (section === "organisationkeyroles") {
      // this.getOrganisationBussinessDetailsById();
      this.getOrganisationKeyRolesListById(this.state.corporateMemberId);
    }
    else if (section === "employeelist") {
      this.getEmployeeList(this.state.corporateMemberId, "");
    }
    else if (section === "finance") {
      // this.getOrganisationBussinessDetailsById();
    }
    else if (section === "financialsrecord") {
      this.getCorporateFinancialInfo(this.state.corporateMemberId);
    }
    this.setState({ openSection: this.state.openSection === section ? "" : section });
  };

  isRedirectOnRequest = () => {
    this.state.isViewOnly ?
      this.setState({ route: "/Membership/MembershipTaskList" })
      :
      this.setState({ route: "/Membership/ViewCorporateMembers" })
  }

  downloadPdf = () => {
    this.setState({ isLoadingPdf: true });
    let request = [
      "artical",
      this.state.corporateMemberDetail.companyProfile.aCRAFilePath
    ]
    this.commonServices.getPDF(request).then((response) => {
      if (response.data != null && response.status === 200) {
        var filename = this.state.corporateMemberDetail.companyProfile.aCRAFilePath;
        var file = new Blob([response.data], { type: "application/pdf" });
        URL.createObjectURL(file);
        saveAs(file, filename);
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoadingPdf: false });
    });
  }
  financeGetCreditNoteInvoice = (value) => {
    let request = [];
    request.push(value);
    if (isNullString(value) !== "") {
      this.financeService
        .financeGetCreditNoteInvoice(request)
        .then((response) => {
          if (response.data !== null && response.status === 200) {
            var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");

            var filename = "";
            filename = `Credit-Note-${formattedDate}`;

            var file = new Blob([response.data], { type: "application/pdf" });

            URL.createObjectURL(file);
            window.open(URL.createObjectURL(file), "_blank");

            saveAs(file, filename + ".pdf");
          } else {
            this.swalServices.Error(response.message);
          }
        });
    } else {
      this.swalServices.Error("Credit Note is not available");
    }
  };
  getCreditNoteInfoForCorporateMember = (membershipPaymentId, paymentStatus, isReceipt) => {
    if (this.state.corporateMemberId > 0 && membershipPaymentId > 0) {
      let request = [this.state.corporateMemberId, membershipPaymentId];
      this.financeService.getCreditNoteInfoForCorporateMember(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          this.setState({ corporateCreditNotePaymentInfo: response.responseItem.responseContent });
          let creditNote = { ...this.state.addEditCreditNote };
          if (isNullString(paymentStatus) === 'Pending' && isNullBoolean(isReceipt) === false) {
            creditNote.entranceRefund = 0
            creditNote.annualRefund = 0
            creditNote.chapterRefund = 0;
            creditNote.subTotalRefund = 0;
            creditNote.gstRefund = 0;
            creditNote.discountRefund = 0;
            creditNote.totalRefund = response.responseItem.responseContent.paidPriceByReconciliation ? response.responseItem.responseContent.paidPriceByReconciliation : 0;
            creditNote.paidTotalAmount = response.responseItem.responseContent.paidPriceByReconciliation ? response.responseItem.responseContent.paidPriceByReconciliation : 0;
            //creditNote.totalRefund = 0;
            creditNote.compareEntranceRefund = 0;
            creditNote.compareAnnualRefund = 0;
            creditNote.compareChapterRefund = 0;
            creditNote.creditNoteMonth = response.responseItem.responseContent.subscriptionStartDate ? response.responseItem.responseContent.subscriptionStartDate : new Date();// moment();
            //added by jigar 
            // creditNote.refundMembershipEntranceFee = response.responseItem.responseContent.entranceFee > 0 && response.responseItem.responseContent.totalAmount > 0  ?  ((response.responseItem.responseContent.entranceFee / response.responseItem.responseContent.totalAmount) * response.responseItem.responseContent.paidPriceByReconciliation) : 0 ;
            // creditNote.refundAnnualFee = response.responseItem.responseContent.annualFee > 0 && response.responseItem.responseContent.totalAmount > 0  ?  ((response.responseItem.responseContent.annualFee / response.responseItem.responseContent.totalAmount) * response.responseItem.responseContent.paidPriceByReconciliation) : 0 ;
            // creditNote.refundSubtotal = response.responseItem.responseContent.subTotalAmount > 0 && response.responseItem.responseContent.totalAmount > 0  ?  ((response.responseItem.responseContent.subTotalAmount / response.responseItem.responseContent.totalAmount) * response.responseItem.responseContent.paidPriceByReconciliation) : 0 ;
            // creditNote.refundGST = response.responseItem.responseContent.gstAmount > 0 && response.responseItem.responseContent.totalAmount > 0  ?  ((response.responseItem.responseContent.gstAmount / response.responseItem.responseContent.totalAmount) * response.responseItem.responseContent.paidPriceByReconciliation) : 0 ;
            // creditNote.refundDiscount = response.responseItem.responseContent.discountedAmount > 0 && response.responseItem.responseContent.totalAmount > 0  ?  ((response.responseItem.responseContent.discountedAmount / response.responseItem.responseContent.totalAmount) * response.responseItem.responseContent.paidPriceByReconciliation) : 0 ;
            // creditNote.refundTotalRefundAmount = response.responseItem.responseContent.totalAmount > 0 && response.responseItem.responseContent.totalAmount > 0  ?  ((response.responseItem.responseContent.totalAmount / response.responseItem.responseContent.totalAmount) * response.responseItem.responseContent.paidPriceByReconciliation) : 0 ;


            creditNote.compareEntranceRefund = response.responseItem.responseContent.entranceFee;
            creditNote.compareAnnualRefund = response.responseItem.responseContent.annualFee;
            // creditNote.entranceRefund  = parseFloat((creditNote.refundMembershipEntranceFee).toFixed(2)); 
            // creditNote.annualRefund =  parseFloat((creditNote.refundAnnualFee).toFixed(2)) ;
            // creditNote.subTotalRefund = parseFloat((creditNote.refundSubtotal).toFixed(2));
            // creditNote.gstRefund =   parseFloat((creditNote.refundGST).toFixed(2));;

          } else {
            creditNote.entranceRefund = response.responseItem.responseContent.entranceFee;
            creditNote.annualRefund = response.responseItem.responseContent.annualFee;
            creditNote.chapterRefund = response.responseItem.responseContent.addtionalChapterFee;
            creditNote.subTotalRefund = response.responseItem.responseContent.subTotalAmount;
            creditNote.gstRefund = response.responseItem.responseContent.gstAmount;
            creditNote.discountRefund = response.responseItem.responseContent.discountedAmount;
            creditNote.totalRefund = response.responseItem.responseContent.totalAmount;
            creditNote.paidTotalAmount = response.responseItem.responseContent.totalAmount;
            creditNote.compareEntranceRefund = response.responseItem.responseContent.entranceFee;
            creditNote.compareAnnualRefund = response.responseItem.responseContent.annualFee;
            creditNote.compareChapterRefund = response.responseItem.responseContent.addtionalChapterFee;
            creditNote.creditNoteMonth = "";//response.responseItem.responseContent.subscriptionStartDate ? response.responseItem.responseContent.subscriptionStartDate : new Date();// moment();
          }
          creditNote.membershipPaymentTypeId = response.responseItem.responseContent.membershipPaymentTypeId;
          creditNote.paymentStatus = paymentStatus;
          this.setState({ addEditCreditNote: creditNote, gstRate: response.responseItem.responseContent.gstRate });
        }
        else {
          this.swalServices.Error(response.message);
        }
      });
    }
  };
  getCancellationReasonForCreditNote = () => {
    this.financeService.getCancellationReasonForCreditNote().then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null && response.responseItem.responseContent.length > 0) {
        let cancelReason = response.responseItem.responseContent;
        this.setState({ cancellationReason: cancelReason });
      }
      else {
        this.swalServices.Error(response.message);
      }
    });
  }


  addFinanceCreditNote = () => {
    if (this.isValidFinanceCreditNote()) {
      if (this.isValidFinanceCreditNoteForOtherReason()) {

        if (this.state.addEditCreditNote.paidTotalAmount >= this.state.addEditCreditNote.totalRefund) {

          this.setState({ isLoading: true })
          let request = {
            corporateMemberId: this.state.corporateMemberId,
            bookingId: this.state.addEditCreditNote.membershipPaymentId,
            isCorporateMember: true,
            creditNoteMonth: this.state.addEditCreditNote.membershipPaymentTypeId === 1 || this.state.addEditCreditNote.membershipPaymentTypeId === 2 || this.state.addEditCreditNote.membershipPaymentTypeId === 3 ? this.state.addEditCreditNote.creditNoteMonth : this.state.corporateCreditNotePaymentInfo.subcriptionEndDate,
            creditNoteTotalRefund: this.state.addEditCreditNote.totalRefund,
            creditNoteStatusId: 1,
            creditNoteItem: [],
            creditNoteCancellationReason: this.state.addEditCreditNote.cancellationReason,
            CreatedAppId: 114,
            CreatedAt: moment(),
            CreatedBy: 1,
            creditSubTotalAmount: this.state.addEditCreditNote.subTotalRefund,
            creditNoteGSTAmount: this.state.addEditCreditNote.gstRefund,
            creditNoteDiscountAmount: this.state.addEditCreditNote.discountRefund ? this.state.addEditCreditNote.discountRefund : 0,
            membershipPaymentTypeId: this.state.addEditCreditNote.membershipPaymentTypeId,
          }
          let creditNoteItemList = [];
          let stardDate = this.state.corporateCreditNotePaymentInfo.subscriptionStartDate;
          let endDate = this.state.corporateCreditNotePaymentInfo.subcriptionEndDate;
          let membershipTypeName = this.state.corporateCreditNotePaymentInfo.membershipTypeName;
          creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Entrance Fee`, creditNoteItemAmount: this.state.addEditCreditNote.entranceRefund });
          creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Annual Fee (${moment(stardDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")})`, creditNoteItemAmount: this.state.addEditCreditNote.annualRefund });
          //creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Additional Chapter Fee (${moment(stardDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")})`, creditNoteItemAmount: this.state.addEditCreditNote.chapterRefund });
          // creditNoteItemList.push({ creditNoteItem: "Membership Subtotal Amount", creditNoteItemAmount: this.state.addEditCreditNote.subTotalRefund });
          // creditNoteItemList.push({ creditNoteItem: "Membership GST Amount", creditNoteItemAmount: this.state.addEditCreditNote.gstRefund });
          // creditNoteItemList.push({ creditNoteItem: "Membership Discounted Amount", creditNoteItemAmount: this.state.addEditCreditNote.discountRefund });
          // creditNoteItemList.push({ creditNoteItem: "Membership Total Amount", creditNoteItemAmount: this.state.addEditCreditNote.totalRefund });
          request.creditNoteItem = creditNoteItemList;
          this.financeService.addFinanceCreditNote(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              if (response.responseItem.responseContent.errorMessage === '') {
                this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Credit Note"));
                this.getCorporateFinancialInfo(this.state.corporateMemberId);
                //this.addCreditNoteInvoiceRecordsForBatchJob(response.responseItem.responseContent.keyId);
              }
              else {
                this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Credit note"));
              }
            }
            else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          });
        }
        else {
          this.swalServices.Warning("Refund Amount Must be Less then Total Amount of Paid");
          this.setState({ isLoading: false });
        }
      }
    }
  }
  isValidFinanceCreditNote = () => {
    const newValidState = isValidForm(this.state.corporateCreditNotePaymentInfo, addEditCreditNoteValRules, this.state.validCreditNote);
    this.setState({ validCreditNote: newValidState });
    return newValidState.isValid;
  }

  validateFinanceCreditNote = (key, providedState) => {
    providedState = providedState ? providedState : this.state.corporateCreditNotePaymentInfo;
    const newValidState = validate(key, providedState, addEditCreditNoteValRules, this.state.validCreditNote);
    this.setState({ validCreditNote: newValidState });
  }
  isValidFinanceCreditNoteForOtherReason = () => {
    const newValidState = isValidForm(this.state.addEditCreditNote, addEditCreditNoteValRules2, this.state.validCreditNote2);
    this.setState({ validCreditNote2: newValidState });
    return newValidState.isValid;
  }
  validFinanceCreditNoteForOtherReason = (key, providedState) => {
    providedState = providedState ? providedState : this.state.addEditCreditNote;
    const newValidState = validate(key, providedState, addEditCreditNoteValRules2, this.state.validCreditNote2);
    this.setState({ validCreditNote2: newValidState });
  }
  addEditFinanceCardDetails = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      var request = this.state.corporateFinance;
      request.corporateMemberId = this.state.corporateMemberId;
      request.createdBy = 1;
      request.createdAppId = 115;

      this.setState({ isLoading: true });
      this.membershipCorporateServices.addEditFinanceCardDetails(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("Finance details updated successfully.");
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })

    } else {
      this.swalServices.Warning("Please enter Membership ID corporate account information.");
    }
  }
  handleRadioButtonsFinanceCard = (e) => {
    const { name, value } = e.target;
    let det = this.state.corporateFinance;
    if (name === "sendInvoiceReminder") {
      det[name] = value;
    }
    else {
      det[name] = Number(value);
    }
    this.setState({ corporateFinance: { ...det } });
  };

  setAddEditModel = (boolean) => {
    //this.setState({ addEditModel : boolean })
    this.setState({ addEditModelForEmployee: boolean })
    this.resetEmployeeInfo()
  }
  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section accordian-body">
          {/* Account Information */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="CAI"
                type="checkbox"
                name="corporate-d"
                value={"CAI"}
                checked={this.state.openSection === "CAI"}
                onClick={() => this.openSection("CAI")}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="CAI"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Corporate Account Information
                </span>
                {/* <div className="flex items-left justify-between text-[18px] text-[#181818]">
                  Corporate ID: S12345
                </div> */}
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0">
                <AccountInformation
                  _by={this.state._by}
                  status={this.state.status}
                  reason={this.state.reason}
                  accountBasicInformation={this.state.accountBasicInformation}
                  handleChange={this.handleChange.bind(this)}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  onSave={this.addEditSIISCorporateAccountInformation.bind(this)}
                  // validState={this.state.validSiisAccountInfo}
                  // validate={this.siisAccountInfoValidate.bind(this)}
                  isLoading={this.state.isLoading}
                />
              </div>
            </div>
          </div>
          {/* Account Information */}

          {/* Engagement Tracking and Notes */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="ETAN"
                type="checkbox"
                name="corporate-d"
                value={"ETAN"}
                checked={this.state.openSection === "ETAN"}
                onClick={() => this.openSection("ETAN")}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="ETAN"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Engagement Tracking and Notes
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0 ">
                <EngagementTrackingNotes
                  _by={this.state._by}
                  engagement={this.state.engagement}
                  engagementTrackingList={this.state.engagementTrackingList}
                  engagementTrackingDetails={this.state.engagementTrackingDetails}
                  onEdit={this.onEditEngagement.bind(this)}
                  isCorporate={this.state.corporateMemberId}
                  onClick={this.addEditEngagementTracking.bind(this)}
                  handleChange={this.handleChange.bind(this)}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  // validate={this.engagementValidate.bind(this)}
                  validState={this.state.validStateEngagementTracking}
                  isLoading={this.state.isLoading}
                  recorded_by={this.state.recorded_by}
                  handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                />
              </div>
            </div>
          </div>
          {/* Engagement Tracking and Notes */}

          {/* Membership Subscription */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="CMS"
                type="checkbox"
                name="corporate-d"
                value={"CMS"}
                checked={this.state.openSection === "CMS"}
                onClick={() => this.openSection("CMS")}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="CMS"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Corporate Membership Subscription
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0 ">
                <MembershipSubscription
                  membershipTypes={this.state.membershipTypes}
                  billingCategory={this.state.billingCategory}
                  membershipSubscription={this.state.membershipSubscription}
                  isLoading={this.state.isLoading}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  onSave={this.addEditCorporateMembershipSubscription.bind(this)}
                />
              </div>
            </div>
          </div>
          {/* Membership Subscription */}

          {/* Company Profile */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="companyprofile"
                type="checkbox"
                name="corporate-d"
                value={"companyprofile"}
                checked={this.state.openSection === "companyprofile"}
                onClick={() => this.openSection("companyprofile")}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="companyprofile"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Company Profile
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0 " >
                <CompanyProfile
                  companyType={this.state.companyType}
                  countries={this.state.countries}
                  countriesSingleDrp={this.state.countriesSingleDrp}
                  annualTurnOver={this.state.annualTurnOver}
                  companyBusinessType={this.state.companyBusinessType}
                  companyInterestType={this.state.companyInterestType}
                  getToKnowUs={this.state.getToKnowUs}
                  companyProfile={this.state.corporateMemberDetail.companyProfile}
                  corporateMemberDetail={this.state.corporateMemberDetail}
                  companyEmployeementSize={this.state.companyEmployeementSize}
                  validStateCompanyProfile={this.state.validStateCompanyProfile}
                  validStateEngagementTracking={this.state.validStateEngagementTracking}
                  isLoading={this.state.isLoading}
                  handleChange={this.handleChange.bind(this)}
                  onFileChange={this.onFileChange.bind(this)}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  validateField={this.validateField.bind(this)}
                  onSave={this.addEditCorporateCompanyProfile.bind(this)}
                  saveMainImage={this.saveMainImage.bind(this)}
                  handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                  downloadPdf={this.downloadPdf.bind(this)}
                  validStateFinanceDepartmentContact={this.state.validStateFinanceDepartmentContact}
                  validStatePointofContactInformation={this.state.validStatePointofContactInformation}
                  salutation={this.state.salutation}
                  designations={this.state.jobFunctionList}
                  selectKnowAboutUsOthers={this.state.selectKnowAboutUsOthers}
                // organisationRoles={this.state.organisationRolesList}
                />
              </div>
            </div>
          </div>
          {/* Company Profile */}

          {/* OrganisationBasicInformation */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="OrganisationBasicInformation"
                type="checkbox"
                name="corporate-d"
                value={"OrganisationBasicInformation"}
                checked={this.state.openSection === "OrganisationBasicInformation"}
                onClick={() => this.openSection("OrganisationBasicInformation")}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="OrganisationBasicInformation"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Company Representative
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0 ">
                <OrganisationBasicInformation
                  corporateOrganisationDetails={this.state.corporateOrganisationDetails}
                  jobFunctionList={this.state.jobFunctionList}
                  setFilterParameters={this.setFilterParametersOrg.bind(this)}
                  salutation={this.state.salutation}
                  handleChange={this.handleChange.bind(this)}
                  validateField={this.validateField.bind(this)}
                  validStateRepresantativeInformation={this.state.validStateRepresantativeInformation}
                  isLoading={this.state.isLoading}
                  onSave={this.addEditRepresantativeInformationById.bind(this)}
                />
              </div>
            </div>
          </div>
          {/* OrganisationBasicInformation */}

          {/* Business Presence / Market Interest */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="Business_Presence"
                type="checkbox"
                name="corporate-d"
                value={"Business_Presence"}
                checked={this.state.openSection === "Business_Presence"}
                onClick={() => this.openSection("Business_Presence")}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="Business_Presence"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Business Presence / Market Interest
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0 " >
                <BusinessPresence
                  companyPresenceList={this.state.companyPresenceList}
                  marketIntersetList={this.state.marketIntersetList}
                  businessPresenceDetails={this.state.businessPresenceDetails}
                  isLoading={this.state.isLoading}
                  handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                  onSave={this.addEditBusinessPresenceAndMarketInterest.bind(this)}
                  handleChange={this.handleChange.bind(this)}
                />
              </div>
            </div>
          </div>
          {/* Business Presence / Market Interest */}

          {/* Organisation Role */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="organisationkeyroles"
                type="checkbox"
                name="corporate-d"
                value={"organisationkeyroles"}
                checked={this.state.openSection === "organisationkeyroles"}
                onClick={() => this.openSection("organisationkeyroles")}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="organisationkeyroles"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Organisation Key Roles
                </span>
                <div className="flex items-center justify-between right-id-sec absolute right-[65px] top-[15px]">
                  <div className="flex items-center justify-end">
                    <button
                      className="flex items-center plush-btn z-10"
                      onClick={(e) => {
                        // e.stopPropagation();
                        this.setState({ isEmployeeModalEditable: false });
                        this.onModalOnOff(true, 0, true);
                        this.resetEmployeeInfo();
                      }}
                    >
                      <span className="text-xl text-[#b92262] font-semibold mr-4 min-w-[30px] min-h-[20px] border border-[#b92262]">
                        +
                      </span>
                      <span className="text-xl text-[#b92262] font-semibold tracking-normal">
                        Add New Profile
                      </span>
                    </button>
                  </div>
                </div>
              </label>

              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0 ">
                <OrganisationRole
                  salutation={this.state.salutation}
                  jobFunctionList={this.state.jobFunctionList}
                  organisationRole={this.state.organisationRole}
                  organisationRolesList={this.state.organisationRolesList}
                  organisationKeyRoleList={this.state.organisationKeyRoleList}
                  organisationKeyRoleDetail={this.state.organisationKeyRoleDetail}
                  detail={this.state.corporateMemberDetail}

                  setOrganisationKeyRoleModal={this.state.setOrganisationKeyRoleModal}
                  validStateOrganisationKeyRole={this.state.validStateOrganisationKeyRole}
                  onModalOnOff={this.onModalOnOff.bind(this)}
                  validateField={this.validateField.bind(this)}
                  handleChange={this.handleChange.bind(this)}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  addEditOraganisation={this.addEditOraganisation.bind(this)}
                  handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                  deleteOrganisationRole={this.deleteOrganisationRole.bind(this)}
                  isCreateNewOrgRole={this.state.isCreateNewOrgRole}
                  organisationKeyRoleModal={this.state.organisationKeyRoleModal}
                  searchIndividualMemberByEmailForOrgRole={this.searchIndividualMemberByEmailForOrgRole.bind(this)}
                  isEmployeeModalEditable={this.state.isEmployeeModalEditable}
                />
              </div>
            </div>
          </div>
          {/* Organisation Role */}

          {/* Employees List*/}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="employeelist"
                type="checkbox"
                name="corporate-d"
                value={"employeelist"}
                checked={this.state.openSection === "employeelist"}
                onClick={() => this.openSection("employeelist")}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="employeelist"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Employees
                </span>
                <div className="flex items-center justify-between right-id-sec xl:w-[35%] absolute right-[25px] top-[8px]">
                  <div className="flex items-center justify-end">
                    <button
                      className="flex items-center plush-btn z-10 lg:w-[185px] w-[180px]"
                      onClick={(e) => {
                        this.setState({ isEmployeeModalEditable: false });
                        this.empModalOnOff(true, 0, true);
                        this.resetEmployeeInfo();
                      }}
                    >
                      <span className="text-xl text-[#b92262] font-semibold mr-4 min-w-[30px] min-h-[20px] border border-[#b92262]">
                        +
                      </span>
                      <span className="text-xl text-[#b92262] font-semibold tracking-normal">
                        Add Employee
                      </span>
                    </button>
                  </div>
                  <TextInput
                    placeholder="Search"
                    name=""
                    handleChange={(e) => this.searchByName(e.target.value)}
                  />
                </div>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0 ">
                <EmployeeList
                  salutation={this.state.salutation}
                  jobFunctionList={this.state.jobFunctionList}
                  organisationRolesList={this.state.organisationRolesList}
                  organisationEmployeeList={this.state.organisationEmployeeList}
                  employeeDetails={this.state.employeeDetails}
                  setOrganisationEmployeeModal={this.state.setOrganisationEmployeeModal}
                  isCreateNewOrgRoleForEmployee={this.state.isCreateNewOrgRoleForEmployee}
                  validStateOrgEmployee={this.state.validStateOrgEmployee}
                  empModalOnOff={this.empModalOnOff.bind(this)}
                  handleChange={this.handleChange.bind(this)}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                  ediCorporateEmployeeDetails={this.ediCorporateEmployeeDetails.bind(this)}
                  searchIndividualMemberByEmailForOrgRole={this.searchIndividualMemberByEmailForOrgRole.bind(this)}
                  deleteEmployee={this.deleteEmployee.bind(this)}
                  validate={this.validateEmployeeInfo.bind(this)}
                  isEmployeeModalEditable={this.state.isEmployeeModalEditable}
                  setAddEditModel={this.setAddEditModel.bind(this)}
                  validateField={this.validateField.bind(this)}
                />
              </div>
            </div>
          </div>
          {/* Employees List*/}

          {/* Finance */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="finance"
                type="checkbox"
                name="corporate-d"
                value={"finance"}
                checked={this.state.openSection === "finance"}
                onClick={() => this.openSection("finance")}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="finance"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Finance
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0 ">
                <Finance
                  corporateFinance={this.state.corporateFinance}
                  onSave={this.addEditFinanceCardDetails.bind(this)}
                  handleRadioButtonsFinanceCard={this.handleRadioButtonsFinanceCard.bind(this)}
                  isLoading={this.state.isLoading}
                />
              </div>
            </div>
          </div>
          {/* Finance */}


          {/* Financials Record */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="financialsrecord"
                type="checkbox"
                name="corporate-d"
                value={"financialsrecord"}
                checked={this.state.openSection === "financialsrecord"}
                onClick={() => this.openSection("financialsrecord")}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="financialsrecord"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Financials Record
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0 ">
                <FinancialsRecord
                  corporateFinanceInfo={this.state.corporateFinanceInfo}
                  getCorporateFinanceReceiptInvoice={this.getCorporateFinanceReceiptInvoice.bind(this)}
                  financeGetCreditNoteInvoice={this.financeGetCreditNoteInvoice.bind(this)}
                  getCreditNoteInfoForCorporateMember={this.getCreditNoteInfoForCorporateMember.bind(this)}
                  corporateCreditNotePaymentInfo={this.state.corporateCreditNotePaymentInfo}
                  getCancellationReasonForCreditNote={this.getCancellationReasonForCreditNote.bind(this)}
                  cancellationReason={this.state.cancellationReason}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  isOtherCancellationReason={this.state.isOtherCancellationReason}
                  addEditCreditNote={this.state.addEditCreditNote}
                  handleChange={this.handleChange.bind(this)}
                  addFinanceCreditNote={this.addFinanceCreditNote.bind(this)}
                  validate={this.validateFinanceCreditNote.bind(this)}
                  validCreditNote={this.state.validCreditNote}
                  validate2={this.validFinanceCreditNoteForOtherReason.bind(this)}
                  validCreditNote2={this.state.validCreditNote2}
                  isLoading={this.state.isLoading}
                />
              </div>
            </div>
          </div>
          {/* Financials Record */}
          <div className="flex items-center justify-between mt-6">
            <button
              className="btn btn-gray text-xl text-white"
              onClick={() => this.isRedirectOnRequest()}
            >
              Back
            </button>
            <button
              className="text-lg font-bold btn btn-pink text-white"
            //onClick={() => this.addMembershipPricing()}
            >
              Save
            </button>
          </div>
        </div>
      </>
    );
  }
}