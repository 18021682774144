import React, { Component } from "react";

export default class Accountpayable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountPayableTableData: [
        {
          id: "1",
          aPDate: "5 Feb 2022",
          invoiceNumber: "3456CN",
          aPName: "1-Net Singapore Pte Ltd",
        },
        {
          id: "2",
          aPDate: "5 Feb 2022",
          invoiceNumber: "3457CN",
          aPName: "1BYTE VENTURES PTE. LTD",
        },
        {
          id: "3",
          aPDate: "5 Feb 2022",
          invoiceNumber: "3458CN",
          aPName: "3D BRAND AGENCY PTE. LTD.",
        },
        {
          id: "4",
          aPDate: "5 Feb 2022",
          invoiceNumber: "3459CN",
          aPName: "7-Network Pte Ltd",
        },
        {
          id: "5",
          aPDate: "5 Feb 2022",
          invoiceNumber: "3460CN",
          aPName: "A Loop Pte Ltd",
        },
        {
          id: "6",
          aPDate: "5 Feb 2022",
          invoiceNumber: "3461CN",
          aPName: "A*STAR Research Entities",
        },
        {
          id: "7",
          aPDate: "5 Feb 2022",
          invoiceNumber: "3462CN",
          aPName: "A-SAT Pte. Ltd.",
        },
        {
          id: "8",
          aPDate: "5 Feb 2022",
          invoiceNumber: "3463CN",
          aPName: "ABB Pte Ltd",
        },
        {
          id: "9",
          aPDate: "5 Feb 2022",
          invoiceNumber: "3464CN",
          aPName: "ABPGROUP Pte Ltd",
        },
        {
          id: "10",
          aPDate: "5 Feb 2022",
          invoiceNumber: "3465CN",
          aPName: "ACA Pacific Technology (S) Pte Ltd",
        },
      ],
    };
  }
  render() {
    return (
      <>
        <div className="main-body-section finance-body-sec">
          <div className="bg-white pb-2">
            <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10 pb-12">
              <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
                <h2 className="text-xl theme-color mb-3">Date</h2>
                <input
                  id="default"
                  name="dateFrom"
                  className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="date"
                  //   value={this.state.pagination.dateFrom}
                  //   onChange={(e) => this.handleChange(e)}
                />
              </div>
              <div className="2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
                <h2 className="text-xl theme-color mb-3">Invoice</h2>
                <div className="with-border-line w-full">
                  <input
                    className="input-texts form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="text"
                  ></input>
                </div>
              </div>
              <div className="2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
                <h2 className="text-xl theme-color mb-3">Name</h2>
                <div className="with-border-line w-full">
                  <input
                    className="input-texts form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="text"
                  ></input>
                </div>
              </div>
              <div className="2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
                <div className="pt-11 flex items-center justify-center w-full">
                  <button className="btn btn-red text-xl border text-white ">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-2 overflow-x-auto account-payable-table">
            <table className="w-full mx-auto my-5 fixed_header">
              <thead>
                <tr className="text-base font-bold flex items-center">
                  <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[15%]">
                    <div className="font-bold tble-th-txt text-left">Date</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[15%]">
                    <div className="font-bold tble-th-txt text-left">
                      Invoice Number
                    </div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[55%]">
                    <div className="font-bold tble-th-txt text-left">Name</div>
                  </th>
                  <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[15%]">
                    <div className="font-bold tble-th-txt text-left">
                      Action
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody className="scrollit text-sm custom-tbody">
                {this.state.accountPayableTableData.map((e,key) => {
                  return (
                    <tr className="custom-tbl-td flex items-center" key={key}>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[15%] text-left">
                        {e.aPDate}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[15%] text-left">
                        {e.invoiceNumber}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[55%] text-left">
                        {e.aPName}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[15%]">
                        <button
                          className="btn btn-lighblue font-bold text-[17px]"
                          // onClick={() => {
                          //   props.getAgencyOfficerById(officer.agencyOfficerId);
                          //   setAgencyOfficerId(officer.agencyOfficerId);
                          // }}
                        >
                          Paid
                        </button>
                      </td>
                    </tr>
                  );
                })}
                {/* <tr className="grid justify-items-center">
              <td className="text-lg text-[#757575]	">No Records Found</td>
            </tr> */}
              </tbody>
            </table>
          </div>
        </div>

      </>
    );
  }
}
