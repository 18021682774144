import React from "react";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import { MultiSelectDropdown, Selector } from "../../../components/allTypesInputComponents/AllTypeInputComponents";

function BusinessPresence(props) {
  return (
    <>
      <div className="p-[25px] business-precence-accordian">
        <div className="grid grid-cols-12 gap-6 w-full">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-2 mb-2">
                  Company Presence In Other Country(les) {"(You can select more than one option) "}
                </h2>
                <Selector
                  // disabled={true}
                  isMulti
                  drpIdentity={"Company_presence"}
                  options={props.companyPresenceList}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={true}
                  // setFilterParameters={props.setFilterParameters}
                  value={props.businessPresenceDetails.selectedCompanyPresence}
                />
                {/* <ValidationText error={props.validStateCompanyProfile.error.employmentSizeId} /> */}
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-2 mb-2">
                  Market Interest Of Your Company {"(You can select more than one option) "}
                </h2>
                <Selector
                  // disabled={true}
                  isMulti
                  drpIdentity={"Market_interest"}
                  options={props.marketIntersetList}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={true}
                  // setFilterParameters={props.setFilterParameters}
                  value={props.businessPresenceDetails.selectedMarketInterest}
                />
                {/* <ValidationText error={props.validStateCompanyProfile.error.knowAboutUsId} /> */}
              </div>
            </div>
            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
              <div className="flex items-center justify-end lg:mt-5 mt-3" >
                {props && props.isLoading === true ? (
                  <ButtonLoader />
                ) : (
                  <button
                    className="btn btn-pink text-xl text-white font-semibold"
                    onClick={() => props.onSave()}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
export default BusinessPresence;