import React from "react";

const SectionWithNoBorder = (props) => {
    return (
                <div className="" >
                    <div className={`grid grid-cols-2 gap h-auto  w-full flex items-start`}>
                        <h2 className="text-size-18 text-[#181818] font-bold">{props.column1}</h2>
                        <h2 className="text-size-18 text-[#181818] font-bold">{props.column2}</h2>
                        <h2 className="text-size-18 text-[#181818] font-bold">{props.column3}</h2>
                    </div>
                    {props.children}
                </div>
    );
};
export default SectionWithNoBorder;