import React from "react";
import ButtonLoader from "../../common/buttonLoader/ButtonLoader";
import moment from "moment";
import DatePicker from "../../datePicker/DatePicker";
import DropdownSelect from "../../dropdown/Dropdown";
import TextInput from "../../UIComponent/TextInput";

function AccountInformation(props) {
  return (
    <>
      <div>
        <div className="grid grid-cols-12 gap-6 w-full p-[25px]">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Account Status
                </h2>
                <DropdownSelect
                  disabled={true}
                  drpIdentity={"AccountStatus"}
                  optionArray={props.status}
                  setFilterParameters={props.setFilterParameters}
                  value={props.accountBasicInformation.accountStatus ? props.accountBasicInformation.accountStatus : 7}
                />
                {/* <Validation error={props.validState.error.isAccountActive} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Reasons for Leaving
                </h2>
                <DropdownSelect
                  drpIdentity={"corporateReasonLeaving"}
                  optionArray={props.reason}
                  setFilterParameters={props.setFilterParameters}
                  value={props.accountBasicInformation.corporateReasonLeavingId ? props.accountBasicInformation.corporateReasonLeavingId : 0}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Joined Date
                </h2>
                <div className="">
                  <DatePicker
                    name="corporateJoinedDate"
                    handleChange={props.handleChange}
                    identity="SIISInformation"
                    value={props.accountBasicInformation.corporateJoinedDate ? moment(props.accountBasicInformation.corporateJoinedDate).format("yyyy-MM-DD") : ''}
                  />
                </div>
                {/* <Validation error={props.validState.error.corporateJoinedDate} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Updated Date
                </h2>
                {props.accountBasicInformation.updatedDate === "" || props.accountBasicInformation.updatedDate === null ?
                  <TextInput
                    disabled={true}
                    placeholder=""
                    type="text"
                    name="linkedIn"
                    value="Unavailable"
                    identity="SIISInformation"
                    isCurserAllowed={false}
                  />
                  :
                  <DatePicker
                    isDisable={true}
                    name="updatedDate"
                    identity="updatedDate"
                    handleChange={props.handleChange}
                    value={props.accountBasicInformation.updatedDate ? moment(props.accountBasicInformation.updatedDate).format("yyyy-MM-DD") : ''}
                  />
                }
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Updated by
                </h2>
                <DropdownSelect
                  disabled={true}
                  drpIdentity={"updatedById"}
                  optionArray={props._by}
                  setFilterParameters={props.setFilterParameters}
                  value={props.accountBasicInformation.createdBy ? props.accountBasicInformation.createdBy : 1}
                />
              </div>
            </div>
          </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                  Domain Information
                  <span className="text-[#c00000]">*</span>
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="dropdwn-input-email flex items-center">
                  <TextInput
                    // placeholder=""
                    // type="text"
                    name="companyDomain"
                    disabled={true}
                    value={props.accountBasicInformation.companyDomain ? props.accountBasicInformation.companyDomain : ""}
                    identity="SIISInformation"
                    handleChange={props.handleChange}
                    isCurserAllowed={false}
                    onBlur={(e) => props.validate("companyDomain", props.accountBasicInformation)}
                  // onBlur={(e) =>
                  //   props.validate(
                  //     "areaofExpertise",
                  //     props.corporateBasicInformation
                  //   )
                  // }

                  // value={this.state.searchbyParent}
                  // handleChange={(event) => {
                  //   this.setState({ searchByName: event.target.value });
                  // }}
                  />

                  {/* <Validation error={props.validState.error.areaofExpertise} /> */}
                </div>
                {/* <div>
                  <Validation error={props.validState.error.companyDomain} />
                </div> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="flex items-center justify-end">
              {props && props.isLoading === true ? (
                <ButtonLoader />
              ) : (
                <button
                  className="btn btn-pink text-xl text-white font-semibold"
                  onClick={() => props.onSave()}
                >
                  Save
                </button>
              )}
            </div>
          </div>

        </div>


      </div>
    </>
  );
}
export default AccountInformation;