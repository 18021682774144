import React, { useState, useRef, useEffect } from "react";
import Transition from "../../../src/utils/Transition";

function DropdownSelect(props) {

  const [isNumberDropDown, setIsNumberDropDown] = useState(props?.isNumberDropDown === true);
  let options = [
    {
      id: 0,
      name: isNumberDropDown === true ? "0" : "Select"
    }
  ];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(props.value);

  if (props.optionArray !== undefined) {
    if (props.optionArray.length > 0) {
      if (props.isRemoveDefaultOption && props.isRemoveDefaultOption === true) {
        options = props.optionArray;
      } else {
        options = options.concat(props.optionArray);
      }
    }
  }

  const trigger = useRef(null);
  const dropdown = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const setFilterParameters = (id, drpIdentity) => {
    props.setFilterParameters(id, drpIdentity);
  };
  return (
    <div className="relative inline-flex w-full" key={props.drpIdentity}>
      {props.disabled ? (
        <button
          disabled={true}
          ref={trigger}
          className="cursor-not-allowed btn btn-dropdown justify-between py-3 font-22 w-full bg-[#e9e9ea]  border-[#181818]  text-[#181818] px-4"
          aria-label="Select date range"
          aria-haspopup="true"
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
            setSelected(
              options.find((option) => option.id === Number(props.value)).id
            );
          }}
          aria-expanded={dropdownOpen}
        >
          <span className="flex items-center">
            {options.find((option) => option.id === Number(props.value)) ? (
              <span className="text-lg">
                {
                  options.find((option) => option.id === Number(props.value))
                    .name
                }
              </span>
            ) : (
              <span className="text-lg">Select</span>
            )}
            {/* <span className='text-lg'>{options.find(option => option.id === Number(props.value)).name}</span> */}
          </span>
          <svg
            className="shrink-0 ml-1 fill-current text-[#181818]"
            width="11"
            height="7"
            viewBox="0 0 11 7"
          >
            <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
          </svg>
        </button>
      ) : (
        <button
          ref={trigger}
          className="btn btn-dropdown justify-between py-3  text-[16px] w-full bg-white border-[#181818] hover:border-[#AA3361] text-[#181818] px-4 text-center"
          aria-label="Select date range"
          aria-haspopup="true"
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
            setSelected(
              options.find((option) => option.id === Number(props.value)).id
            );
          }}
          aria-expanded={dropdownOpen}
        >
          <span className="flex items-center">
            {options.find((option) => option.id === Number(props.value)) ? (
              <span className="text-lg text-left">
                {
                  options.find((option) => option.id === Number(props.value))
                    .name
                }
              </span>
            ) : (
              <span className="text-lg text-left">Select</span>
            )}
            {/* <span className='text-lg'>{options.find(option => option.id === Number(props.value)).name}</span> */}
          </span>
          <svg
            className="shrink-0 ml-1 fill-current text-[#181818]"
            width="11"
            height="7"
            viewBox="0 0 11 7"
          >
            <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
          </svg>
        </button>
      )}

      <Transition
        show={dropdownOpen}
        tag="div"
        className="dropdown-option-section-list z-60 absolute top-full left-0 w-full bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-x-hidden overflow-y-auto  mt-1 break-words"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="font-medium text-sm text-slate-600 fixheight"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {options.map((option) => {
            return (
              <button
                key={option.id}
                tabIndex="0"
                className={`flex items-center w-full z-60 hover:bg-slate-50 py-1 px-3 cursor-pointer ${option.id === selected && "hover-active-color"
                  }`}
                onClick={(event) => {
                  setSelected(option.id);
                  setDropdownOpen(false);
                  setFilterParameters(option.id, props.drpIdentity);
                }}
              >
                <svg
                  className={`shrink-0 mr-2 fill-current theme-text-color ${option.id !== selected && "invisible"
                    }`}
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                >
                  <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                </svg>
                <span className="theme-text-color">{option.name}</span>
              </button>
            );
          })}
        </div>
      </Transition>
    </div>
  );
}

export default DropdownSelect;
