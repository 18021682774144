import React from 'react';
import TextInput from '../../UIComponent/TextInput';
import DropdownSelect from '../../dropdown/Dropdown';
import ValidationText from '../../../utils/validation/ValidationText';

function IndividualInformation(props) {
  return (
    <>
      <div className='p-[25px]'>
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="flield-sec">
              <label className="text-[#181818] text-[20px] big-bullet-points font-bold 2xl:mb-1 inline-block mobile-14">
                First Name <span className="text-[#AA3361]">*</span>
              </label>
              <div className="flex items-center left-border-none">
                <div className="w-[130px]">
                  <DropdownSelect
                    drpIdentity={"Select_Salutation"}
                    optionArray={props.salutation}
                    setFilterParameters={props.setFilterParameters}
                    value={props.individualInformation.salutationId}
                  />
                </div>
                <TextInput
                  placeholder=""
                  name="firstName"
                  autoComplete={"off"}
                  identity="Individual_Information"
                  value={props.individualInformation.firstName}
                  handleChange={props.handleChange}
                  onBlur={() => props.validateField("firstName", "Individual_Information")}
                />
              </div>
              <ValidationText error={props.validStateIndividualInformation.error.salutationId} />
              <ValidationText error={props.validStateIndividualInformation.error.firstName} />
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Last Name
                  <span className="text-[#AA3361]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  name="lastName"
                  autoComplete={"off"}
                  identity="Individual_Information"
                  value={props.individualInformation.lastName}
                  handleChange={props.handleChange}
                  onBlur={() => props.validateField("lastName", "Individual_Information")}
                />
                <ValidationText error={props.validStateIndividualInformation.error.lastName} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Year of Birth
                  <span className="text-[#AA3361]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  name="yearOfBirth"
                  autoComplete={"off"}
                  identity="Individual_Information"
                  value={props.individualInformation.yearOfBirth}
                  handleChange={props.handleChange}
                  onBlur={() => props.validateField("yearOfBirth", "Individual_Information")}
                />
                <ValidationText error={props.validStateIndividualInformation.error.yearOfBirth} />
              </div>
            </div>
          </div>
          <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="flield-sec">
              <label className="text-[#181818] text-[20px] big-bullet-points font-bold 2xl:mb-1 inline-block mobile-14">
                Mobile Number <span className="text-[#AA3361]">*</span>
              </label>
              <div className="flield-sec">
                <TextInput
                  placeholder=""
                  name="mobileNumber"
                  autoComplete={"off"}
                  identity="Individual_Information"
                  value={props.individualInformation.mobileNumber}
                  handleChange={props.handleChange}
                  onBlur={() => props.validateField("mobileNumber", "Individual_Information")}
                />
                <ValidationText error={props.validStateIndividualInformation.error.mobileNumber} />
              </div>
            </div>
          </div>
          <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
            <div className="flield-sec flex flex-col">
              <label className="text-[#181818] text-[20px] big-bullet-points font-bold  2xl:mb-1 inline-block mobile-14 w-full">
                Email Address <span className="text-[#AA3361]">*</span>
              </label>
              <TextInput
                placeholder=""
                name="emailAddress"
                autoComplete={"off"}
                identity="Individual_Information"
                value={props.individualInformation.emailAddress}
                handleChange={props.handleChange}
                onBlur={() => props.validateField("emailAddress", "Individual_Information")}
              />
              <ValidationText error={props.validStateIndividualInformation.error.emailAddress} />
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Nationality
                  <span className="text-[#AA3361]">*</span>
                </h2>
                <DropdownSelect
                  // disabled={true}
                  drpIdentity={"Select_Nationality"}
                  optionArray={props.nationalityType}
                  setFilterParameters={props.setFilterParameters}
                  value={props.individualInformation.nationalityId}
                />
                <ValidationText error={props.validStateIndividualInformation.error.nationalityId} />
              </div>
            </div>
          </div>
        </div>
        <div className="xl:col-span-12 lg:col-span-12 col-span-12">
          <div className="flex items-center justify-end">
            <button
              className="btn btn-pink text-xl text-white font-semibold mt-4"
              onClick={() => props.updateIndividualInformationByIndividualId()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
export default IndividualInformation;