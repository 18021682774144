import React, { Component } from 'react';
import Table from '../../../../../components/table/Table';
import { DateInput } from '../../../../../components/allTypesInputComponents/AllTypeInputComponents';
// import DropdownSelect from '../../../../../components/dropdown/Dropdown';
import moment from 'moment';
import { validate, isValidForm } from "../../../../../utils/validation/CommonValidator";
import MembershipTaskListProcess from './MebershipTaskListProcess';
import { CommonSuccessMessages } from "../../../../../utils/Messages";
import { encryptAES } from '../../../../../utils/Encryption';
import { Navigate } from 'react-router-dom';
//import { NavigateWithData, Routes } from "../../../../../navigation/ComponentNavigation";
// Import API Services
import IndividualMemberService from '../../../../../services/axiosServices/apiServices/IndividualMemberService';
import IndividalMemberTaskListServices from '../../../../../services/axiosServices/apiServices/IndividalMemberTaskListServices';
import SwalServices from '../../../../../services/swalServices/SwalServices';
import DropdownSelect from "../../../../../components/dropdown/Dropdown";
import DropdownAction from "../../../../../components/dropdown/DropdownAction";
import ActionContainer from "../../../../../components/UIComponent/ActionTableComponent/actionContainer";

export default class IndividualMembershipTaskList extends Component {
  constructor(props) {
    super(props);
    const moment = require('moment');

    this.swalServices = new SwalServices();
    this.individualMemberService = new IndividualMemberService();
    this.individalMemberTaskListServices = new IndividalMemberTaskListServices();
    this.state = {
      gstPercentage: 0,
      totalResultes: 0,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        dateFrom: "",
        dateTo: "",
        // filterBySource: 0,
        searchText: "",
        isApprovalRequired: false,
        statusId: 0,
      },
      selectedTaskId: 0,
      setAddEditModalOpen: false,
      membershipTypeId: 1,
      isLoading: false,
      isRejectLoading: false,
      isAcceptLoading: false,
      // Individual Task List
      deactionDropdown: ["View", "Delete"],
      actionDropdown: ["View", "Delete"],
      engagementTrackingList: [],
      statusList: [],
      status: [],
      reason: [],
      updated_By: [],
      recorded_by: [],
      followUp_By: [],
      engagementType: [],
      membershipType: [],
      billingCategory: [],
      jobPositionType: [],
      industryType: [],
      companyInterestType: [],
      preferredChannelType: [],
      eNewsType: [],
      getToKnowUs: [],
      salutation: [],
      yearOfBirthType: [],
      nationalityType: [],
      institutes: [],
      studiesType: [],
      pursuingQualifiedType: [],
      yearOfCompletedType: [],
      individualMemberId: 0,
      membershipTaskList: [
        // {
        //   RegistrationDate: "7-8-2025",
        //   Name: "Nisarg Patel",
        //   EmailAddress: "mol@1234",
        //   CompanyName: "Moltech",
        //   CompanyUEN: "Super Active",
        //   TransactionType: "Kacchi Babul 120",
        //   Source: "Honda Dada",
        // },
        // {
        //   RegistrationDate: "7-8-2025",
        //   Name: "Visarg Hatela",
        //   EmailAddress: "mol@1234",
        //   CompanyName: "Zoltech",
        //   CompanyUEN: "Active",
        //   TransactionType: "Nulla Nawab 420",
        //   Source: "KTM Chapari",
        // },
      ],
      sourceList: [
        {
          id: 1,
          name: "Admin"
        },
        {
          id: 2,
          name: "Web"
        },
      ],

      // Membership Task List Process State Data
      usersList: [],
      billingCategoryList: [],
      updatedByList: [
        {
          id: 1,
          name: "Admin"
        },
        {
          id: 2,
          name: "Web"
        }
      ],
      membershipTypeList: [],
      revenueTypeList: [],
      transactionTypeList: [],
      membershipTenureList: [],
      designationList: [],
      // task List Details
      taskListDetail: {
        taskId: 0,
        companyCategoryId: 0,
        contactNo: "",
        corporateMemberBillingCategoryId: 0,
        corporateMemberRecordTypeId: 0,
        countryOfStudiesId: 0,
        designation: "",
        email: "",
        salutionId: 0,
        firstName: "",
        individualMemberId: 0,
        individualSubscriptionId: 0,
        instituteName: "",
        instituteWebsite: "",
        isSubscriptionAccountStatus: false,
        jobPositionId: 0,
        lastName: "",
        instituteId: 0,
        nationalityId: 0,
        pursuingQualificationId: 0,
        specialisation: "",
        subscriptionEndDate: "",
        subscriptionJoinDate: "",
        subscriptionLastUpdatedDate: "",
        subscriptionReasonLeaving: "",
        subscriptionRenewalNotes: "",
        updatedById: 0,
        yearOfBirth: "",
        yearOfCommencementId: 0,
        yearOfCompletionId: 0,
        membershipTypeId: 0,
        matriculationNumber: "",
        otherInstituteName: "",
        designation: "",
        jobPositionId: 0,
      },
      calculatedPaymentPrice: {
        entranceFee: 0,
        annualFee: 0,
        subtotalPrice: 0,
        gstPrice: 0,
        gstRate: 0,
        discountedPrice: 0,
        totalPrice: 0,
        netTotal: 0,
        subscriptionEndDate: ''
      },
      pricingTable: {
        row: 0,
        year: 1,
        entranceFee: "",
        annualFee: "",
        tableName: "",
        status: "",
        months: 0,
        startDate: "",
        endDate: ""
      },
      paymentsMethods: [
        {
          id: 1,
          name: "Credit Card",
        },
        {
          id: 2,
          name: "Pay Now",
        },
        {
          id: 3,
          name: "Bank Transfer",
        },
      ],

      //   All Lodaer State
      isLoadingPayment: false,
      isLoadingQuotation: false,
      isLoadingInvoice: false,
      isLoadingApprove: false,
      isEscalateLodaing: false,

      membershipPricing: [],
      educatorPricingDetail: {},
      // companyUENValidationRule: [
      //   {
      //     type: "require",
      //     message: CommonValidationMessages.FieldRequired.replace("{0}", "company UEN"),
      //   },
      //   {
      //     type: "isCompanyUEN",
      //     message: "Please enter valid company UEN"
      //   },
      // ],
      // businessRegistrationNoValidationRule: [
      //   {
      //     type: "require",
      //     message: CommonValidationMessages.FieldRequired.replace("{0}", "company UEN"),
      //   },
      // ],

      disablePaymentButton: true,
      declreMembershipReceived: false,
      isGeneratingInvoice: false,
      isProceedPayment: false,
      membersReceipt: {},
      payment: {
        membershipPaymentId: 0,
        membershipTenureId: 0,
      },
      validStateTaskListDetails: {
        isValid: true,
        error: {},
      },
    }
  }

  componentDidMount() {
    this.getAllDropdownForIndividualMember();
    // this.getAllIndividualMembershipTaskList(this.state.pagination);
  }

  // Get All Individual Dropdown Method Start
  getAllDropdownForIndividualMember = () => {
    this.individualMemberService.getAllDropdownForIndividualMember().then((response) => {

      if (response.statusCode === 200 && response.responseItem != null) {
        let gstPercentage = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "GSTPercentage"
        );
        let percentage = Number(gstPercentage[0].name);
        let status = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "AccountStatus"
        );
        status = status.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let reason = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "ReasonForLeaving"
        );
        reason = reason.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let updated_By = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "By"
        );
        updated_By = updated_By.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let recorded_by = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "By"
        );
        recorded_by = recorded_by.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let followUp_By = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "By"
        );
        followUp_By = followUp_By.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let engagement = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "EngagementType"
        );
        engagement = engagement.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let membership = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "MembershipTypes"
        );
        membership = membership.map((p) => ({
          id: p.id,
          name: p.name
        }));
        membership = membership.filter(s => s.id === 4 || s.id === 5 || s.id === 6);
        let billingCategory = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "BillingCategory"
        );
        billingCategory = billingCategory.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let jobPosition = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "JobFunction"
        );
        jobPosition = jobPosition.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let industry = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "IndustryType"
        );
        industry = industry.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let interestedType = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CompanyInterestType"
        );
        interestedType = interestedType.map((p) => ({
          value: p.id,
          label: p.name
        }));
        let preferredType = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "PreferredChannelOfCommunication"
        );
        preferredType = preferredType.map((p) => ({
          value: p.id,
          label: p.name
        }));
        let eNews = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Enewsletters"
        );
        eNews = eNews.map((p) => ({
          value: p.id,
          label: p.name
        }));
        let hearAboutUs = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "GetToKnowUs"
        );
        hearAboutUs = hearAboutUs.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let salutation = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Salutation"
        );
        salutation = salutation.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let yearOfBirth = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Years"
        );
        yearOfBirth = yearOfBirth.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let nationality = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "NationalityType"
        );
        nationality = nationality.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let studies = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Countries"
        );
        studies = studies.map((p) => ({
          id: p.id,
          name: p.name
        }));
        let pursuingQualified = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "PursuingQualifications"
        );
        pursuingQualified = pursuingQualified.map((P) => ({
          id: P.id,
          name: P.name
        }));
        let yearOfCompleted = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Years"
        );
        yearOfCompleted = yearOfCompleted.map((p) => ({
          id: p.id,
          name: p.name
        }));
        //Institutes
        let institutes = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Institutes"
        );
        institutes.map(function (item) {
          delete item["dropdownName"];
        });
        institutes = institutes.map((p) => ({
          id: p.id,
          name: p.name,
        }));

        let statusName = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "AccountStatus");
        statusName = statusName.map((p) => ({
          id: p.id,
          name: p.name,
        }));
        console.log("statusName ", statusName);
        statusName = statusName.filter(s => s.id === 2 || s.id === 13 || s.id === 12 || s.id === 15);

        this.setState({
          status: status, reason: reason, updated_By: updated_By, recorded_by: recorded_by, followUp_By: followUp_By,
          engagementType: engagement, membershipType: membership, billingCategory: billingCategory, jobPositionType: jobPosition,
          industryType: industry, companyInterestType: interestedType, preferredChannelType: preferredType, eNewsType: eNews,
          salutation: salutation, getToKnowUs: hearAboutUs, yearOfBirthType: yearOfBirth, nationalityType: nationality,
          studiesType: studies, pursuingQualifiedType: pursuingQualified, yearOfCompletedType: yearOfCompleted, institutes: institutes,
          statusList: statusName,
          gstPercentage: percentage,
        },
          () => { this.getAllIndividualMembershipTaskList(this.state.pagination); this.getMembershipPricingForIndividual(); }
        )
      }
      else {
        this.getAllIndividualMembershipTaskList(this.state.pagination)
        this.swalServices.Error(response.message);
      }
    }
    )
  }


  // API Method Declaration Section Start
  getAllIndividualMembershipTaskList = (pagination = this.state.pagination) => {
    this.state.pagination.statusId = this.state.pagination.statusId

    this.setState({ isLoading: true });
    this.individalMemberTaskListServices.getAllIndividualMembershipTaskList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let details = response.responseItem.responseContent.itemList;
        details.map((key, index) => {
          key.registrationDate = moment(key.registrationDate).format(
            "DD MMM YYYY"
          );
          if (key.individualStatusId == 2) {
            key.status = "Submitted";
          }
          if (key.membershipTypeId == 5) {
            key.membershipType = "Student";
          }
          if (key.membershipTypeId == 6) {
            key.membershipType = "Educator";
          }
        });
        this.setState({
          membershipTaskList: response.responseItem.responseContent.itemList,
          totalResultes: response.responseItem.responseContent.totalCount,
        }
        );
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  // Get Membership Task List Details by Id
  getAllIndividualInformationDetailsById = (individualMemberId) => {
    this.individalMemberTaskListServices.getAllIndividualInformationDetailsById(individualMemberId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {

        let data = this.state.taskListDetail;
        let resp = response.responseItem.responseContent;

        data.companyCategoryId = resp.companyCategoryId;
        data.contactNo = resp.contactNo;
        data.corporateMemberBillingCategoryId = resp.corporateMemberBillingCategoryId;
        data.corporateMemberRecordTypeId = resp.corporateMemberRecordTypeId;
        data.countryOfStudiesId = resp.countryOfStudiesId
        data.designation = resp.designation
        data.email = resp.email
        data.firstName = resp.firstName;
        data.individualMemberId = resp.individualMemberId;
        data.individualSubscriptionId = resp.individualSubscriptionId;
        data.instituteName = resp.instituteName;
        data.instituteWebsite = resp.instituteWebsite;
        data.isSubscriptionAccountStatus = resp.isSubscriptionAccountStatus;
        data.jobPositionId = resp.jobPositionId;
        data.lastName = resp.lastName;
        data.nationalityId = resp.nationalityId;
        data.pursuingQualificationId = resp.pursuingQualificationId;
        data.specialisation = resp.specialisation;
        data.subscriptionEndDate = resp.subscriptionEndDate;
        data.subscriptionJoinDate = resp.subscriptionJoinDate;
        data.subscriptionLastUpdatedDate = resp.subscriptionLastUpdatedDate;
        data.subscriptionReasonLeaving = resp.subscriptionReasonLeaving;
        data.subscriptionRenewalNotes = resp.subscriptionRenewalNotes;
        data.updatedById = resp.updatedById;
        data.yearOfBirth = resp.yearOfBirth;
        data.yearOfCommencementId = resp.yearOfCommencementId;
        data.yearOfCompletionId = resp.yearOfCompletionId;
        data.salutionId = resp.salutionId;
        data.membershipTypeId = resp.membershipTypeId;
        data.instituteId = resp.instituteId;
        data.matriculationNumber = resp.matriculationNumber;
        data.otherInstituteName = resp.otherInstituteName;
        data.designation = resp.designation;
        data.jobPositionId = resp.jobPositionId;
        data.taskId = resp.taskId;

        this.setState({ taskListDetail: data });
        // this.setState({ setAddEditModalOpen: true });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  // Reject Individual Member
  acceptIndividualStudentApplication = (memberId) => {
    let request = {};
    request.individualMemberId = this.state.individualMemberId;
    request.membershipTypeId = memberId;
    request.email = this.state.taskListDetail.email;
    request.subscriptionJoinDate = this.state.taskListDetail.subscriptionJoinDate;
    request.subscriptionEndDate = this.state.taskListDetail.subscriptionEndDate;
    request.isSubscriptionAccountStatus = 1;
    request.individualStatusId = 4;
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Accept_Question.replace("{0}", "Application"),
        CommonSuccessMessages.Confirm_Accept.replace("{0}", "Application"),
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          this.setState({ isAcceptLoading: true })
          this.individalMemberTaskListServices.acceptIndividualStudentApplication(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success("This application request has been accepted");
              this.setState({ setAddEditModalOpen: false });
              this.getAllIndividualMembershipTaskList();
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isAcceptLoading: false });
          });
        }
      });
  };

  // Reject Individual Member
  rejectMemberApplication = () => {
    let request = {};
    request.individualMemberId = this.state.individualMemberId;
    request.isRejected = true;
    request.rejectApplicationReason = "Other institute is not valid";
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Reject_Question.replace("{0}", "Application"),
        CommonSuccessMessages.Confirm_Reject.replace("{0}", "Application"),
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          this.setState({ isRejectLoading: true })
          this.individalMemberTaskListServices.rejectMemberApplication(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {

              this.swalServices.Success("This application request has been rejected");
              this.setState({ setAddEditModalOpen: false });
              this.getAllIndividualMembershipTaskList();
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isRejectLoading: false });
          });
        }
      });
  };


  // Method Which Called on Send Payment Link
  generatePaymentInvoiceForIndividual = (isPaymentButton = false) => {
    let request = {};
    request.IndividualMemberId = this.state.individualMemberId;
    request.taskId = this.state.taskListDetail.taskId;
    // request.taskId = 1
    //this.state.companyInfo.taskId;
    // request.finalCalculatedPriceId = 0;
    request.companyName = this.state.taskListDetail.companyName;
    request.address = this.state.taskListDetail.companyAddress;
    request.attentionTo = this.state.taskListDetail.firstName + " " + this.state.taskListDetail.lastName;
    request.poNumber = this.state.taskListDetail.poNumber ? this.state.taskListDetail.poNumber : '';
    request.customerAcNo = this.state.taskListDetail.customerAcNo;
    request.entranceFee = this.state.educatorPricingDetail.entranceFee;
    request.annualFee = this.state.educatorPricingDetail.annualFee;
    request.subTotalAmount = request.entranceFee + request.annualFee;
    //  this.state.educatorPricingDetail.entranceFee;
    let gstPrice = (request.annualFee * this.state.gstPercentage) / 100;
    request.gSTAmount = gstPrice;
    request.discountedAmount = 0;
    request.totalAmount = request.subTotalAmount + request.gSTAmount;
    request.year = 1;

    request.netTotal = request.subTotalAmount + request.gSTAmount;
    request.gSTRate = this.state.gstPercentage;
    request.membershipPaymentInvoicePath = "";
    request.creditTermName = "Credit Term Name";
    request.membershipPaymentTypeId = 1;
    request.startMembershipOn = this.state.taskListDetail.subscriptionJoinDate ? this.state.taskListDetail.subscriptionJoinDate : '';
    request.paymentVia = "Cash"
    request.membershipPaymentInvoiceNo = "9874563210";
    request.billingCategoryId = 1;
    request.subscriptionEndDate = this.state.taskListDetail.subscriptionEndDate ? this.state.taskListDetail.subscriptionEndDate : '';

    this.setState({ isGeneratingInvoice: true, isProceedPayment: true, isAcceptLoading: true });
    this.individalMemberTaskListServices.generatePaymentInvoiceForIndividual(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
        this.setState({ membersReceipt: response.responseItem.responseContent })
        this.setState({ isProceedPayment: true });
        //  this.addIndividualMembershipPaymentData(isPaymentButton);
        this.sendReconciliationPaymentLinkToIndividualEducator(request.IndividualMemberId, request.companyName, '', '', this.state.email, this.state.membershipTypeId);
      }
      else {
        this.swalService.Error(response.message);
        this.setState({ isGeneratingInvoice: false, isAcceptLoading: false });
      }
      this.setState({ isLoading: false });
    })
    this.setState({ isGeneratingInvoice: false, isProceedPayment: false });
    // } else {
    //   this.swalService.Warning("Please accept terms and condition")
    // }
  }



  // Get Membership Pricing For Individual
  getMembershipPricingForIndividual = (membershiptype) => {
    this.individalMemberTaskListServices.getMembershipPricingForIndividual().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let membershipPricing = response.responseItem.responseContent;
        this.setState(
          {
            membershipPricing: membershipPricing,
            educatorPricingDetail: membershipPricing[2],
          },
        );
      }
      else {
        this.swalServices.Error(response.message);
      }
    });
  };

  // Send Payment Link for individaul Educator
  sendReconciliationPaymentLinkToIndividualEducator = (idividualId) => {
    let request = { ...this.state.membersReceipt };
    request.individualMemberId = idividualId;
    request.companyName = this.state.taskListDetail.instituteName === 'Others, please specify' ? this.state.taskListDetail.otherInstituteName : this.state.taskListDetail.instituteName;
    request.firstName = this.state.taskListDetail.firstName;
    request.lastName = this.state.taskListDetail.lastName;
    request.email = this.state.taskListDetail.email;
    request.membershipTypeId = this.state.taskListDetail.membershipTypeId;
    this.setState({ isAcceptLoading: true });
    this.individalMemberTaskListServices.sendReconciliationPaymentLinkToIndividualEducator(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices.Success("Payment link send successfully!");
        this.setState({ setAddEditModalOpen: false });
        this.getAllIndividualMembershipTaskList();
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isAcceptLoading: false });
    });
  };


  //   // Validation Method Declaration Section
  //   validateField = (key) => {
  //     let validationRule = { ...this.state.TaskListDetailsValidationRules };
  //     if (this.state.taskListDetail.companyTypeId === 1) {
  //       validationRule.companyUEN = this.state.companyUENValidationRule;
  //     }
  //     else if (this.state.taskListDetail.companyTypeId === 2) {
  //       validationRule.businessRegistrationNo = this.state.businessRegistrationNoValidationRule;
  //     }
  //     const returnValidState = validate(
  //       key,
  //       this.state.taskListDetail,
  //       validationRule,
  //       this.state.validStateTaskListDetails);
  //     this.setState({ validStateTaskListDetails: returnValidState });
  //   }
  //   isValidateAllFields = () => {
  //     let validationRule = { ...this.state.TaskListDetailsValidationRules };
  //     if (this.state.taskListDetail.companyTypeId === 1) {
  //       validationRule.companyUEN = this.state.companyUENValidationRule;
  //     }
  //     else if (this.state.taskListDetail.companyTypeId === 2) {
  //       validationRule.businessRegistrationNo = this.state.businessRegistrationNoValidationRule;
  //     }
  //     const newValidState = isValidForm(
  //       this.state.taskListDetail,
  //       validationRule,
  //       this.state.validStateTaskListDetails);
  //     this.setState({ validStateTaskListDetails: newValidState });
  //     return newValidState.isValid;
  //   };


  //   // Set Pagination For Grid Method
  //   setPagination = (newPagination) => {
  //       this.setState({ pagination: newPagination });
  //       this.getAllIndividualMembershipTaskList(newPagination);
  //   };
  // }

  // Search By Name Filter Method

  // 
  // Handle All Type Input Method
  handleChange(event, identity) {
    const value = event.target.value;
    const name = event.target.name;

    let detail1 = this.state.pagination;
    let detail2 = this.state.taskListDetail;

    if (identity === "Filter_By_Date") {
      let fulldate = (detail1[name] = value === "" ? null : value);
      let date = new Date(fulldate); //1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM
      let startLimit = new Date("1/1/1753 12:00:00 AM");
      let endLimit = new Date("12/31/9999 11:59:59 PM");
      detail1[name] = value;
      this.setState({ pagination: { ...detail1 } });
      this.setState({ pagination: { ...detail1 } }, () => {
        if (startLimit < date && date < endLimit) {
          this.getAllIndividualMembershipTaskList();
        } else if (fulldate === null) {
          this.getAllIndividualMembershipTaskList();
        }
      });
    }
    else if (identity === "Task_List_Detail") {
      detail2[name] = value;
      this.setState({ taskListDetail: detail2 });
    }
  }

  // All Dropdown Handle Method
  setFilterParameters = (id, drpIdentity) => {
    let detail1 = this.state.pagination;
    let detail2 = this.state.taskListDetail;

    if (drpIdentity === "Status_Name") {
      detail1["statusId"] = id;
      //this.validateField("resourceTypeId");
    }
    else if (drpIdentity === "Task_List_Salutation") {
      detail2["salutionId"] = id;
    }
    else if (drpIdentity === "Nationality_Type") {
      detail2["nationalityId"] = id;
    }
    else if (drpIdentity === "Select_Institute") {
      detail2["membershipTypeId"] = id;
      // this.validateField("membershipTypeId");
    }
    else if (drpIdentity === "Select_CountryOfStudies") {
      detail2["countryOfStudiesId"] = id;
      // this.validateField("countryOfStudiesId");
    }
    else if (drpIdentity === "Select_Pursuing_Qualification") {
      detail2["pursuingQualificationId"] = id;
      // this.validateField("pursuingQualificationId");
    }
    else if (drpIdentity === "Year_Of_Commencement") {
      detail2["yearOfCommencementId"] = id;
      // this.validateField("yearOfCommencementId");
    }
    else if (drpIdentity === "Year_Of_Completion") {
      detail2["yearOfCompletionId"] = id;
      // this.validateField("yearOfCompletionId");
    }
    else if (drpIdentity === "Membership_Type") {
      detail2["membershipTypeId"] = id;
      // this.validateField("membershipTypeId");
    }
    else if (drpIdentity === "Select_Job_Position") {
      detail2["jobPositionId"] = id;
      // this.validateField("jobPositionId");
    }

    this.setState({
      pagination: { ...detail1 },
      taskListDetail: { ...detail2 }
    });
    this.getAllIndividualMembershipTaskList();
  };


  // Serach Individual by Name Method
  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.searchText = "";
    this.setState({ pagination: { ...detail } });
    if (value.length > 2) {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getAllIndividualMembershipTaskList();
  };

  // actions = (element, index, value) => {
  //   return element !== "individualMemberId" ? null : (
  //     <td className="whitespace-nowrap tble-td-txt break-all text-left">
  //       <button
  //         className="flex items-center justify-center"
  //         onClick={(event) => this.selectTask(event, index, value)
  //         }
  //       >
  //         <span className="text-[#8DA4B5] font-bold underline ml-5 2xl:text-[15px]">
  //           View
  //         </span>
  //       </button>
  //     </td >
  //   );
  // };

  actions = (element, index, value) => {
    return element !== "individualMemberId" ? null : (
      <td>
        <ActionContainer>
          <DropdownAction
            key={index}
            index={index}
            value={value}
            options={this.state.actionDropdown}
            align="right"
            onOptionClick={this.actionClick.bind(this)}
          />
        </ActionContainer>
      </td>
    );
  };

  actionClick = (index, value, option) => {
    if (value && value > 0 && option === "View") {
      this.selectTask(null, index, value);
    }
    if (value && value > 0 && option === "Delete") {
      console.log("delete membership task")
      let taskId = this.state.membershipTaskList[index].individualMemberId;
      this.deleteIndividualMemberFromTaskList(taskId)
    }
  };

  // Select Task on Action Click
  selectTask = (event, index, value) => {
    let individualId = this.state.membershipTaskList[index].individualMemberId;
    this.setState({ setAddEditModalOpen: true, individualMemberId: individualId },
      this.getAllIndividualInformationDetailsById(individualId)
    );
  }

  // Set Pagination For Grid Method
  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getAllIndividualMembershipTaskList(newPagination);
  };

  navigateToViewIndividualMember = () => {
    let individualMemberId = this.state.taskListDetail.individualMemberId;
    if (individualMemberId && individualMemberId > 0) {
      this.setState({
        route:
          '/CreateIndividualMembers/individualMemberId?=' + encryptAES(individualMemberId) + "ViewIndi"
      });
      window.open('/CreateIndividualMembers/individualMemberId?=' + encryptAES(individualMemberId) + "ViewIndi", '_blank');
    }
  }


  deleteIndividualMemberFromTaskList = (value) => {
    // let request = {
    //   id: value,
    //   deletedBy: 1,
    //   appId: 115,
    // };

    let reqParam = [value, false];
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_Question.replace("{0}", "Member"),
        CommonSuccessMessages.Confirm_Delete.replace("{0}", "Member"),
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          this.individalMemberTaskListServices.deleteTaskListDetailsByTaskId(reqParam)
            .then((response) => {
              if (response.statusCode === 200) {
                this.swalServices.Success("Deleted");
                this.getAllIndividualMembershipTaskList();
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoading: false });
            });
        }
      });
  };

  render() {
    // if (this.state.route != null) {
    //   return <Navigate to={this.state.route} />;
    // }
    return (
      <div>
        <div className="main-body-section">
          <div className="bg-white pl-8 pt-10 pb-12">
            <div className="grid grid-cols-12 gap-6 w-full items-center">
              <div className="2xl:col-span-6 lg:col-span-6 col-span-12 pr-7">
                <h2 className="text-xl theme-color">Date</h2>
                <div className="grid grid-cols-12 gap-6 items-center w-full">
                  <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <DateInput
                      name="dateFrom"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      value={moment(this.state.pagination.dateFrom).format("YYYY-MM-DD")}
                      handleChange={(e) => this.handleChange(e, "Filter_By_Date")}
                    />
                  </div>
                  <div className=" 2xl:col-span-2 lg:col-span-2 col-span-12 w-full text-center">
                    <h2 className="text-xl theme-color">To</h2>
                  </div>
                  <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <DateInput
                      name="dateTo"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      value={moment(this.state.pagination.dateTo).format("YYYY-MM-DD")}
                      handleChange={(e) => this.handleChange(e, "Filter_By_Date")}
                    />
                  </div>
                </div>
              </div>
              <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                <h2 className="pl-12 block text-xl font-medium  theme-color">
                  Status
                </h2>
                <div className="pl-12 border-l-[1px] border-[#181818]">
                  <DropdownSelect
                    drpIdentity={"Status_Name"}
                    optionArray={this.state.statusList}
                    setFilterParameters={this.setFilterParameters.bind(this)}
                    value={this.state.pagination.statusId}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-4 pt-10">
            <h2 className="col-start-1 col-end-3 text-3xl font-bold text-[#181818]">
              {`Total:${this.state.totalResultes}`} </h2>
            <div className="col-end-7 col-span-3 grid grid-cols-3 items-center">
              <caption className="text-[#181818] text-xl text-right pr-5">Search by name </caption>
              <input
                id="default"
                className="col-span-2 text-xl font-bold h-[55px] w-full text-[#181818] border-white"
                type="text"
                onChange={(e) => this.searchByName(e.target.value)}
              />
            </div>
          </div>
          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div>
                  <div className=" mt-6">
                    <Table
                      columns={[
                        { name: "registrationDate", title: "Registration Date" },
                        { name: "fullName", title: "Name" },
                        { name: "email", title: "Email Address" },
                        { name: "otherInstituteName", title: "Institution Name" },
                        { name: "membershipType", title: "Membership Type" },
                        { name: "status", title: "Status" },
                        { name: "individualMemberId", title: "Action" },
                      ]}
                      rows={this.state.membershipTaskList}
                      isLoading={false}
                      sortingColumns={["Source", "fullName", "email", "otherInstituteName", "membershipType", "status"]}
                      pagination={this.state.pagination}
                      totalResultes={this.state.totalResultes}
                      totalCount={this.state.totalResultes}
                      setPagination={this.setPagination.bind(this)}
                      customScope={[
                        {
                          column: "individualMemberId",
                          renderTableData: this.actions.bind(this),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div className="member-task-process">
          <MembershipTaskListProcess
            // Pass the Data
            setAddEditModalOpen={this.state.setAddEditModalOpen}
            taskListDetail={this.state.taskListDetail}
            salutation={this.state.salutation}
            nationalityType={this.state.nationalityType}
            institutes={this.state.institutes}
            studiesType={this.state.studiesType}
            pursuingQualifiedType={this.state.pursuingQualifiedType}
            yearOfCompletedType={this.state.yearOfCompletedType}
            membershipType={this.state.membershipType}
            jobPositionType={this.state.jobPositionType}
            isRejectLoading={this.state.isRejectLoading}
            isAcceptLoading={this.state.isAcceptLoading}
            membershipPricing={this.state.membershipPricing}
            educatorPricingDetail={this.state.educatorPricingDetail}
            // Passed Methods
            setModalOpen={() => this.setState({ setAddEditModalOpen: false })}
            handleChange={this.handleChange.bind(this)}
            setFilterParameters={this.setFilterParameters.bind(this)}
            rejectMemberApplication={this.rejectMemberApplication.bind(this)}
            acceptIndividualStudentApplication={this.acceptIndividualStudentApplication.bind(this)}
            navigateToViewIndividualMember={this.navigateToViewIndividualMember.bind(this)}
            generatePaymentInvoiceForIndividual={this.generatePaymentInvoiceForIndividual.bind(this)}
            sendReconciliationPaymentLinkToIndividualEducator={this.sendReconciliationPaymentLinkToIndividualEducator.bind(this)}
          // addEditMembershipTask={this.addEditMembershipTask.bind(this)}
          // disablePaymentButton={this.state.disablePaymentButton}
          // handleChangeInPriceTable={this.handleChangeInPriceTable.bind(this)}
          // addEditMembershipTaskAndSendPaymentLink={this.addEditMembershipTaskAndSendPaymentLink.bind(this)}
          />
        </div>
      </div>
    )
  }
}