import React from "react";

const SectionWithBorder = (props) => {
  return (
    <div className="border border-b-0 pb-3 border-[#181818] border-solid border-section-main">
      <div
        className={`grid 2xl:grid-cols-3 lg:grid-cols-3 gap-0 h-[55px] SectionWithBorder-bg w-full items-center`}
      >
        <h2 className="text-size-18 text-[#181818] pl-8">{props.column1}</h2>
        <h2 className="text-size-18 text-[#181818] margin-left-12-res">{props.column2}</h2>
        <h2 className="text-size-18 text-[#181818] margin-left-59">{props.column3}</h2>
      </div>
      {props.children}
    </div>
  );
};
export default SectionWithBorder;
