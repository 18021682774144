import React, { useState, useRef, useEffect } from 'react';
import Transition from '../../utils/Transition';

export default function DropdownAction(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  const actionClick = (index, value, option, event) => {
    if (props) {
      props.onOptionClick(index, value, option, event);
      setDropdownOpen(false);
    }
  }

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className={`custom-action-btn rounded btn bg-white border-slate-200 hover:border-slate-300 theme-color px-2 py-2 2xl:h-[40px] 2xl:w-[40px] lg:h-[30px] lg:w-[30px] h-[30px] w-[30px] hover:text-[#AA3361] mr-2 ${props.disabled && props.disabled === true ? 'cursor-not-allowed' : ''}`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
        disabled={props.disabled && props.disabled === true ? true : false}
      >
        <span className="sr-only">Filter</span><wbr />
        <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16px" height="20px" viewBox="0 0 52 52" enableBackground="new 0 0 52 52" >
          <path d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z" />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`origin-top-right z-10 absolute top-[25px] min-w-50 bg-white border border-slate-200 rounded shadow-lg overflow-hidden mt-1 mr-2 ${props.align === 'right' ? 'right-0' : 'left-[-10px]'}`}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div ref={dropdown} >
          <>
            <ul className="">
              {props && props.options ?
                props.options.map((option,key) => {
                  return (
                    <li className="py-1 px-3" key={key}>
                      <label className="flex items-center" onClick={(event) => actionClick(props.index, props.value, option, event)}>
                        <span className="text-sm font-medium ml-2 theme-color hover:text-[#AA3361]">{option}</span>
                      </label>
                    </li>
                  )
                })
                :
                null
              }
            </ul>
          </>
        </div>
      </Transition>
    </div>
  );
}
