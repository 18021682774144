import React, { useState } from "react";
import TextInput from "../UIComponent/TextInput";
import { DeleteButtonIcon } from "../SVGConstants";
import ValidationText from "../../utils/validation/ValidationText";
import PageLoader from "../../components/common/pageLoader/PageLoader"
//import { Value } from "sass";

export default function EditDropdownTable(props) {
  const [dropdownId, setDropdownId] = useState(0);
  const [valueOrdering, setValueOrdering] = useState(0);

  return (
    <div className="w-full flex justify-center">
      {props.isLoading ? < PageLoader /> :
        <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200 ">
          {/* Table header */}
          <thead className="table-bg text-xs font-semibold capitalize text-[#181818] border-t border-b border-slate-200 ">
            <tr>
              <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#181818]">
                <div className="font-bold tble-th-txt text-left">
                  Dropdown Name
                </div>
              </th>
              <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#181818] w-[15%]">
                <div className="font-bold tble-th-txt text-center flex items-center justify-center">
                  Value Order
                </div>
              </th>
              <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white border border-[#181818] w-[15%]">
                <div className="font-bold tble-th-txt text-left flex items-center justify-center">
                  Actions
                </div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="text-sm custom-tbody">
            {(props?.dropdownListById &&
              props.dropdownListById.length === 0) ||
              props.dropdownListById.length === null ? (
              <tr className="">
                <td
                  className="w-full text-lg text-center py-4 text-[#181818] "
                  colSpan="100%"
                >
                  No Records Found
                </td>
              </tr>
            ) : (
              props.dropdownListById.map((dropdown, key) => (
                <tr key={key}>
                  <td className="px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#181818] border border-[#181818]">
                    <div className="flex flex-col">
                      {props &&
                        props.addEditDropdown === true &&
                        dropdownId === dropdown.value ? (
                        <div key={key} className="flex flex-col relative">
                          <div className={`edit-field input-height-set`}>
                            <TextInput
                              placeholder=""
                              type="text"
                              name="name"
                              value={props.dropdownDetails.name}
                              handleChange={props.handleChange}
                              //onBlur={() => props.validateField("name")}
                              identity="DropdownName"
                            />
                          </div>
                          <div className="absolute bottom-[-22px]">
                            <ValidationText error={props.validState.error.name} />
                          </div>
                        </div>
                      ) : (
                        <div className="current-text">{dropdown.label}</div>
                      )}
                    </div>
                  </td>
                  <td className="px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#181818] border border-[#181818]">
                    <div className="flex flex-col">
                      {props &&
                        props.addEditDropdown === true &&
                        valueOrdering === dropdown.valueOrdering &&
                        dropdownId === dropdown.value
                        ? (
                          <div key={key} className="flex flex-col relative">
                            <div
                              className={`edit-field input-height-set center-text`}
                            >
                              <TextInput
                                placeholder=""
                                type="number"
                                name="valueOrdering"
                                max={props.length - 1}
                                value={props.dropdownDetails.valueOrdering}

                                handleChange={props.handleChange}
                                //onBlur={() => props.validateField("valueOrdering")}
                                identity="DropdownValueOrdering"
                                min={0}
                              />
                            </div>
                            <div className="absolute bottom-[-22px]">
                              <ValidationText
                                error={props.validState.error.valueOrdering}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="current-text text-center">
                            {dropdown.valueOrdering}
                          </div>
                        )}
                    </div>
                  </td>
                  <td className="px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#181818] border border-[#181818]">
                    {props &&
                      props.addEditDropdown === true &&
                      dropdownId === dropdown.value ? (
                      <div className="flex items-center justify-center edit-field">
                        <button
                          className="action-btn mr-4 flex items-center"
                          aria-controls="add-edit-modal"
                          onClick={() => {
                            props.addEditDropdownData();
                          }}
                        >
                          <p className="ml-2 tool-tip-txt text-[#AA3361] underline cursor-pointer">
                            Save
                          </p>
                        </button>
                        <button
                          className="action-btn mr-4 flex items-center"
                          aria-controls="add-edit-modal"
                          onClick={() => {
                            props.cancelAddEditDropdown();
                          }}
                        >
                          <img
                            src="/cancel-icon.png"
                            className="w-[22px] mx-auto my-0"
                            alt=""
                          ></img>
                        </button>
                      </div>
                    ) : (
                      <div className="flex items-center justify-center">
                        <button
                          className="action-btn mr-4 flex items-center"
                          aria-controls="add-edit-modal"
                          onClick={() => {
                            props.getTableDataById(
                              props.selectedDropdownId,
                              dropdown.value
                            );
                            setDropdownId(dropdown.value);
                            setValueOrdering(dropdown.valueOrdering);

                          }}
                        >
                          <p className="ml-2 tool-tip-txt underline text-[#181818]">
                            Edit
                          </p>
                        </button>
                        <button
                          className="action-btn right-icon"
                          onClick={() => props.deleteDropdownData(dropdown.value)}
                        >
                          <DeleteButtonIcon />
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            )}
            {props && props.addNewDropdown === true ? (
              <tr
                key="new-agency-key"
                ref={props?.refProp ? props.refProp : props.refProp}
              >
                <td className="px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#181818] border border-[#181818]">
                  <div className="flex flex-col">
                    <div className={`edit-field input-height-set`}>
                      <TextInput
                        placeholder=""
                        type="text"
                        name="name"
                        // maxLength={50}
                        value={props.dropdownDetails.name}
                        handleChange={props.handleChange}
                        //onBlur={() => props.validateField("name")}
                        identity="DropdownName"
                      />
                    </div>
                    <div>
                      <ValidationText error={props.validState.error.name} />
                    </div>
                  </div>
                </td>
                <td className="px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#181818] border border-[#181818]">
                  <div className="flex flex-col">
                    <div className={`edit-field input-height-set center-text`}>
                      <TextInput
                        placeholder=""
                        type="number"
                        name="valueOrdering"
                        //max={50}
                        value={
                          props.dropdownDetails.valueOrdering === 0
                            ? props.length
                            : props.dropdownDetails.valueOrdering
                        }
                        handleChange={props.handleChange}
                        //onBlur={() => props.validateField("valueOrdering")}
                        identity="DropdownValueOrdering"
                        min={0}
                        disabled={true}
                      />
                    </div>
                    <div>
                      <ValidationText
                        error={props.validState.error.valueOrdering}
                      />
                    </div>
                  </div>
                </td>
                <td className="px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#181818] border border-[#181818]">
                  <div className="flex items-center justify-center edit-field">
                    <button
                      className="action-btn mr-4 flex items-center"
                      aria-controls="add-edit-modal"
                      onClick={() => props.addEditDropdownData()}
                    >
                      <p className="ml-2 tool-tip-txt text-[#AA3361] underline cursor-pointer">
                        Save
                      </p>
                    </button>
                    <button
                      className="action-btn mr-4 flex items-center"
                      aria-controls="add-edit-modal"
                      onClick={() => {
                        props.cancelAddEditDropdown();
                      }}
                    >
                      <img
                        src="/cancel-icon.png"
                        className="w-[22px] mx-auto my-0"
                        alt=""
                      ></img>
                    </button>
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      }
    </div>
  );
}