import React, { Component } from 'react'
import BookingServices from '../../services/axiosServices/apiServices/BookingServices';
import moment from "moment";
import { Navigate } from 'react-router-dom';
import { checkParameters } from '../../utils/Encryption';
import { formatPrice } from '../../components/common/FormatePrice';

export default class NewBookingCancellationDetail extends Component {
    constructor(props) {
        super(props);
        this.bookingServices = new BookingServices();
        this.state = {
            bookingCancellationDetails: {
                cancellationId: 0,
                className: "",
                classDate: "",
                eventFormStartDate: "",
                eventFormEndDate: "",
                bookingSessionId: 0,
                reason: "",
                bookingReferenceNo: "",
                bookingStatus: "",
                attendeeName: "",
                contactNo: "",
                bookingDate: "",
                paymentVia: "",
                promoCode: "",
                subTotalPrice: "",
                discountPrice: "",
                totalPrice: "",
                location: ""
            },
            route: ""
        };
    }


    getBookingCancellationDetailsById = (id) => {
        this.bookingServices.getBookingCancellationDetailsById(id).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                response.responseItem.responseContent.bookingDate = moment(response.responseItem.responseContent.bookingDate).format("DD MMM YYYY")
                response.responseItem.responseContent.classDate = moment(response.responseItem.responseContent.classDate).format("DD MMM YYYY")
                // response.responseItem.responseContent.eventFormStartDate = moment(response.responseItem.responseContent.eventFormStartDate).format("DD MMM YYYY")
                // response.responseItem.responseContent.eventFormEndDate = moment(response.responseItem.responseContent.eventFormEndDate).format("DD MMM YYYY")
                this.setState({ bookingCancellationDetails: response.responseItem.responseContent });
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let CancellationId = params.get("");
        if (CancellationId && (CancellationId = checkParameters(CancellationId, "Number"))) {
            if (CancellationId != null && CancellationId > 0) {
                this.getBookingCancellationDetailsById(CancellationId);
            }
        }

    }

    render() {
        if (this.state.route != null && this.state.route !== "") {
            return <Navigate to={this.state.route} />;
        }
        return (
            <div>
                <div className="main-body-section">
                    <div className="bg-white">
                        <div className=" heading-part px-7 py-4 border-b">
                            <h4 className="small-title font-bold theme-color">
                                Booking Details
                            </h4>
                        </div>

                        <div className='mb-2 overflow-x-auto  px-7 py-4'>
                            <table className="partner-tbl w-full mx-auto my-5 fixed_header border-2">
                                <thead >
                                    <tr className="text-base font-bold flex">
                                        <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[20%]">
                                            <div className="font-bold tble-th-txt text-left">Class Name</div>
                                        </th>
                                        <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[20%]">
                                            <div className="font-bold tble-th-txt text-center ">Class Date</div>
                                        </th>
                                        <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[20%]">
                                            <div className="font-bold tble-th-txt text-center">Time</div>
                                        </th>
                                        <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[20%]">
                                            <div className="font-bold tble-th-txt text-center">Session</div>
                                        </th>
                                        <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[20%]">
                                            <div className="font-bold tble-th-txt text-center">Location</div>
                                        </th>

                                    </tr>
                                </thead>
                                {/* {this.state.bookingCancellationDetails.map((item) => ( */}
                                <tbody className="scrollit text-sm custom-tbody ">

                                    <tr className='custom-tbl-td flex'>
                                        <td className=" px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                            {this.state.bookingCancellationDetails.className}
                                        </td>
                                        <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                            <div className="dollar-sign-icon inline-block">
                                                {this.state.bookingCancellationDetails.classDate}

                                            </div>
                                        </td>
                                        <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                            <div className="dollar-sign-icon inline-block">
                                                {this.state.bookingCancellationDetails.eventFormStartDate} -
                                                {this.state.bookingCancellationDetails.eventFormEndDate}
                                            </div>
                                        </td>
                                        <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                            {this.state.bookingCancellationDetails.bookingSessionId}
                                        </td>
                                        <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                            {/* need to check this data */}
                                            {this.state.bookingCancellationDetails.location ?
                                                this.state.bookingCancellationDetails.location
                                                : "N.A"}
                                        </td>

                                    </tr>

                                    {/* <tr className="grid justify-items-center">
      <td className="text-lg text-[#757575]	">No Records Found</td>
    </tr>  */}
                                </tbody>
                                {/* ))} */}

                            </table>
                        </div>
                        <div className=" heading-part px-7 py-4 ">
                            <h4 className="small-title font-bold theme-color">
                                Cancellation Details
                            </h4>
                        </div>
                        <div className=' px-7 py-4 '>

                            <div className="grid grid-cols-12 gap-6 w-full">
                                <div className="xl:col-span-6 lg:col-span-6 col-span-12  border">
                                    <div className="flex items-center ">
                                        <h2 className="text-xl theme-color w-[50%] ml-4 py-2 ">
                                            Reason
                                        </h2>

                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">
                                            {this.state.bookingCancellationDetails.reason}
                                        </h2>

                                    </div>


                                </div>
                            </div>

                        </div>
                        <div className=" heading-part px-7 py-4 ">
                            <h4 className="small-title font-bold theme-color ">
                                Booking Details
                            </h4>
                        </div>
                        <div className=' px-7 py-4 '>

                            <div className="grid grid-cols-12 gap-6 w-full">
                                <div className="xl:col-span-6 lg:col-span-6 col-span-12  border">
                                    <div className="flex items-center ">
                                        <h2 className="text-xl theme-color ml-4 py-2  w-[50%]">

                                            Booking Ref. No
                                        </h2>

                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">
                                            {this.state.bookingCancellationDetails.bookingReferenceNo}
                                        </h2>
                                    </div>
                                    <div className="flex items-center">
                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">
                                            Status
                                        </h2>

                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">

                                            {this.state.bookingCancellationDetails.bookingStatus}
                                        </h2>
                                    </div>
                                    <div className="flex items-center">
                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">
                                            Attendee
                                        </h2>

                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">

                                            {this.state.bookingCancellationDetails.attendeeName}
                                        </h2>
                                    </div>
                                    <div className="flex items-center">
                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">
                                            Contact No
                                        </h2>

                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">

                                            {this.state.bookingCancellationDetails.contactNo}
                                        </h2>
                                    </div>
                                    <div className="flex items-center">
                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">
                                            Booking Date
                                        </h2>

                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">

                                            {this.state.bookingCancellationDetails.bookingDate}
                                        </h2>
                                    </div>
                                    <div className="flex items-center">
                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">
                                            Payment Type
                                        </h2>

                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">

                                            {this.state.bookingCancellationDetails.paymentVia}
                                        </h2>
                                    </div>
                                </div>

                                <div className="xl:col-span-6 lg:col-span-6 col-span-12  border">
                                    <div className="flex items-center ">
                                        <h2 className="text-xl theme-color ml-4 py-2  w-[50%]">
                                            Promo Code
                                        </h2>

                                        <h2 className="text-xl theme-color  ml-4 w-[50%]  py-2">
                                            {this.state.bookingCancellationDetails.promoCode ?
                                                this.state.bookingCancellationDetails.promoCode : "N.A"}
                                        </h2>
                                    </div>
                                    <div className="flex items-center">
                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">
                                            Discount Price
                                        </h2>

                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]" >
                                            {/* {this.state.bookingCancellationDetails.discountPrice} */}
                                            {formatPrice(this.state.bookingCancellationDetails.discountPrice)}
                                        </h2>
                                    </div>
                                    <div className="flex items-center">
                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">
                                            Original Price
                                        </h2>

                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">

                                            HKD {formatPrice(this.state.bookingCancellationDetails.subTotalPrice)}
                                            {/* {this.state.bookingCancellationDetails.subTotalPrice} */}
                                        </h2>
                                    </div>
                                    <div className="flex items-center">
                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">
                                            Paid Price
                                        </h2>

                                        <h2 className="text-xl theme-color ml-4 py-2 w-[50%]">

                                            HKD {formatPrice(this.state.bookingCancellationDetails.totalPrice)}
                                            {/* {this.state.bookingCancellationDetails.totalPrice} */}
                                        </h2>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                            <div className="flex flex-wrap justify-between space-x-10 mt-12">
                                <div>
                                    {/* <NavLink to="/ViewBookingCancellation "> */}
                                    <button
                                        className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white"
                                        onClick={() => {
                                            this.setState({
                                                route: "/booking/ViewBookingCancellation",
                                            });
                                        }}>
                                        Back
                                    </button>
                                    {/* </NavLink> */}
                                </div>
                                <div>
                                    {/* {props.resource.isPage ? ( */}
                                    <button
                                        className="w-32 px-7  mr-12 py-2 btn-sm btn-lighblue text-lg text-white"
                                    // onClick={() => {
                                    //   props.addEditEntryDetails(packageList, 1);
                                    //  scrollTo1();
                                    //   // props.executeScroll();
                                    // }}

                                    >
                                        Accept
                                    </button>

                                    <button
                                        className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"

                                    >
                                        Reject
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        )

    }
}
