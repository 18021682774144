import React, { Component } from "react";
import EditDropdownTable from "../../components/fieldManagement/EditDropdownTable";
import Selector from "../../components/dropdown/commonDropdown/Selector";
import { isValidForm } from "../../utils/validation/CommonValidator";
import {
  CommonSuccessMessages,
  CommonValidationMessages,
  ErrorMessage,
} from "../../utils/Messages";

//import API services
import FieldManagementServices from "../../services/axiosServices/apiServices/FieldManagementServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { getAuthProps } from "../../utils/AuthenticationLibrary";

export default class DropdownManagement extends Component {
  constructor(props) {
    super(props);
    this.FieldManagementServices = new FieldManagementServices();
    this.SwalServices = new SwalServices();
    this.myRef = React.createRef();
    this.state = {
      addEditDropdown: false,
      addNewDropdown: false,
      isLoading: false,
      setShowBottom: false,
      dropdownList: [],
      dropdownListById: [],
      selectedTableList: [],
      selectedDropdownId: 0,
      selectedTableDataId: 0,
      length: 0,
      dropdownDetails: {
        dropdownId: 0,
        tabelDataId: 0,
        name: "",
        valueOrdering: 0,
      },
      validationRule: {
        name: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "dropdown name"
            ),
          },
        ],
        valueOrdering: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "order value"
            ),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
      createdBy: 0,
    };
  }

  // Method declaration section start
  componentDidMount() {
    let cookie = getAuthProps();
    const adminUserId = cookie?.adminUserId ? Number(cookie.adminUserId) : 0;
    if (adminUserId > 0) {
      this.setState({ createdBy: adminUserId });
    }
    this.getDropdownRecordList();
  }

  // API method declaration start

  // Add Edit selected dropdown data method
  addEditDropdownData = () => {
    let data = this.state.dropdownDetails;
    data.dropdownId = this.state.selectedDropdownId;
    this.setState({ dropdownDetails: data });
    let request = this.state.dropdownDetails;
    if (request.tabelDataId === 0) {
      request.valueOrdering = this.state.length;
    } else {
      let data2 = this.state.dropdownDetails;
      data2.valueOrdering = data2.valueOrdering ? data2.valueOrdering : 0;
      this.setState({ dropdownDetails: data2 });
      //this.state.selectedDropdownId.valueOrdering = 0;
      //this.isValidateAllFields("valueOrdering")
    }
    let isValidInput = this.isValidateAllFields();
    if (isValidInput) {
      request.createdBy = this.state.createdBy;
      request.createdAppId = 114;
      this.FieldManagementServices.addEditDropdownData(request).then(
        (response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.errorColumn === "Name") {
              this.SwalServices.Error("Dropdown value already exist.");
              // } else if(response.responseItem.responseContent.errorColumn === "ValueOrdering"){
              //   this.SwalServices.Error("dropdown order value already exist.");
            } else {
              this.SwalServices.Success("Dropdown details has been added sucessfully.");
              this.afterAddEditDropdown();
            }
          } else {
            this.SwalServices.Error(ErrorMessage.DefaultMessage);
          }
          this.getDropdownDataById(this.state.selectedDropdownId);
        }
      );
    }
  };

  //Get all agency name in dropdown
  getDropdownRecordList = () => {
    let request = this.state.dropdownDetails;
    request.dropdownId = 115;
    this.FieldManagementServices.getDropdownRecordList().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let newDropdownList = response.responseItem.responseContent;
        newDropdownList = newDropdownList.map((p) => ({
          value: p.id,
          label: p.name,
        }));
        this.setState({ dropdownList: newDropdownList });
      } else {
        this.SwalServices.Error(response.message);
      }
    });
  };

  //Get dropdown content list by dropdown ID
  getDropdownDataById = (id) => {
    this.setState({ isLoading: true });
    this.FieldManagementServices.getDropdownDataById(id).then((response) => {
    

      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({
          length: response.responseItem.responseContent.length + 1,
        });
        let newDropdownDataById = response.responseItem.responseContent;
        newDropdownDataById = newDropdownDataById.map((p) => ({
          value: p.id,
          label: p.name,
          valueOrdering: p.valueOrdering,
        }));
        this.setState({ dropdownListById: newDropdownDataById });
      } else {
        this.SwalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  //Get dropdown content by ID
  getTableDataById = async (dropdownId, tableDataId) => {
    this.setState({ selectedTableDataId: tableDataId });
    let reqParam = [dropdownId, tableDataId];
    this.resetDropdownData();
    this.resetValidation();
    this.setState({ addEditDropdown: true });
    this.setState({ addNewDropdown: false });
    this.FieldManagementServices.getTableDataById(reqParam).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let dropId = response.responseItem.responseContent;
        dropId.tabelDataId = response.responseItem.responseContent.id;
        dropId.dropdownId = this.state.selectedDropdownId;
        dropId.name = response.responseItem.responseContent.name;
        this.setState({ dropdownDetails: dropId });
      } else {
        this.SwalServices.Error(response.message);
      }
    });
  };

  //Delete dropdown method
  deleteDropdownData = (value) => {
    let request = {
      id: this.state.selectedDropdownId,
      tabelDataId: value,
      deletedBy: this.state.createdBy,
      appId: 114,
    };
    this.SwalServices.Confirm(
      CommonSuccessMessages.Confirm_Question.replace("{0}", "dropdown value"),
      CommonSuccessMessages.Confirm_Delete.replace("{0}", "dropdown value"),
      CommonSuccessMessages.Yes_Text,
      CommonSuccessMessages.No_Text
    ).then((response) => {
      if (response) {
        this.FieldManagementServices.deleteDropdownData(request).then(
          (response) => {
            if (response.statusCode === 200) {
              this.SwalServices.Success("Deleted");
              this.getDropdownDataById(this.state.selectedDropdownId);
            } else {
              this.SwalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          }
        );
      }
    });
  };
  // API method declaration end

  //Internal methods start
  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.dropdownDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  resetValidation() {
    let validReset = this.state.validState;
    validReset.isValid = true;
    validReset.error = {};
    this.setState({ validState: validReset });
  }

  resetDropdownData() {
    let resetDropdownData = this.state.dropdownDetails;
    resetDropdownData.dropdownId = 0;
    resetDropdownData.tabelDataId = 0;
    resetDropdownData.name = "";
    resetDropdownData.valueOrdering = 0;

    this.setState({ dropdownDetails: resetDropdownData });
  }

  onAddNewDropdownValue() {
    this.resetValidation();
    this.resetDropdownData();
    this.setState({ addNewDropdown: true });
    this.setState({ addEditDropdown: false });
  }

  afterAddEditDropdown() {
    this.resetValidation();
    this.resetDropdownData();
    this.setState({ addEditDropdown: false });
    this.setState({ addNewDropdown: false });
  }

  cancelAddEditDropdown() {
    this.resetValidation();
    this.resetDropdownData();
    this.setState({ addNewDropdown: false });
    this.setState({ addEditDropdown: false });
  }

  //Handle change for add dropdown data
  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.dropdownDetails;
    if (name === "valueOrdering") {
      let chcklength = this.state.length - 1;
      if (value > chcklength) {
        detail[name] = parseInt(chcklength);
      } else {
        detail[name] = (value);
      }
    } else {
      detail[name] = value;
    }
    this.setState({ dropdownDetails: detail });
  }

  //handle change in dropdown
  handleChangeInDropdown(selected, drpIdentity) {
    if (drpIdentity === "DropdownFilter") {
      let details = this.state.dropdownDetails;
      details.dropdownId = selected.value;
      if (selected.value > 0) {
        this.cancelAddEditDropdown();
        this.getDropdownDataById(selected.value);
        this.setState({ selectedDropdownId: selected.value });
      } else {
        // this.state.dropdownListById = [];
        // this.state.selectedDropdownId = 0;
        this.setState({ dropdownListById: [] });
        this.setState({ selectedDropdownId: 0 });
        this.setState({ addNewDropdown: false });
        this.cancelAddEditDropdown();
      }
      this.setState({ dropdownDetails: details });
    }
  }
  //Method declaration section end

  render() {
    return (
      <div className="main-body-section">
        <div className="px-6 pt-10 pb-12 bg-white">
          <div className="grid grid-cols-12 gap-6 w-full">
            <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
              <div className="grid grid-cols-12 gap-6 w-full">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                  <h2 className="text-xl theme-color mb-1">Select Dropdown</h2>
                  <Selector
                    options={this.state.dropdownList}
                    // isMulti
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    // components={{
                    //   Option,
                    // }}
                    onChange={this.handleChangeInDropdown.bind(this)}
                    allowSelectAll={false}
                    // value={this.state.pagination.FilterByAgency}
                    value={this.state.dropdownList.find(
                      (item) =>
                        item.value === this.state.dropdownDetails.dropdownId
                    )}
                    drpIdentity="DropdownFilter"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <div className="bg-white pb-7">
            <div className=" heading-part px-7 py-4 border-b">
              <h4 className="small-title font-bold theme-color">
                Add/Edit Dropdown Details
              </h4>
            </div>
            <div className="px-6 pt-7">
              <div className="flex justify-end">
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                  {this.state.selectedDropdownId > 0 ? (
                    <button
                      className="btn btn-pink text-white font-semibold link"
                      onClick={() => {
                        this.onAddNewDropdownValue();
                        //this.myRef.current.scrollIntoView();
                      }}
                    >
                      <span className="text-xl text-white font-semibold tracking-normal">
                        Add Dropdown
                      </span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-pink text-white font-semibold link cursor-not-allowed"
                      title="Please select the dropdown first!"
                      onClick={() => {
                        this.onAddNewDropdownValue();
                      }}
                      disabled={true}
                    >
                      <span className="text-xl text-white font-semibold tracking-normal">
                        Add Dropdown
                      </span>
                    </button>
                  )}
                </div>
              </div>
              <div className="edit-table-sec my-6  ">
                <EditDropdownTable
                  dropdownListById={this.state.dropdownListById}
                  dropdownDetails={this.state.dropdownDetails}
                  addEditDropdown={this.state.addEditDropdown}
                  addNewDropdown={this.state.addNewDropdown}
                  selectedDropdownId={this.state.selectedDropdownId}
                  validState={this.state.validState}
                  handleChange={this.handleChange.bind(this)}
                  cancelAddEditDropdown={this.cancelAddEditDropdown.bind(this)}
                  deleteDropdownData={this.deleteDropdownData.bind(this)}
                  addEditDropdownData={this.addEditDropdownData.bind(this)}
                  getTableDataById={this.getTableDataById.bind(this)}
                  refProp={this.myRef}
                  length={this.state.length}
                  isLoading={this.state.isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}