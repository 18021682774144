import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import Row3Input from '../../../components/membership/membershipPricing/Row3Input';
import SectionWithBorder from "../../../components/UIComponent/SectionBorder";
import MembershipPricingServices from "../../../services/axiosServices/apiServices/MembershipPricingServices";
import SwalServices from '../../../services/swalServices/SwalServices';
import { getAuthProps } from '../../../utils/AuthenticationLibrary';
import { CommonValidationMessages } from '../../../utils/Messages';
import { convertToDecimal } from '../../../utils/Utils';
import { isValidForm, validate } from '../../../utils/validation/CommonValidator';

export default class MembershipPricing extends Component {
    constructor(props) {
        super(props);
        this.membershipPricingServices = new MembershipPricingServices();
        this.swalServices = new SwalServices();
        this.state = {
            membershipPricing: {
                membershipPricingId: 0,
                corporateMembershipEntranceFee: 0,
                corporateMembership100MListedCompany: 0,
                corporateMembership25M_100M: 0,
                corporateMembership1M_25M: 0,
                corporateMembership5L_1M: 0,
                corporateMembershipLess5L: 0,
                corporateMembershipNewBusiness: 0,
                corporateOMEntranceFee: 0,
                corporateOM100MListedCompany: 0,
                corporateOM25M_100M: 0,
                corporateOM1M_25M: 0,
                corporateOM5L_1M: 0,
                corporateOMLess5L: 0,
                corporateOMNewBusiness: 0,
                associateIndividualEntranceFee: 0,
                associateIndividualAnnualFee: 0,
                associateStudentEntranceFee: 0,
                associateStudentAnnualFee: 0,
                associateEducatorEntranceFee: 0,
                associateEducatorAnnualFee: 0,
                corporateOMEntranceFeeNM:0,
                corporateMembershipEntranceFeeNM:0
            },
            validationRule: {
                corporateMembershipEntranceFee: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                corporateMembership100MListedCompany: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                corporateMembership25M_100M: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                corporateMembership1M_25M: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                corporateMembership5L_1M: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                corporateMembershipLess5L: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                corporateMembershipNewBusiness: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                corporateOMEntranceFee: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                corporateOM100MListedCompany: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                corporateOM25M_100M: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                corporateOM1M_25M: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                corporateOM5L_1M: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                corporateOMLess5L: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                corporateOMNewBusiness: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                associateIndividualEntranceFee: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                associateIndividualAnnualFee: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                associateStudentEntranceFee: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                associateStudentAnnualFee: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                associateEducatorEntranceFee: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                associateEducatorAnnualFee: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                corporateOMEntranceFeeNM: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                corporateMembershipEntranceFeeNM: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
            },
            validState: {
                isValid: true,
                error: {},
            },
        }
    }

    handleChange(event) {
        let value = event.target.value;
        const name = event.target.name;
        let detail = this.state.membershipPricing;
        if ((value = convertToDecimal(value)) || value === 0) {
            detail[name] = value;
        }
        this.setState({ membershipPricing: { ...detail } });
    }

    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.membershipPricing,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.membershipPricing,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    componentDidMount() {
        // For Set Created by based on admin login
        let cookie = getAuthProps();
        const adminUserId = (cookie?.adminUserId) ? Number(cookie.adminUserId) : 0;
        if (adminUserId > 0) {
            this.setState({ createdBy: adminUserId });
        }
        // END
         this.getMembershipPricing();
    }

    addMembershipPricing = () => {
        let isValid = this.isValidateAllFields();
        if (isValid) {
            this.membershipPricingServices
                .addEditMembershipPricing(this.state.membershipPricing)
                .then((response) => {
                    if (response.statusCode === 200 && response.responseItem != null) {
                        this.swalServices.Success(
                            "Membership pricing updated successfully."
                        );
                    } else {
                        this.swalServices.Error(response.message);
                    }
                });
        }
    };
    getMembershipPricing = () => {
        this.membershipPricingServices.getMembershipPricing().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                if (response.responseItem.responseContent !== null) {
                    this.setState({
                        membershipPricing: response.responseItem.responseContent,
                    });
                }
            } else {
                this.swalServices.Error(response.message);
            }
        });
    };

    render() {
        return (
            <>
                <div className="main-body-section">
                    <div className="bg-white">
                        <div className="heading-part pl-7 py-4 border-b">
                            <div className="flex items-center justify-between">
                                <h4 className="small-title font-bold theme-color">
                                    Base Pricing Table
                                </h4>
                            </div>
                        </div>
                        <div className="custom-card shadow-lg mb-10">
                            <div className="">
                                <SectionWithBorder
                                    column1="Fees"
                                    column2="Corporate Ordinary"
                                    column3="Corporate Associate"
                                >
                                    <div className="sign-icon-input  px-7">
                                        <div className="pr-24">
                                            <Row3Input
                                                className="text-size-18 text-[#181818]"
                                                caption="Entrance Fee (One-Time)"
                                                col1value={this.state.membershipPricing.corporateOMEntranceFee}
                                                col2value={this.state.membershipPricing.corporateMembershipEntranceFee}
                                                col1FieldName="corporateOMEntranceFee"
                                                col2FieldName="corporateMembershipEntranceFee"
                                                col1InputType="Money"
                                                col2InputType="Money"
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={this.state.validState.error.corporateOMEntranceFee}
                                                error2={this.state.validState.error.corporateMembershipEntranceFee}
                                            />
                                        </div>
                                    </div>
                                    <div className="sign-icon-input  px-7">
                                        <div className="pr-24">
                                            <Row3Input
                                                className="text-size-18 text-[#181818]"
                                                caption="Entrance Fee for New Business(One-Time)"
                                                col1value={this.state.membershipPricing.corporateOMEntranceFeeNM}
                                                col2value={this.state.membershipPricing.corporateMembershipEntranceFeeNM}
                                                col1FieldName="corporateOMEntranceFeeNM"
                                                col2FieldName="corporateMembershipEntranceFeeNM"
                                                col1InputType="Money"
                                                col2InputType="Money"
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={this.state.validState.error.corporateOMEntranceFeeNM}
                                                error2={this.state.validState.error.corporateMembershipEntranceFeeNM}
                                            />
                                        </div>
                                    </div>
                                </SectionWithBorder>
                                <div className="annual-member-fee pb-10">
                                    <SectionWithBorder
                                        column1="Annual Membership Fee"
                                        column2=""
                                        column3=""
                                    >
                                        <h2 className="text-size-18 ml-10 mt-10 text-zinc-500">
                                            Revenue Tier
                                        </h2>
                                        <div className="px-7">
                                            <div className="pr-24">
                                                <Row3Input
                                                    className="text-size-18 text-[#181818]"
                                                    caption="S$100 Mil or Listed Company"
                                                    col1value={this.state.membershipPricing.corporateOM100MListedCompany}
                                                    col2value={this.state.membershipPricing.corporateMembership100MListedCompany}
                                                    col1FieldName="corporateOM100MListedCompany"
                                                    col2FieldName="corporateMembership100MListedCompany"
                                                    col1InputType="Money"
                                                    col2InputType="Money"
                                                    handleChange={this.handleChange.bind(this)}
                                                    validateField={this.validateField.bind(this)}
                                                    validState={this.state.validState}
                                                    error1={this.state.validState.error.corporateOM100MListedCompany}
                                                    error2={this.state.validState.error.corporateMembership100MListedCompany}
                                                />

                                                <Row3Input
                                                    className="text-size-18 text-[#181818]"
                                                    caption="S$25 Mil – S$100 Mil"
                                                    col1value={this.state.membershipPricing.corporateOM25M_100M}
                                                    col2value={this.state.membershipPricing.corporateMembership25M_100M}
                                                    col1FieldName="corporateOM25M_100M"
                                                    col2FieldName="corporateMembership25M_100M"
                                                    col1InputType="Money"
                                                    col2InputType="Money"
                                                    handleChange={this.handleChange.bind(this)}
                                                    validateField={this.validateField.bind(this)}
                                                    validState={this.state.validState}
                                                    error1={this.state.validState.error.corporateOM25M_100M}
                                                    error2={this.state.validState.error.corporateMembership25M_100M}
                                                />

                                                <Row3Input
                                                    className="text-size-18 text-[#181818]"
                                                    caption="S$1 Mil – S$25 Mil"
                                                    col1value={this.state.membershipPricing.corporateOM1M_25M}
                                                    col2value={this.state.membershipPricing.corporateMembership1M_25M}
                                                    col1FieldName="corporateOM1M_25M"
                                                    col2FieldName="corporateMembership1M_25M"
                                                    col1InputType="Money"
                                                    col2InputType="Money"
                                                    handleChange={this.handleChange.bind(this)}
                                                    validateField={this.validateField.bind(this)}
                                                    validState={this.state.validState}
                                                    error1={this.state.validState.error.corporateOM1M_25M}
                                                    error2={this.state.validState.error.corporateMembership1M_25M}
                                                />

                                                <Row3Input
                                                    className="text-size-18 text-[#181818]"
                                                    caption="S$500,000 – S$1 Mil "
                                                    col1value={this.state.membershipPricing.corporateOM5L_1M}
                                                    col2value={this.state.membershipPricing.corporateMembership5L_1M}
                                                    col1FieldName="corporateOM5L_1M"
                                                    col2FieldName="corporateMembership5L_1M"
                                                    col1InputType="Money"
                                                    col2InputType="Money"
                                                    handleChange={this.handleChange.bind(this)}
                                                    validateField={this.validateField.bind(this)}
                                                    validState={this.state.validState}
                                                    error1={this.state.validState.error.corporateOM5L_1M}
                                                    error2={this.state.validState.error.corporateMembership5L_1M}
                                                />

                                                <Row3Input
                                                    className="text-size-18 text-[#181818]"
                                                    caption="Less than S$500,000"
                                                    col1value={this.state.membershipPricing.corporateOMLess5L}
                                                    col2value={this.state.membershipPricing.corporateMembershipLess5L}
                                                    col1FieldName="corporateOMLess5L"
                                                    col2FieldName="corporateMembershipLess5L"
                                                    col1InputType="Money"
                                                    col2InputType="Money"
                                                    handleChange={this.handleChange.bind(this)}
                                                    validateField={this.validateField.bind(this)}
                                                    validState={this.state.validState}
                                                    error1={this.state.validState.error.corporateOMLess5L}
                                                    error2={this.state.validState.error.corporateMembershipLess5L}
                                                />
                                                <Row3Input
                                                    className="text-size-18 text-[#181818]"
                                                    caption="New Business"
                                                    col1value={this.state.membershipPricing.corporateOMNewBusiness}
                                                    col2value={this.state.membershipPricing.corporateMembershipNewBusiness}
                                                    col1FieldName="corporateOMNewBusiness"
                                                    col2FieldName="corporateMembershipNewBusiness"
                                                    col1InputType="Money"
                                                    col2InputType="Money"
                                                    handleChange={this.handleChange.bind(this)}
                                                    validateField={this.validateField.bind(this)}
                                                    validState={this.state.validState}
                                                    error1={this.state.validState.error.corporateOMNewBusiness}
                                                    error2={this.state.validState.error.corporateMembershipNewBusiness}
                                                />
                                            </div>
                                        </div>
                                    </SectionWithBorder>
                                </div>
                            </div>
                            <div className="individual-membership-fee">
                                <SectionWithBorder
                                    column1="Individual Membership Fee"
                                    column2="Entrance Fee"
                                    column3="Annual Fee"
                                >
                                    <div className="px-7">
                                        <div className="pr-24">
                                            <Row3Input
                                                className="text-size-18 text-[#181818]"
                                                caption="Associate Individual"
                                                col1value={this.state.membershipPricing.associateIndividualEntranceFee}
                                                col2value={this.state.membershipPricing.associateIndividualAnnualFee}
                                                col1FieldName="associateIndividualEntranceFee"
                                                col2FieldName="associateIndividualAnnualFee"
                                                col1InputType="Money"
                                                col2InputType="Money"
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={this.state.validState.error.associateIndividualEntranceFee}
                                                error2={this.state.validState.error.associateIndividualAnnualFee}
                                            />

                                            <Row3Input
                                                className="text-size-18 text-[#181818]"
                                                caption="Associate Student"
                                                col1value={this.state.membershipPricing.associateStudentEntranceFee}
                                                col2value={this.state.membershipPricing.associateStudentAnnualFee}
                                                col1FieldName="associateStudentEntranceFee"
                                                col2FieldName="associateStudentAnnualFee"
                                                col1InputType="Money"
                                                col2InputType="Money"
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={this.state.validState.error.associateStudentEntranceFee}
                                                error2={this.state.validState.error.associateStudentAnnualFee}
                                            />

                                            <Row3Input
                                                className="text-size-18 text-[#181818]"
                                                caption="Associate Educator"
                                                col1value={this.state.membershipPricing.associateEducatorEntranceFee}
                                                col2value={this.state.membershipPricing.associateEducatorAnnualFee}
                                                col1FieldName="associateEducatorEntranceFee"
                                                col2FieldName="associateEducatorAnnualFee"
                                                col1InputType="Money"
                                                col2InputType="Money"
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={this.state.validState.error.associateEducatorEntranceFee}
                                                error2={this.state.validState.error.associateEducatorAnnualFee}
                                            />
                                        </div>
                                    </div>
                                </SectionWithBorder>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <NavLink to="/Dashboard" className="btn btn-gray text-xl text-white">
                            {" "}
                            Back
                        </NavLink>
                        <button
                            className="text-lg font-bold btn btn-pink text-white"
                            onClick={() => this.addMembershipPricing()}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </>
        )
    }
}
