import React, { Component } from 'react'
import Row2Input from '../../../../components/membership/membershipPricing/Row2Input';
import SectionWithNoBorder from "../../../../components/UIComponent/SectionTitleNoBorder";
import DropdownSelect from "../../../../components/dropdown/Dropdown";
import ValidationText from '../../../../utils/validation/ValidationText';
import moment from 'moment';
import { CommonValidationMessages } from '../../../../utils/Messages';
import { isValidForm, validate } from '../../../../utils/validation/CommonValidator';
import SwalServices from '../../../../services/swalServices/SwalServices';
import MembershipCampaignServices from '../../../../services/axiosServices/apiServices/MembershipCampaignServices';
import { checkParameters } from '../../../../utils/Encryption';
import { Navigate } from 'react-router-dom';

export default class AddEditMembershipCampaign extends Component {
    constructor(props) {
        super(props);
        this.swalServices = new SwalServices();
        this.MembershipCampaignServices = new MembershipCampaignServices();
        this.state = {
            redirect: null,
            paymentYear: [
                {
                    id: 1,
                    name: 1,
                },
                {
                    id: 2,
                    name: 2,
                },
                {
                    id: 3,
                    name: 3,
                },
                {
                    id: 4,
                    name: 4,
                },
                {
                    id: 5,
                    name: 5,
                },
            ],
            membershipCampaignDetails: {
                membershipCampaignId: 0,
                // campaignStartDate: "",
                // campaignEndDate: "",
                // campaignMinimumPaymentTerm: 0,
                // isNewMember: false,
                // isRenewalMember: false,
                newCMEntranceFeeFor36Months: 0,
                newCMEntranceFeeFor24_35Months: 0,
                renewalCMAnnualFeeFor36Months: 0,
                renewalCMAnnualFeeFor24_35Months: 0,
                newAssociateIndividualEntranceFeeFor36Months: 0,
                newAssociateIndividualEntranceFeeFor24_35Months: 0,
                renewalAssociateIndividualAnnualFeeFor36Months: 0,
                renewalAssociateIndividualAnnualFeeFor24Months: 0,
                newAssociateStudentEntranceFeeFor36Months: 0,
                newAssociateStudentEntranceFeeFor24_35Months: 0,
                renewalAssociateStudentAnnualFeeFor36Months: 0,
                renewalAssociateStudentAnnualFeeFor24Months: 0,
                newAssociateEducatorEntranceFeeFor36Months: 0,
                newAssociateEducatorEntranceFeeFor24_35Months: 0,
                renewalAssociateEducatorAnnualFeeFor36Months: 0,
                renewalAssociateEducatorAnnualFeeFor24Months: 0,
                // campaignAddtionalPromotion: '',
                // campaignInternalNotes: '',
                // validMembershipStartEndCampaign: false
            },
            validationRule: {
                newCMEntranceFeeFor36Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                newCMEntranceFeeFor24_35Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                renewalCMAnnualFeeFor36Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                renewalCMAnnualFeeFor24_35Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                newAssociateIndividualEntranceFeeFor36Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                newAssociateIndividualEntranceFeeFor24_35Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                renewalAssociateIndividualAnnualFeeFor36Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                renewalAssociateIndividualAnnualFeeFor24Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                newAssociateStudentEntranceFeeFor36Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                newAssociateStudentEntranceFeeFor24_35Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                renewalAssociateStudentAnnualFeeFor36Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                renewalAssociateStudentAnnualFeeFor24Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                newAssociateEducatorEntranceFeeFor36Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                newAssociateEducatorEntranceFeeFor24_35Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                renewalAssociateEducatorAnnualFeeFor36Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                renewalAssociateEducatorAnnualFeeFor24Months: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
            },
            validState: {
                isValid: true,
                error: {},
            },
        }
    }

    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.membershipCampaignDetails,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.membershipCampaignDetails,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    handleChange(event) {
        let value = event.target.value;
        var num = 0;
        value = parseFloat(value);
        if (!isNaN(value)) {
            num = value.toFixed(0);
            if(num>100){
                num=100;
            }
        }
        const name = event.target.name;
        let detail = this.state.membershipCampaignDetails;
        detail[name] = num;
        this.setState({ membershipCampaignDetails: { ...detail } });
    }

    setFilterParameters = (id, drpIdentity) => {
        let detail = this.state.membershipCampaignDetails;
        if (drpIdentity === "Year") {
            detail["campaignMinimumPaymentTerm"] = id;
            this.validateField("campaignMinimumPaymentTerm");
        }
        this.setState({ membershipCampaignDetails: { ...detail } });
    };

    // handleChange2(event) {
    //     const value = event.target.value;
    //     const name = event.target.name;
    //     let detail = this.state.membershipCampaignDetails;
    //     detail[name] = value;

    //     if (detail.campaignEndDate <= detail.campaignStartDate) {
    //         detail.validMembershipStartEndCampaign = true;
    //     }
    //     else if (detail.campaignEndDate > detail.campaignStartDate) {
    //         detail.validMembershipStartEndCampaign = false;
    //         console.warn("on Cahnge End Zatu Date :", detail.campaignEndDate);
    //     }
    //     let d = new Date(detail.campaignStartDate);
    //     d.setDate(d.getDate() + 1);
    //     this.setState({ tempDate: moment(d).format("yyyy-MM-DD") })

    //     this.setState({ membershipCampaignDetails: { ...detail } });
    // }


    // handleChangeCheckBox = (e) => {
    //     const { name, checked } = e.target;
    //     let det = this.state.membershipCampaignDetails;
    //     det[name] = checked;
    //     this.setState({ membershipCampaignDetails: { ...det } });
    // };

    componentDidMount() {
        // let params = new URLSearchParams(window.location.search);
        // let membershipCampaignId = params.get("");
        // if (membershipCampaignId && (membershipCampaignId = checkParameters(membershipCampaignId, "Number"))) {
        //     if (membershipCampaignId > 0) {
        //     }
        // }
        this.getMembershipCampaign();
    }

    getMembershipCampaign = () => {
        this.MembershipCampaignServices.getMembershipCampaign().then(
            (response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    this.setState({
                        membershipCampaignDetails: response.responseItem.responseContent,
                    });
                } else {
                    this.swalServices.Error(response.message);
                }
                // let detail = this.state.membershipCampaignDetails;
                // let d = new Date(detail.campaignStartDate);
                // d.setDate(d.getDate() + 1);
                // this.setState({ tempDate: moment(d).format("yyyy-MM-DD") })

            }
        );
    };

    addEditMembershipCampaign = () => {

        // let dateValid = this.validateCampaignDate();
        let isValid = this.isValidateAllFields();
        if (isValid) {
            let request = this.state.membershipCampaignDetails
            // request.membershipCampaignId = request.membershipCampaignId || 0;
            this.MembershipCampaignServices.addEditMembershipCampaign(
                request
            ).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    // if (this.state.membershipCampaignDetails.membershipCampaignId > 0) {
                        this.swalServices.Success("Campaign Updated successfully.");
                    // } else {
                    //     this.swalServices.Success("Campaign inserted successfully.");
                    // }
                    // this.setState({ redirect: "/MembershipCampaign" });
                } else {
                    this.swalServices.Error(response.message);
                }
            });
        }
        else {
                this.swalServices.Alert("please fill all fields");
        }
    };

    // validateCampaignDate = () => {
    //     const valid = { ...this.state.validationRule };
    //     valid["isValid"] = false;
    //     const campaignItem = { ...this.state.membershipCampaignDetails };
    //     const startDate = new Date(campaignItem.campaignStartDate);
    //     const endDate = new Date(campaignItem.campaignEndDate);
    //     if (startDate < endDate) {
    //         valid["isValid"] = true;
    //         return true;
    //     }
    //     return false;
    // };

    render() {
        // if (this.state.redirect != null) {
        //     return <Navigate to={this.state.redirect} />;
        // }
        return (
            <>
                <div className="main-body-section px-5">
                    <div className=" shadow-lg mb-10 bg-white">
                        <h2 className="border-b border-[#181818]-300 text-[22px] text-[#181818] font-bold w-full p-4 pl-8 ">
                            Membership Campaign
                        </h2>

                        {/* <div className="grid 2xl:grid-cols-5 lg :grid-cols-5 gap-4 h-[40px]  w-full items-center mb-20 p-4 pl-8 pt-8 flex-wrap pb-10 res-membershipCampain">
                            <div className="tab-font-date">
                                <label className="text-size-18 text-[#181818] font-bold">
                                    Campaign Start Date
                                </label>
                                <input
                                    id="campaignStartDate"
                                    name="campaignStartDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(
                                        this.state.membershipCampaignDetails.campaignStartDate
                                    ).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange2(event)}
                                    onBlur={() => this.validateField("campaignStartDate")}
                                />
                                <ValidationText
                                    error={this.state.validState.error.campaignStartDate}
                                />
                            </div>
                            <div className="tab-font-date">
                                <label className="text-size-18 text-[#181818] font-bold">
                                    Campaign End Date
                                </label>
                                <input
                                    id="campaignEndDate"
                                    name="campaignEndDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    min={this.state.tempDate}
                                    value={moment(
                                        this.state.membershipCampaignDetails.campaignEndDate
                                    ).format("yyyy-MM-DD")}
                                    onBlur={() => this.validateField("campaignEndDate")}
                                    onChange={(event) => this.handleChange2(event)}
                                />
                                <ValidationText
                                    error={this.state.validState.error.campaignEndDate}
                                />
                                {
                                    this.state.membershipCampaignDetails.validMembershipStartEndCampaign === true && this.state.membershipCampaignDetails.campaignEndDate !== "" ?
                                        <p className="text-[#AA3361]">Please select date higher than campaign start date.</p>
                                        : null
                                }
                            </div>
                            <div className="lg:pr-0 tab-font-date">
                                <label className="text-size-18 text-[#181818] font-bold ">
                                    Minimum Payment Term
                                </label>
                                <DropdownSelect
                                    drpIdentity={"Year"}
                                    optionArray={this.state.paymentYear}
                                    setFilterParameters={this.setFilterParameters}
                                    value={
                                        this.state.membershipCampaignDetails
                                            .campaignMinimumPaymentTerm
                                    }
                                />
                                <div>
                                    <ValidationText
                                        error={this.state.validState.error.campaignMinimumPaymentTerm}
                                    />
                                </div>
                                <div>
                                </div>
                            </div>

                            <label className="flex items-center pl-10 res-checkbox-left">
                                <input
                                    type="checkbox"
                                    className="form-checkbox"
                                    name="isNewMember"
                                    id="isNewMember"
                                    onChange={(event) => this.handleChangeCheckBox(event)}
                                    checked={this.state.membershipCampaignDetails.isNewMember}
                                    value={this.state.membershipCampaignDetails.isNewMember}
                                />
                                <span
                                    htmlFor="isNewMember"
                                    className="text-base text-[#181818] font-bold ml-4"
                                >
                                    New Member
                                </span>
                            </label>

                            <label className="flex items-center">
                                <input
                                    type="checkbox"
                                    className="form-checkbox"
                                    name="isRenewalMember"
                                    id="isRenewalMember"
                                    onChange={(event) => this.handleChangeCheckBox(event)}
                                    checked={this.state.membershipCampaignDetails.isRenewalMember}
                                    value={this.state.membershipCampaignDetails.isRenewalMember}
                                />
                                <span
                                    htmlFor="isRenewalMember"
                                    className="text-base text-[#181818] font-bold ml-4"
                                >
                                    Renewal Member
                                </span>
                            </label>
                        </div> */}

                        {/* <div className=''> */}
                        <div className="grid grid-cols-12 gap-6 m-8 pt-8 flex border p-3 border-[#181818]">
                            <div className="col-span-6 border-r border-[#181818]">
                                {/* <div className="grid grid-col-12"> */}
                                <SectionWithNoBorder
                                    column1="New Corporate Member"
                                    column2="Entrance Fee"
                                >
                                    <div className="sign-icon-percentage-input">
                                        <Row2Input
                                            className="text-size-18 text-[#181818]"
                                            caption="For Equal or above 36 months"
                                            col1FieldName="newCMEntranceFeeFor36Months"
                                            col1InputType="Percentage"
                                            col1value={
                                                this.state.membershipCampaignDetails
                                                    .newCMEntranceFeeFor36Months
                                            }
                                            handleChange={this.handleChange.bind(this)}
                                            validateField={this.validateField.bind(this)}
                                            validState={this.state.validState}
                                            error1={
                                                this.state.validState.error.newCMEntranceFeeFor36Months
                                            }
                                        />
                                        <Row2Input
                                            className="text-size-18 text-[#181818]"
                                            caption="For 24-35 months"
                                            col1FieldName="newCMEntranceFeeFor24_35Months"
                                            col1InputType="Percentage"
                                            col1value={
                                                this.state.membershipCampaignDetails
                                                    .newCMEntranceFeeFor24_35Months
                                            }
                                            handleChange={this.handleChange.bind(this)}
                                            validateField={this.validateField.bind(this)}
                                            validState={this.state.validState}
                                            error1={
                                                this.state.validState.error.newCMEntranceFeeFor24_35Months
                                            }
                                        />
                                    </div>
                                </SectionWithNoBorder>
                                {/* </div> */}
                            </div>
                            <div className="col-span-6">
                                {/* <div className="grid grid-col-12"> */}
                                <SectionWithNoBorder
                                    column1="Renewal Corporate Member"
                                    column2="Annual Fee"
                                >
                                    <div className="sign-icon-percentage-input">
                                        <Row2Input
                                            className="text-size-18 text-[#181818]"
                                            caption="For 36 months"
                                            col1InputType="Percentage"
                                            col1FieldName="renewalCMAnnualFeeFor36Months"
                                            col1value={
                                                this.state.membershipCampaignDetails
                                                    .renewalCMAnnualFeeFor36Months
                                            }
                                            handleChange={this.handleChange.bind(this)}
                                            validateField={this.validateField.bind(this)}
                                            validState={this.state.validState}
                                            error1={
                                                this.state.validState.error.renewalCMAnnualFeeFor36Months
                                            }
                                        />
                                        <Row2Input
                                            className="text-size-18 text-[#181818]"
                                            caption="For 24 months"
                                            col1InputType="Percentage"
                                            col1FieldName="renewalCMAnnualFeeFor24_35Months"
                                            col1value={
                                                this.state.membershipCampaignDetails
                                                    .renewalCMAnnualFeeFor24_35Months
                                            }
                                            handleChange={this.handleChange.bind(this)}
                                            validateField={this.validateField.bind(this)}
                                            validState={this.state.validState}
                                            error1={
                                                this.state.validState.error.renewalCMAnnualFeeFor24_35Months
                                            }
                                        />
                                    </div>
                                </SectionWithNoBorder>
                            </div>
                            {/* </div> */}
                        </div>
                        {/* </div> */}
                        <div>
                            <div className="grid grid-cols-12 gap-6 m-8 pt-8 flex border p-3 border-[#181818]">
                                <div className="col-span-6 border-r border-[#181818]">
                                    {/* <div className="grid grid-col-12"> */}
                                    <SectionWithNoBorder
                                        column1="New Associate Individual"
                                        column2="Entrance Fee"
                                    >
                                        <div className="sign-icon-percentage-input">
                                            <Row2Input
                                                className="text-size-18 text-[#181818]"
                                                caption="For Equal or above 36 months"
                                                col1FieldName="newAssociateIndividualEntranceFeeFor36Months"
                                                col1InputType="Percentage"
                                                col1value={
                                                    this.state.membershipCampaignDetails
                                                        .newAssociateIndividualEntranceFeeFor36Months
                                                }
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={
                                                    this.state.validState.error.newAssociateIndividualEntranceFeeFor36Months
                                                }
                                            />
                                            <Row2Input
                                                className="text-size-18 text-[#181818]"
                                                caption="For 24-35 months"
                                                col1FieldName="newAssociateIndividualEntranceFeeFor24_35Months"
                                                col1InputType="Percentage"
                                                col1value={
                                                    this.state.membershipCampaignDetails
                                                        .newAssociateIndividualEntranceFeeFor24_35Months
                                                }
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={
                                                    this.state.validState.error.newAssociateIndividualEntranceFeeFor24_35Months
                                                }
                                            />
                                        </div>
                                    </SectionWithNoBorder>
                                    {/* </div> */}
                                </div>
                                <div className="col-span-6">
                                    {/* <div className="grid grid-col-12"> */}
                                    <SectionWithNoBorder
                                        column1="Renewal Associate Individual"
                                        column2="Annual Fee"
                                    >
                                        <div className="sign-icon-percentage-input">
                                            <Row2Input
                                                className="text-size-18 text-[#181818]"
                                                caption="For 36 months"
                                                col1FieldName="renewalAssociateIndividualAnnualFeeFor36Months"
                                                col1InputType="Percentage"
                                                col1value={
                                                    this.state.membershipCampaignDetails
                                                        .renewalAssociateIndividualAnnualFeeFor36Months
                                                }
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={
                                                    this.state.validState.error.renewalAssociateIndividualAnnualFeeFor36Months
                                                }
                                            />
                                            <Row2Input
                                                className="text-size-18 text-[#181818]"
                                                caption="For 24 months"
                                                col1FieldName="renewalAssociateIndividualAnnualFeeFor24Months"
                                                col1InputType="Percentage"
                                                col1value={
                                                    this.state.membershipCampaignDetails
                                                        .renewalAssociateIndividualAnnualFeeFor24Months
                                                }
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={
                                                    this.state.validState.error.renewalAssociateIndividualAnnualFeeFor24Months
                                                }
                                            />
                                        </div>
                                    </SectionWithNoBorder>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-12 gap-6 m-8 pt-8 flex border p-3 border-[#181818]">
                                <div className="col-span-6 border-r border-[#181818]">
                                    {/* <div className="grid grid-col-12"> */}
                                    <SectionWithNoBorder
                                        column1="New Associate Student"
                                        column2="Entrance Fee"
                                    >
                                        <div className="sign-icon-percentage-input">
                                            <Row2Input
                                                className="text-size-18 text-[#181818]"
                                                caption="For Equal or above 36 months"
                                                col1FieldName="newAssociateStudentEntranceFeeFor36Months"
                                                col1InputType="Percentage"
                                                col1value={
                                                    this.state.membershipCampaignDetails
                                                        .newAssociateStudentEntranceFeeFor36Months
                                                }
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={
                                                    this.state.validState.error.newAssociateStudentEntranceFeeFor36Months
                                                }
                                            />
                                            <Row2Input
                                                className="text-size-18 text-[#181818]"
                                                caption="For 24-35 months"
                                                col1FieldName="newAssociateStudentEntranceFeeFor24_35Months"
                                                col1InputType="Percentage"
                                                col1value={
                                                    this.state.membershipCampaignDetails
                                                        .newAssociateStudentEntranceFeeFor24_35Months
                                                }
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={
                                                    this.state.validState.error.newAssociateStudentEntranceFeeFor24_35Months
                                                }
                                            />
                                        </div>
                                    </SectionWithNoBorder>
                                    {/* </div> */}
                                </div>
                                <div className="col-span-6">
                                    {/* <div className="grid grid-col-12"> */}
                                    <SectionWithNoBorder
                                        column1="Renewal Associate Student"
                                        column2="Annual Fee"
                                    >
                                        <div className="sign-icon-percentage-input">
                                            <Row2Input
                                                className="text-size-18 text-[#181818]"
                                                caption="For 36 months"
                                                col1FieldName="renewalAssociateStudentAnnualFeeFor36Months"
                                                col1InputType="Percentage"
                                                col1value={
                                                    this.state.membershipCampaignDetails
                                                        .renewalAssociateStudentAnnualFeeFor36Months
                                                }
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={
                                                    this.state.validState.error.renewalAssociateStudentAnnualFeeFor36Months
                                                }
                                            />
                                            <Row2Input
                                                className="text-size-18 text-[#181818]"
                                                caption="For 24 months"
                                                col1FieldName="renewalAssociateStudentAnnualFeeFor24Months"
                                                col1InputType="Percentage"
                                                col1value={
                                                    this.state.membershipCampaignDetails
                                                        .renewalAssociateStudentAnnualFeeFor24Months
                                                }
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={
                                                    this.state.validState.error.renewalAssociateStudentAnnualFeeFor24Months
                                                }
                                            />
                                        </div>
                                    </SectionWithNoBorder>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-12 gap-6 m-8 pt-8 flex border p-3 border-[#181818]">
                                <div className="col-span-6 border-r border-[#181818]">
                                    {/* <div className="grid grid-col-12"> */}
                                    <SectionWithNoBorder
                                        column1="New Associate Educator "
                                        column2="Entrance Fee"
                                    >
                                        <div className="sign-icon-percentage-input">
                                            <Row2Input
                                                className="text-size-18 text-[#181818]"
                                                caption="For Equal or above 36 months"
                                                col1FieldName="newAssociateEducatorEntranceFeeFor36Months"
                                                col1InputType="Percentage"
                                                col1value={
                                                    this.state.membershipCampaignDetails
                                                        .newAssociateEducatorEntranceFeeFor36Months
                                                }
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={
                                                    this.state.validState.error.newAssociateEducatorEntranceFeeFor36Months
                                                }
                                            />
                                            <Row2Input
                                                className="text-size-18 text-[#181818]"
                                                caption="For 24-35 months"
                                                col1FieldName="newAssociateEducatorEntranceFeeFor24_35Months"
                                                col1InputType="Percentage"
                                                col1value={
                                                    this.state.membershipCampaignDetails
                                                        .newAssociateEducatorEntranceFeeFor24_35Months
                                                }
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={
                                                    this.state.validState.error.newAssociateEducatorEntranceFeeFor24_35Months
                                                }
                                            />
                                        </div>

                                    </SectionWithNoBorder>
                                    {/* </div> */}
                                </div>
                                <div className="col-span-6">
                                    {/* <div className="grid grid-col-12"> */}
                                    <SectionWithNoBorder
                                        column1="Renewal Associate Educator"
                                        column2="Annual Fee"
                                    >
                                        <div className="sign-icon-percentage-input">
                                            <Row2Input
                                                className="text-size-18 text-[#181818]"
                                                caption="For 36 months"
                                                col1FieldName="renewalAssociateEducatorAnnualFeeFor36Months"
                                                col1InputType="Percentage"
                                                col1value={
                                                    this.state.membershipCampaignDetails
                                                        .renewalAssociateEducatorAnnualFeeFor36Months
                                                }
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={
                                                    this.state.validState.error.renewalAssociateEducatorAnnualFeeFor36Months
                                                }
                                            />
                                            <Row2Input
                                                className="text-size-18 text-[#181818]"
                                                caption="For 24 months"
                                                col1FieldName="renewalAssociateEducatorAnnualFeeFor24Months"
                                                col1InputType="Percentage"
                                                col1value={
                                                    this.state.membershipCampaignDetails
                                                        .renewalAssociateEducatorAnnualFeeFor24Months
                                                }
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={
                                                    this.state.validState.error.renewalAssociateEducatorAnnualFeeFor24Months
                                                }
                                            />
                                        </div>
                                    </SectionWithNoBorder>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-6 items-center pl-8 pb-12 pt-5">
                            <div className="col-span-6">
                                {/* <div className="grid grid-cols-12 gap-6 gap-res">
                                    <div className="col-span-6">
                                        <label className="text-size-18 text-[#181818] font-bold">
                                            Additional Promotion
                                        </label>
                                        <input
                                            className="w-full mt-2"
                                            placeholder=""
                                            type="text"
                                            name="campaignAddtionalPromotion"
                                            value={
                                                this.state.membershipCampaignDetails
                                                    .campaignAddtionalPromotion
                                            }
                                            onChange={(e) => this.handleChange2(e)}
                                        />
                                    </div>
                                    <div className="col-span-6">
                                        <label className="text-size-18 text-[#181818] font-bold">
                                            Internal Notes
                                        </label>
                                        <input
                                            className="w-full mt-2"
                                            placeholder=""
                                            type="text"
                                            name="campaignInternalNotes"
                                            value={
                                                this.state.membershipCampaignDetails.campaignInternalNotes
                                            }
                                            onChange={(e) => this.handleChange2(e)}
                                        />
                                    </div>
                                </div> */}
                            </div>
                            <div className="2xl:col-span-6 lg:col-span-6 pt-6">
                                <div className="flex items-center justify-end pr-14">
                                    <button
                                        className="flex items-center text-lg font-bold  btn btn-pink text-white"
                                        onClick={() => this.addEditMembershipCampaign()}
                                    >
                                        {/* <span className="border-2 flex items-center justify-center border-white h-7 w-7 mr-2 rounded-full text-xl">
                                            +
                                        </span> */}
                                        <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
