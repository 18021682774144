import React from 'react';
import ModalBasic from '../../../pages/component/ModalBasic';
import ValidationText from '../../../utils/validation/ValidationText';
import DropdownSelect from '../../dropdown/Dropdown';
import TextInput from '../../UIComponent/TextInput';
import ButtonLoader from '../../common/buttonLoader/ButtonLoader';
import Selector from '../../dropdown/commonDropdown/Selector';

const AddEditOrganisationEmployee = (props) => {
    console.log("Check Disble fag ", props.validStateOrgEmployee)
    return (
        <ModalBasic
            id="show-individual-profile-modal"
            modalOpen={props.setOrganisationEmployeeModal}
            setModalOpen={props.setModalOpen}
        >
            <div className="bg-white mb-10">
                <div className="">
                    <div className="grid grid-cols-12 gap-6 w-full 2xl:px-10 lg:px-8 pr-5">
                        <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                            <h1 className="text-4xl text-[#AA3361] font-bold leading-loose py-4">
                                Individual Profile
                            </h1>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 w-full 2xl:px-10 lg:px-8 pr-5">
                        {
                            props.isCreateNewOrgRoleForEmployee === true ?
                                <>
                                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                                            Email Address <span className="text-[#c00000]">*</span>
                                        </h2>
                                        <TextInput
                                            placeholder=""
                                            type="text"
                                            name="searchByEmail"
                                            value={props.employeeDetails.searchByEmail}
                                            identity="Organisation_Employee"
                                            handleChange={props.handleChange}
                                            onBlur={() => props.validateField("searchByEmail", "Organisation_SearchByEmail")}
                                        />
                                        <ValidationText error={props.validStateOrgEmployee.error.searchByEmail} />
                                    </div>
                                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                        <div className='flex justify-end'>
                                            <div>
                                                <h2 className="text-xl mb-1">&nbsp;</h2>
                                                <button className="w-32 px-7 py-2 btn btn-pink text-lg text-white font-bold"
                                                    onClick={() => props.searchIndividualMemberByEmailForOrgRole("Organisarion Employees")}
                                                >Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null
                        }
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <h2 className="2xl:text-xl lg:text-md text-[#181818] font-bold 2xl:mb-1">
                                First Name <span className="text-[#AA3361]">*</span>
                            </h2>
                            <div className="flex items-center">
                                <div className="w-[130px] individual-f-name">
                                    <DropdownSelect
                                        drpIdentity={"Employee_Salutation"}
                                        optionArray={props.salutation}
                                        setFilterParameters={props.setFilterParameters}
                                        value={props.employeeDetails.mrMrsId}
                                        disabled={props.isEmployeeModalEditable !== true}
                                    />
                                </div>
                                <div className="w-full">
                                    <TextInput
                                        placeholder=""
                                        name="firstName"
                                        identity="Organisation_Employee"
                                        handleChange={props.handleChange}
                                        value={props.employeeDetails.firstName}
                                        onBlur={(e) => props.validateField("firstName", "Organisation_Employee")}
                                        disabled={props.isEmployeeModalEditable !== true}
                                        isCurserAllowed={props.isEmployeeModalEditable === true}
                                    />
                                </div>
                            </div>
                            <ValidationText error={props.validStateOrgEmployee.error.mrMrsId} />
                            <ValidationText error={props.validStateOrgEmployee.error.firstName} />
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <h2 className="2xl:text-xl lg:text-md text-[#181818] font-bold 2xl:mb-1">
                                Last Name <span className="text-[#AA3361]">*</span>
                            </h2>
                            <TextInput
                                placeholder=""
                                name="lastName"
                                identity="Organisation_Employee"
                                handleChange={props.handleChange}
                                value={props.employeeDetails.lastName}
                                onBlur={(e) => props.validateField("lastName", "Organisation_Employee")}
                                disabled={props.isEmployeeModalEditable !== true}
                                isCurserAllowed={props.isEmployeeModalEditable === true}
                            />
                            <ValidationText error={props.validStateOrgEmployee.error.lastName} />
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <h2 className="2xl:text-xl lg:text-md text-[#181818] font-bold 2xl:mb-1">
                                Business Email{" "}
                                <span className="text-[#AA3361]">*</span>
                            </h2>
                            <div className="flex items-center">
                                <div className="w-full right-border-none">
                                    <TextInput
                                        placeholder=""
                                        name="email"
                                        identity="Organisation_Employee"
                                        handleChange={props.handleChange}
                                        value={props.employeeDetails.email}
                                        onBlur={(e) => props.validateField("email", "Organisation_Employee")}
                                        disabled={props.isEmployeeModalEditable !== true}
                                        isCurserAllowed={props.isEmployeeModalEditable === true}
                                    />
                                </div>
                            </div>
                            <ValidationText error={props.validStateOrgEmployee.error.email} />
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <h2 className="2xl:text-xl lg:text-md text-[#181818] font-bold 2xl:mb-1">
                                Contact Number
                            </h2>
                            <TextInput
                                placeholder=""
                                name="contactNo"
                                identity="Organisation_Employee"
                                handleChange={props.handleChange}
                                value={props.employeeDetails.contactNo}
                                onBlur={(e) => props.validateField("contactNo", "Organisation_Employee")}
                                disabled={props.isEmployeeModalEditable !== true}
                                isCurserAllowed={props.isEmployeeModalEditable === true}
                            />
                            <ValidationText error={props.validStateOrgEmployee.error.contactNo} />
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <h2 className="2xl:text-xl lg:text-md text-[#181818] font-bold 2xl:mb-1">
                                Job Function
                                <span className="text-[#AA3361]"> *</span>
                            </h2>
                            <div className="dropdwn-input-email flex items-center">
                            </div>
                            <DropdownSelect
                                drpIdentity={"Employee_KeyRoles"}
                                optionArray={props.jobFunctionList}
                                setFilterParameters={props.setFilterParameters}
                                value={props.employeeDetails.jobPositionId}
                                disabled={props.isEmployeeModalEditable !== true}
                            />
                            <ValidationText error={props.validStateOrgEmployee.error.jobPositionId} />
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <h2 className="2xl:text-xl lg:text-md text-[#181818] font-bold 2xl:mb-1">
                                Designation
                                <span className="text-[#AA3361]"> *</span>
                            </h2>
                            <div className="flex items-center">
                                <div className="w-full right-border-none">
                                    <TextInput
                                        placeholder=""
                                        name="title"
                                        identity="Organisation_Employee"
                                        handleChange={props.handleChange}
                                        value={props.employeeDetails.title}
                                        onBlur={(e) => props.validateField("title", "Organisation_Employee")}
                                        disabled={props.isEmployeeModalEditable !== true}
                                        isCurserAllowed={props.isEmployeeModalEditable === true}
                                    />
                                    <ValidationText error={props.validStateOrgEmployee.error.title} />
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-6 w-full pt-16 px-10">
                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                        <div className="flex items-center justify-between">
                            <div className="">
                                {
                                    props.employeeDetails && props.employeeDetails.individualMemberId > 0 ?
                                        <button
                                            className="btn btn-gray text-lg text-white font-bold"
                                            onClick={() => { props.deleteEmployee(props.employeeDetails.individualMemberId) }}
                                        >
                                            Delete Profile
                                        </button>
                                        :
                                        null
                                }
                            </div>
                            {
                                props.isAddEditAOrganisation && props.isAddEditAOrganisation === true ?
                                    <div className="savebtn">
                                        <ButtonLoader />
                                    </div>
                                    :

                                    <div className="savebtn">
                                        <button
                                            className="text-lg font-bold btn btn-pink text-white"
                                            onClick={() => props.ediCorporateEmployeeDetails("Save")}
                                        >
                                            Save
                                        </button>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </ModalBasic>
    )
}
export default AddEditOrganisationEmployee;