import moment from "moment";
import React, { Component } from "react";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { saveAs } from "file-saver";
import PageLoader from "../../components/common/pageLoader/PageLoader";

export default class DeferredIncomeReport extends Component {
    constructor(props) {
        super(props);
        this.financeServices = new FinanceServices();
        this.swalServices = new SwalServices();
        this.state = {
            options: [],
            currentDate: "",
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "CreatedAt",
                orderFlag: 0,
                searchText: "",
            },
            deferredIncomeReportList: [],
            isLoading: false,
            totalCount: 0,
        };
    }

    componentDidMount = () => {
        this.getAllDeferredIncomeReportList();
    }
    getAllDeferredIncomeReportList = () => {

        this.setState({ isLoading: true });
        let pagination = { ...this.state.pagination };
        this.financeServices.getAllDeferredIncomeReportList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let details = response.responseItem.responseContent.itemList;
                this.setState({
                    deferredIncomeReportList: details,
                    totalCount: response.responseItem.responseContent.totalCount,
                })
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }
    generateDeferredIncomeReport = () => {
        if (this.state.currentDate) {
            this.setState({ isLoading: true });
            let request = {};
            request.currentDate = moment(this.state.currentDate).format("DD MMM YYYY");
            this.financeServices.generateDeferredIncomeReport(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    this.swalServices.Success("Deferred income record generated successfully.");
                    this.getAllDeferredIncomeReportList();
                    this.setState({ currentDate: "" });
                }
                else if (response.statusCode === 409) {
                    this.swalServices.Error(response.message);
                    this.setState({ currentDate: "" });
                }
                else {
                    this.swalServices.Error(response.message);
                    this.setState({ currentDate: "" });
                }
                this.setState({ isLoading: false });
            });
        } else {
            this.swalServices.Alert('Please select date for deferred income report')
        }

    }

    downloadSheetByDeferredIncomeReportId = (deferredIncomeReportId, deferredIncomeReportStartDate, deferredIncomeReportEndDate) => {
        this.setState({ isLoading: true });
        let request = [];
        request.push(deferredIncomeReportId);
        this.financeServices.downloadSheetByDeferredIncomeReportId(request).then((response) => {
            if (response.status === 200 && response.data != null) {
                const formatter = new Intl.DateTimeFormat('en-GB', { month: 'long' });
                const month = formatter.format(new Date(deferredIncomeReportStartDate));
                const year = new Date(deferredIncomeReportStartDate).getFullYear();
                let blob = new Blob([response.data])
                saveAs(blob, `DeferredIncome from ${deferredIncomeReportStartDate} to ${deferredIncomeReportEndDate}` + ".xlsx");
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }

    render() {
        return (
            <div className="main-body-section finance-body-sec">
                <div className="bg-white">
                    <div className="grid grid-cols-12 gap-6 w-full items-center px-8 py-10">
                        <div className="lg:col-span-3 col-span-12">
                            <label
                                className="block text-xl font-bold 2xl:mb-2 mb-2 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Date<span className="text-[#AA3361]">*</span>
                            </label>
                            <div className="">
                                <input
                                    id="currentDate"
                                    name="currentDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.currentDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.setState({ currentDate: event.target.value })}
                                //    disabled
                                />
                            </div>
                        </div>

                        <div className="col-span-4">
                            <button className="btn btn-pink text-xl border text-white mt-8"
                                onClick={() => this.generateDeferredIncomeReport()}
                            >
                                Generate Deferred Income Report
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 w-full items-center justify-center px-8 py-10">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                            {this.state.isLoading ? <PageLoader /> :
                                <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table border">
                                    <thead>
                                        <tr className="text-base font-bold flex">
                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                                                <div className="font-bold tble-th-txt text-left">
                                                    Generate Date
                                                </div>
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                                                <div className="font-bold tble-th-txt text-left">
                                                    Start Date
                                                </div>
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                                                <div className="font-bold tble-th-txt text-left">
                                                    End Date
                                                </div>
                                            </th>

                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                                                <div className="font-bold tble-th-txt text-center">Action</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="scrollit text-sm custom-tbody">
                                        {this.state.deferredIncomeReportList.map((e, key) => {
                                            return (
                                                <tr className="custom-tbl-td flex items-center" key={key}>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%] ">
                                                        {moment(e.deferredIncomeReportGeneratedDate).format("DD MMM YYYY")}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                                                        {moment(e.deferredIncomeReportStartDate).format("DD MMM YYYY")}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                                                        {moment(e.deferredIncomeReportEndDate).format("DD MMM YYYY")}
                                                    </td>

                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                                        <button
                                                            className="action-btn mx-auto flex items-center"
                                                            aria-controls="add-edit-modal"
                                                            onClick={() => {
                                                                this.downloadSheetByDeferredIncomeReportId(e.deferredIncomeReportId, moment(e.deferredIncomeReportStartDate).format("DD-MM-YYYY"), moment(e.deferredIncomeReportEndDate).format("DD-MM-YYYY"));
                                                            }}
                                                        >
                                                            <p className="ml-2 tool-tip-txt underline text-[#81a3b8]">
                                                                Download
                                                            </p>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
