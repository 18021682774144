import React, { Component } from "react";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import ActionEditButton from "../../components/UIComponent/ActionTableComponent/ActionEditButton";
import ActionDeleteButton from "../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import { Navigate } from "react-router-dom";
import AuthenticationServices from "../../services/axiosServices/apiServices/AuthenticationServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { APIURL, GetImage } from "../../services/axiosServices/ApiEndPoints";
import { CommonSuccessMessages } from "../../utils/Messages";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import PageLoader from "../../components/common/pageLoader/PageLoader";
import { encryptAES } from "../../utils/Encryption";

export default class ViewEditAdminAccount extends Component {
  constructor(props) {
    super(props);
    this.adminServices = new AuthenticationServices();
    this.swalServices = new SwalServices();
    this.state = {
      route: "",
      isLoading: false,
      pagination: {
        pageNo: 1,
        pageSize: 50,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
      },
      totalResultes: 0,
      adminDetailsList: [],
      deletedBy: 0,
      deletedAppId: 0,
    };
  }

  componentDidMount() {
    // For Set Updated by and Created by based on admin login
    let cookie = getAuthProps();
    const adminUserId = (cookie?.adminUserId) ? Number(cookie.adminUserId) : 0;
    if (adminUserId > 0) {
      this.setState({ deletedBy: adminUserId, deletedAppId: 114 });
    }
    // END
    this.getAdminDetailList();
  }
  getAdminDetailList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });

    this.adminServices.getAdminDetailList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ adminDetailsList: response.responseItem.responseContent.itemList });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount });

      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  onDelete = (id) => {

    if (id !== 1) { // Todo : remove this condition in production.
      this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Admin"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "admin details"),
        CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
          if (response) {
            let request = {};
            request.id = id;
            request.deletedBy = this.state.deletedBy;
            request.appId = this.state.deletedAppId;
            this.adminServices.deleteAdminDetailsById(request).then((response) => {
              if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Success("Admin details deleted successfully.");
                this.getAdminDetailList();
              }
              else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoading: false });
            })
          }
        })
    }
    else {
      this.swalServices.Error("This is main admin you can't delete it.");
    }

  }

  searchByName = (value) => {
    let detail = this.state.pagination;

    detail.pageNo = 1;
    detail.pageSize = 10;
    detail.searchText = value;
    this.setState({ pagination: { ...detail } });

    this.getAdminDetailList();
  }

  onEdit = (id) => {
    this.setState({ route: '/CreateAdminAccount/adminId?=' + encryptAES(id) });
  }


  render() {
    if (this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section">
          <div className="grid grid-cols-6 gap-4 px-0 pb-3">
            <div className="col-end-7 col-span-2 grid grid-cols-3 items-center">
              <div className="text-[#181818] text-xl">Search</div>
              <input
                id="default"
                className="col-span-2 text-lg font-bold  h-[55px] w-full text-[#181818] border-white"
                type="text"
                onChange={(e) => this.searchByName(e.target.value)}
              />
            </div>
          </div>
          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div className="view-accout-details-table">
                  <div className="mt-6 view-accout-details-table">
                    {this.state.isLoading ? <PageLoader /> :
                      <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200 fixed_header">
                        {/* Table header */}
                        <thead className="table-bg text-xs font-semibold capitalize text-[#181818] border-t border-b border-slate-200 ">
                          <tr className="flex">
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[25%]">
                              <div className="font-bold tble-th-txt text-left">
                                Name
                              </div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[30%]">
                              <div className="font-bold tble-th-txt text-left">
                                Email
                              </div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[30%]">
                              <div className="font-bold tble-th-txt text-left">
                                Image
                              </div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[15%]">
                              <div className="font-bold tble-th-txt text-left">
                                Action
                              </div>
                            </th>
                          </tr>
                        </thead>
                        {/* Table body */}
                        <tbody className=" scrollit text-sm custom-tbody ">
                        {this.state.adminDetailsList.map((e, key) => {
                            return (
                              <tr className="custom-tbl-td flex items-center" key={key}>
                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[25%]">
                                  {e.name + " " + e.lastName}
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[30%]">
                                  {e.email}
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[30%]">
                                  <img
                                    className="w-32 border border-[#dfdfdf]"
                                    alt=""
                                    src={APIURL + GetImage + "?type=admin&&fileName=" + e.profilePic}
                                  ></img>
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[15%]">
                                  <ActionContainer>
                                    <ActionEditButton
                                      value={e.adminID}
                                      id={key}
                                      index={key}
                                      onActionClick={this.onEdit.bind(this)}
                                      text="Edit"
                                    />
                                    <ActionDeleteButton
                                      value={e.adminID}
                                      id={key}
                                      index={key}
                                      onActionClick={this.onDelete.bind(this)}
                                      text="Delete"
                                    />
                                  </ActionContainer>
                                </td>
                              </tr>
                            );
                          })}

                        </tbody>
                      </table>
                    }
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}
